import { FC } from 'react';
import { Navigate } from 'react-router';

import HelmetPageTitle from 'components/HelmetPageTitle';
import { APP_ROUTE } from 'constants/appRoutes';
import { Loader } from 'components/Loader';
import { SerialNumberStatus } from 'types/serialNumber';

import { InnerWrapper } from './styled';
import { ISerialNumberDetails } from './types';
import { SERIAL_NUMBER_DETAILS_TEXT } from './constants';
import SerialNumberHeader from './SerialNumberHeader';
import SerialNumberCards from './SerialNumberCards';
import RequestCurrencyDetails from './RequestCurrencyDetails';
import DealerDetails from './DealerDetails';
import ShareModal from './ShareModal';
import ShareEmailModal from './ShareEmailModal';
import ShareSmsModal from './ShareSmsModal';
import ShareQRModal from './ShareQRModal';
import { SN_DETAILS_CONTENT_CLASSNAME } from './utils/constants';

const SerialNumberDetails: FC<ISerialNumberDetails> = ({
  serialNumber,
  paymentCards,
  isSerialNumberDetailsFetching,
  handleExportPdf,
  isPdfGenerating,
  generatePdfProgress,
}) => {
  if (serialNumber?.status === SerialNumberStatus.deleted) {
    return <Navigate replace to={APP_ROUTE.notFound} />;
  }

  return (
    <>
      {isSerialNumberDetailsFetching ? (
        <Loader />
      ) : (
        serialNumber && (
          <HelmetPageTitle
            title={SERIAL_NUMBER_DETAILS_TEXT.pageTitle}
            headerTitle={SERIAL_NUMBER_DETAILS_TEXT.pageTitle}
            backLinkPath={APP_ROUTE.serialNumbers}
          >
            <InnerWrapper>
              <div className={SN_DETAILS_CONTENT_CLASSNAME}>
                <SerialNumberHeader
                  serialNumber={serialNumber}
                  paymentCards={paymentCards}
                  isPdfGenerating={isPdfGenerating}
                  handleExportPdf={handleExportPdf}
                  generatePdfProgress={generatePdfProgress}
                />
                <SerialNumberCards serialNumber={serialNumber} />
                <RequestCurrencyDetails serialNumber={serialNumber} />
                <DealerDetails serialNumber={serialNumber} />
              </div>
            </InnerWrapper>
            <ShareModal />
            <ShareEmailModal serialNumber={serialNumber} />
            <ShareSmsModal serialNumber={serialNumber} />
            <ShareQRModal />
          </HelmetPageTitle>
        )
      )}
    </>
  );
};

export default SerialNumberDetails;
