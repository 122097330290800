import { FC, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  useCancelSubscription,
  useChangeSubscriptionPayment,
} from 'api/subscriptions';
import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import { APP_ROUTE } from 'constants/appRoutes';
import { showConfirmModal } from 'utils/showConfirmModal';

import { LOCK_SUBSCRIPTION_CONFIRM_TEXT } from './constants';
import { ISubscriptionActionsContainerProps } from './types';
import SubscriptionActions from './SubscriptionActions';

const SubscriptionActionsContainer: FC<ISubscriptionActionsContainerProps> = ({
  subscription,
  paymentCards,
}) => {
  const { refetchSerialNumberDetails } = useSerialNumbersDetailsModalContext();
  const navigateTo = useNavigate();
  const [isEditSubscriptionModalOpened, setIsEditSubscriptionModalOpened] =
    useState<boolean>(false);

  const [isPaymentMethodModalOpened, setIsPaymentMethodModalOpened] =
    useState<boolean>(false);

  const handleOpenEditSubscriptionModal = () => {
    setIsEditSubscriptionModalOpened(true);
  };
  const handleCloseEditSubscriptionModal = () =>
    setIsEditSubscriptionModalOpened(false);

  const handleChangeSubscriptionPlanSuccess = () =>
    refetchSerialNumberDetails();

  const { mutate: cancelSubscriptionMutate } = useCancelSubscription();

  const handleLockButtonClick = () => {
    showConfirmModal({
      ...LOCK_SUBSCRIPTION_CONFIRM_TEXT,
      onOk: () => {
        cancelSubscriptionMutate(subscription._id, {
          onSuccess: () => navigateTo(APP_ROUTE.serialNumbers),
        });
      },
    });
  };

  const handleOpenPaymentMethodModal = () => {
    setIsPaymentMethodModalOpened(true);
  };
  const handleClosePaymentMethodModal = () => {
    setIsPaymentMethodModalOpened(false);
  };

  const handleUpdatePaymentMethodSuccess = () => {
    handleClosePaymentMethodModal();
    refetchSerialNumberDetails();
  };

  const {
    mutate: updatePaymentMethodMutate,
    isLoading: isUpdatePaymentMethodLoading,
  } = useChangeSubscriptionPayment(
    subscription._id,
    handleUpdatePaymentMethodSuccess,
  );

  const handleUpdatePaymentMethod = (paymentSourceId: string) => {
    updatePaymentMethodMutate({ paymentSourceId });
  };

  return (
    <SubscriptionActions
      subscription={subscription}
      paymentCards={paymentCards}
      isEditSubscriptionModalOpened={isEditSubscriptionModalOpened}
      isPaymentMethodModalOpened={isPaymentMethodModalOpened}
      isUpdatePaymentMethodLoading={isUpdatePaymentMethodLoading}
      handleLockButtonClick={handleLockButtonClick}
      handleOpenEditSubscriptionModal={handleOpenEditSubscriptionModal}
      handleCloseEditSubscriptionModal={handleCloseEditSubscriptionModal}
      handleOpenPaymentMethodModal={handleOpenPaymentMethodModal}
      handleClosePaymentMethodModal={handleClosePaymentMethodModal}
      handleUpdatePaymentMethod={handleUpdatePaymentMethod}
      handleChangeSubscriptionPlanSuccess={handleChangeSubscriptionPlanSuccess}
    />
  );
};

export default SubscriptionActionsContainer;
