import { FC } from 'react';
import { useNavigate } from 'react-router';

import { useAuthenticate } from 'api/auth';
import { EMPTY_STRING } from 'constants/common';
import { APP_ROUTE } from 'constants/appRoutes';
import { useFormik } from 'hooks';

import SignInForm from './SignInForm';
import { formValidationSchema } from './validation/schemas';
import { ISignInFormValues, SignInFormField } from './types';

const SignInFormContainer: FC = () => {
  const navigateTo = useNavigate();

  const { mutate: authenticateMutate, isLoading: isAuthenticateLoading } =
    useAuthenticate();

  const handleAuthenticate = (signInPayload: ISignInFormValues) =>
    authenticateMutate(signInPayload);

  const formik = useFormik({
    initialValues: {
      [SignInFormField.EMAIL]: EMPTY_STRING,
      [SignInFormField.PASSWORD]: EMPTY_STRING,
      [SignInFormField.REMEMBER]: false,
    },
    validationSchema: formValidationSchema,
    onSubmit: handleAuthenticate,
  });

  const handleNavigateToSignUp = () => navigateTo(APP_ROUTE.signUp);

  return (
    <SignInForm
      formik={formik}
      isAuthenticateLoading={isAuthenticateLoading}
      handleNavigateToSignUp={handleNavigateToSignUp}
    />
  );
};

export default SignInFormContainer;
