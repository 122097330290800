import { FC } from 'react';

import { Loader } from 'components/Loader';
import { EMPTY_STRING } from 'constants/common';
import { getResendButtonDisableText } from 'constants/datetime';

import { RESEND_CODE_TEXT } from './constants';
import {
  StyledButton,
  StyledDescription,
  StyledWrapper,
  ResendNotAvailable,
} from './styled';
import { IResendCodeProps } from './types';

const ResendCode: FC<IResendCodeProps> = ({
  resendButtonTimer,
  isSendVerificationCodeLoading,
  handleResendCode,
}) => (
  <StyledWrapper>
    {isSendVerificationCodeLoading ? (
      <Loader />
    ) : resendButtonTimer !== EMPTY_STRING ? (
      <ResendNotAvailable>
        {getResendButtonDisableText(resendButtonTimer)}
      </ResendNotAvailable>
    ) : (
      <StyledDescription>
        {RESEND_CODE_TEXT.notReceiveEmail}&nbsp;
        <StyledButton type='link' onClick={handleResendCode}>
          {RESEND_CODE_TEXT.resendCodeLink}
        </StyledButton>
      </StyledDescription>
    )}
  </StyledWrapper>
);

export default ResendCode;
