import { object, string } from 'yup';

import {
  EMAIL_PATTERN,
  VALIDATION_TEST_NAME,
  MAX_EMAIL_LENGTH,
} from 'constants/validations';
import { testRegexNegative } from 'utils/validation/testRegexNegative';

import { VALIDATION_ERROR_MESSAGES } from './constants';
import { ShareEmailFormField } from './types';

export const shareEmalValidationSchema = object().shape({
  [ShareEmailFormField.EMAIL]: string()
    .required(VALIDATION_ERROR_MESSAGES.EMAIL_FIELD_REQUIRED)
    .matches(
      EMAIL_PATTERN.GENERAL_STRUCTURE,
      VALIDATION_ERROR_MESSAGES.EMAIL_INVALID,
    )
    .matches(
      EMAIL_PATTERN.LOCAL_PART_GENERAL,
      VALIDATION_ERROR_MESSAGES.EMAIL_INVALID,
    )
    .matches(
      EMAIL_PATTERN.LOCAL_PART_BORDERS,
      VALIDATION_ERROR_MESSAGES.EMAIL_INVALID,
    )
    .test(
      VALIDATION_TEST_NAME.GENERAL_TRAILINGS,
      VALIDATION_ERROR_MESSAGES.EMAIL_INVALID,
      testRegexNegative(EMAIL_PATTERN.GENERAL_TRAILINGS),
    )
    .matches(
      EMAIL_PATTERN.DOMAIN_PART_GENERAL,
      VALIDATION_ERROR_MESSAGES.EMAIL_INVALID,
    )
    .matches(
      EMAIL_PATTERN.DOMAIN_PART_BORDERS,
      VALIDATION_ERROR_MESSAGES.EMAIL_INVALID,
    )
    .matches(
      EMAIL_PATTERN.DOMAIN_PART_TOTAL_LENGTH,
      VALIDATION_ERROR_MESSAGES.EMAIL_INVALID,
    )
    .max(MAX_EMAIL_LENGTH, VALIDATION_ERROR_MESSAGES.EMAIL_INVALID),
});
