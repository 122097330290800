import { FC, useCallback } from 'react';

import {
  NOTIFICATION_ERROR_MESSAGE,
  NOTIFICATION_SUCCESS_MESSAGE,
} from 'constants/messages';
import useShareSerialNumber from 'api/serialNumbers/shareSerialNumber';
import { useFormik } from 'hooks';
import { openNotificationWithIcon } from 'utils/showNotification';
import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import { NotificationType } from 'types/notifications';

import ShareEmailForm from './ShareEmailForm';
import { INITIAL_SHARE_EMAIL_FORM_VALUES } from './constants';
import { shareEmalValidationSchema } from './validation';
import { IShareEmailFormContainerProps, IShareEmailFormValues } from './types';

const ShareEmailFormContainer: FC<IShareEmailFormContainerProps> = ({
  serialNumber,
  handleCloseModal,
}) => {
  const { uploadedPdf, handleShareEmailModalClose } =
    useSerialNumbersDetailsModalContext();

  const { mutate: shareSerialNumber, isLoading: isSubmitActionLoading } =
    useShareSerialNumber();

  const handleOnSubmit = useCallback(
    ({ email }: IShareEmailFormValues) => {
      shareSerialNumber(
        {
          email,
          link: uploadedPdf?.url as string,
          password: uploadedPdf?.password,
          serialNumberId: serialNumber._id,
        },
        {
          onSuccess: () =>
            openNotificationWithIcon(
              NotificationType.success,
              NOTIFICATION_SUCCESS_MESSAGE.shareSerialNumberByEmail,
            ),
          onError: () =>
            openNotificationWithIcon(
              NotificationType.error,
              NOTIFICATION_ERROR_MESSAGE.shareSerialNumberByEmail,
            ),
          onSettled: () => {
            handleShareEmailModalClose();
            formik.resetForm();
          },
        },
      );
    },
    [
      shareSerialNumber,
      openNotificationWithIcon,
      handleShareEmailModalClose,
      uploadedPdf,
    ],
  );

  const formik = useFormik({
    initialValues: INITIAL_SHARE_EMAIL_FORM_VALUES,
    onSubmit: handleOnSubmit,
    validationSchema: shareEmalValidationSchema,
    enableReinitialize: true,
  });

  return (
    <ShareEmailForm
      formik={formik}
      isSubmitActionLoading={isSubmitActionLoading}
      handleCloseModal={handleCloseModal}
    />
  );
};

export default ShareEmailFormContainer;
