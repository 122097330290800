import { FC, useState } from 'react';
import { useNavigate } from 'react-router';

import { APP_ROUTE } from 'constants/appRoutes';

import ThankYou from './ThankYou';
import { INITIAL_THANK_YOU_PAGE_STEP, LEARN_MORE_STEP } from './constants';

const ThankYouContainer: FC = () => {
  const navigateTo = useNavigate();

  const [thankYouPageStep, setThankYouPageStep] = useState(
    INITIAL_THANK_YOU_PAGE_STEP,
  );

  const isInitialStep = thankYouPageStep === INITIAL_THANK_YOU_PAGE_STEP;

  const handleSwitchThankYouPageStep = () =>
    setThankYouPageStep(LEARN_MORE_STEP);

  const handleProceed = () => navigateTo(APP_ROUTE.serialNumbers);

  return (
    <ThankYou
      isInitialStep={isInitialStep}
      handleProceed={handleProceed}
      handleSwitchThankYouPageStep={handleSwitchThankYouPageStep}
    />
  );
};

export default ThankYouContainer;
