import { FC, useMemo } from 'react';
import { Popover } from 'antd';
import { useIsFetching } from 'react-query';

import { SAVE_AND_SHARE_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import {
  ActiveSubscriptionBadge,
  UnlockedSerialNumberBadge,
  LockedSerialNumberBadge,
} from 'components/Badges';
import Dropdown from 'components/Dropdown';
import { CurrencyType } from 'types/currencies';
import { SubscriptionStatus } from 'types/subscriptions';
import { formatSerialNumber } from 'utils/format';
import { checkIfSubscriptionActive } from 'utils/subscriptions';
import { renderTrialTooltip } from 'utils/renders';
import { QUERY_KEYS } from 'constants/queryKeys';

import { SERIAL_NUMBER_HEADER, TWO_REQUESTS_COUNT } from './constants';
import ActionButton from './ActionButton';
import {
  BadgeWrapper,
  ButtonWrapper,
  HeaderCard,
  SerialNumber,
  SerialNumberText,
  SerialNumberWrapper,
  StyledShareIcon,
  StyledSaveIcon,
  SaveAndShareWrapper,
  StyledEmbedInfoNotice,
  StyledInfoCircleIcon,
} from './styled';
import useSaveActions from './utils/useSaveActions';
import { ISerialNumberHeader } from '../types';
import EnabledDate from '../EnabledDate';
import SubscriptionActions from '../SubscriptionActions';

const SerialNumberHeader: FC<ISerialNumberHeader> = ({
  serialNumber,
  paymentCards,
  handleExportPdf,
  isPdfGenerating,
  generatePdfProgress,
}) => {
  const {
    subscriptionStatus,
    serialNumber: snValue,
    isLocked: isRestricted,
    saveAndShareAccess: saveAndShareAccessByCurrency,
    currency,
    _id,
    subscriptions,
  } = serialNumber;

  const { actions, isLoading: isSaveFileProcessing } = useSaveActions({
    serialNumberId: _id,
    handleExportPdf,
  });
  const { pdfBytes, handleShareModalOpen, uploadEncryptPdfProgress } =
    useSerialNumbersDetailsModalContext();

  const isPdfUploding = Boolean(
    useIsFetching({
      queryKey: [QUERY_KEYS.UPLOAD_ENCRYPT_PDF, _id, false],
    }),
  );

  const saveAndShareAccess =
    saveAndShareAccessByCurrency[
      currency as unknown as keyof typeof CurrencyType
    ];

  const currentSubscription = useMemo(
    () => subscriptions.find((subscription) => subscription.SNref._id === _id),
    [subscriptions, _id],
  );

  const isSubscriptionActive = checkIfSubscriptionActive(subscriptionStatus);

  const isSaveAndShareEnabled = saveAndShareAccess.isActive;

  const saveAndShareEnabledTillDate = saveAndShareAccess?.enabledTill;

  return (
    <HeaderCard isRestricted={isRestricted}>
      <SerialNumberWrapper>
        <SerialNumberText>
          {SERIAL_NUMBER_HEADER.serialNumber}
          <SerialNumber>{formatSerialNumber(snValue)}</SerialNumber>
        </SerialNumberText>
      </SerialNumberWrapper>
      <ButtonWrapper>
        <BadgeWrapper>
          <ActiveSubscriptionBadge />
          {subscriptionStatus === SubscriptionStatus.trial &&
            renderTrialTooltip(currentSubscription?.nextBillingAt)}
          {isSubscriptionActive ? (
            <UnlockedSerialNumberBadge />
          ) : (
            <LockedSerialNumberBadge />
          )}
        </BadgeWrapper>
        {isSubscriptionActive && isSaveAndShareEnabled && (
          <SaveAndShareWrapper data-html2canvas-ignore>
            <EnabledDate date={saveAndShareEnabledTillDate} />
            <Popover
              placement='bottom'
              content={
                <StyledEmbedInfoNotice>
                  {SERIAL_NUMBER_HEADER.saveShareInfo}
                </StyledEmbedInfoNotice>
              }
            >
              <StyledInfoCircleIcon />
            </Popover>
            <Dropdown
              disabled={isSaveFileProcessing || isPdfGenerating}
              items={actions}
              placement='bottomRight'
              visible={pdfBytes ? undefined : false}
            >
              <ActionButton
                id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
                isUploading={isPdfGenerating || isPdfUploding}
                loading={isSaveFileProcessing}
                width={75}
                progress={(generatePdfProgress + uploadEncryptPdfProgress) / TWO_REQUESTS_COUNT}
                onClick={pdfBytes ? undefined : handleShareModalOpen}
                icon={<StyledSaveIcon />}
              >
                {SERIAL_NUMBER_HEADER.saveButton}
              </ActionButton>
            </Dropdown>
            <ActionButton
              id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
              width={82}
              isUploading={isPdfGenerating}
              disabled={isPdfGenerating}
              onClick={handleShareModalOpen}
              progress={generatePdfProgress}
              icon={<StyledShareIcon />}
            >
              {SERIAL_NUMBER_HEADER.shareButton}
            </ActionButton>
          </SaveAndShareWrapper>
        )}
        {currentSubscription && isSubscriptionActive && (
          <SubscriptionActions
            subscription={currentSubscription}
            paymentCards={paymentCards}
          />
        )}
      </ButtonWrapper>
    </HeaderCard>
  );
};

export default SerialNumberHeader;
