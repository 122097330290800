import { EMPTY_STRING } from 'constants/common';

const DELIMITER = '-';

export const formatSerialNumber = (serialNumber: string): string => {
  const serialNumberParts = serialNumber.match(/.{1,4}/g);
  const formattedSerialNumber = serialNumberParts?.join(DELIMITER);

  return formattedSerialNumber || EMPTY_STRING;
};

export const unformatSerialNumber = (serialNumber: string): string =>
  serialNumber.replaceAll(DELIMITER, EMPTY_STRING);
