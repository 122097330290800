import { FC } from 'react';

import { CARD_LOGO_LARGE_LOOKUP } from 'constants/payment';

import { IconWrapper } from './styled';

const PaymentIcons: FC = (props) => {
  const iconsList = Object.values(CARD_LOGO_LARGE_LOOKUP);

  return (
    <div {...props}>
      {iconsList.map((iconImg, index) => (
        <IconWrapper key={index}>{iconImg}</IconWrapper>
      ))}
    </div>
  );
};

export default PaymentIcons;
