import { useMutation, UseMutationResult } from 'react-query';

import { useNavigateHome } from 'hooks';
import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { QUERY_KEYS } from 'constants/queryKeys';
import { USER_EMAIL_LS_KEY } from 'constants/localStorage';
import { TAxiosRequestError } from 'api/types';
import { transformRoute } from 'utils/transformRoute';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';
import { UserTypes } from 'types/user';

import { IUseAuthenticatePayload, IUseAuthenticateResponse } from './types';

const useAuthenticate = (): UseMutationResult<
  IUseAuthenticateResponse,
  TAxiosRequestError,
  IUseAuthenticatePayload
> => {
  const triggerGoHome = useNavigateHome();

  const handleAuthenticateSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEYS.USER_PROFILE]);
    triggerGoHome();
  };

  const handleAuthenticateError = (error: TAxiosRequestError) => {
    openNotificationWithIcon(
      NotificationType.error,
      error?.response?.data?.message || error?.message,
    );
  };

  return useMutation(
    async (signInPayload: IUseAuthenticatePayload) => {
      const { data: tokenResponse } = await apiClient.post(
        transformRoute(ApiRoutes.SIGN_IN, UserTypes.customer),
        signInPayload,
      );
      localStorage.setItem(USER_EMAIL_LS_KEY, signInPayload.email);

      return tokenResponse;
    },
    {
      onSuccess: handleAuthenticateSuccess,
      onError: handleAuthenticateError,
    },
  );
};

export default useAuthenticate;
