import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LeftCircleOutlined } from '@ant-design/icons';

export const StyledLink = styled(
  ({ isDisabled: _isDisabled, children, ...props }) => (
    <Link {...props}>{children}</Link>
  ),
)<{ isDisabled: boolean }>(({ isDisabled }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '20px',
  ...(isDisabled && {
    pointerEvents: 'none',
    cursor: 'default',
  }),
}));

export const StyledLinkText = styled('span')({
  lineHeight: 1,
  fontSize: '16px',
  fontWeight: 600,
});

export const StyledLeftCircleOutlined = styled(LeftCircleOutlined)({
  marginRight: '10px',
  '& svg': {
    width: '18px',
    height: '18px',
  },
});
