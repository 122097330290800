import { FC } from 'react';
import { isEmpty } from 'lodash';

import { APP_ROUTE } from 'constants/appRoutes';
import { MAX_EMAIL_LENGTH } from 'constants/validations';
import { UNAUTHORIZED_LAYOUT_TEXT } from 'components/Layouts/UnauthorizedLayout/constants';
import { DefaultInput, PasswordInput } from 'components/Inputs';

import { SIGN_IN_FORM_TEXT } from './constants';
import {
  StyledForm,
  SignInButton,
  Checkbox,
  FieldWrapper,
  CheckboxFieldWrapper,
  StyledLink,
  StyledText,
  StyledButton,
} from './styled';
import { ISignInFormProps, SignInFormField } from './types';

const SignInForm: FC<ISignInFormProps> = ({
  formik,
  isAuthenticateLoading,
  handleNavigateToSignUp,
}) => {
  const { values, errors, handleSubmit, getFieldProps } = formik;

  const isButtonDisabled =
    isEmpty(values[SignInFormField.EMAIL]) ||
    isEmpty(values[SignInFormField.PASSWORD]);

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <FieldWrapper>
          <DefaultInput
            labelText={SIGN_IN_FORM_TEXT.EMAIL_LABEL}
            errorMessage={errors[SignInFormField.EMAIL]}
            maxLength={MAX_EMAIL_LENGTH}
            {...getFieldProps(SignInFormField.EMAIL)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <PasswordInput
            errorMessage={errors[SignInFormField.PASSWORD]}
            labelText={SIGN_IN_FORM_TEXT.PASSWORD_LABEL}
            {...getFieldProps(SignInFormField.PASSWORD)}
          />
        </FieldWrapper>
        <SignInButton
          htmlType='submit'
          size='large'
          loading={isAuthenticateLoading}
          disabled={isButtonDisabled}
        >
          {UNAUTHORIZED_LAYOUT_TEXT.SIGN_IN}
        </SignInButton>
        <CheckboxFieldWrapper>
          <Checkbox
            {...getFieldProps(SignInFormField.REMEMBER)}
            checked={values[SignInFormField.REMEMBER]}
          >
            {SIGN_IN_FORM_TEXT.REMEMBER_ME}
          </Checkbox>
          <StyledLink to={APP_ROUTE.forgotPassword}>
            {SIGN_IN_FORM_TEXT.FORGOT_PASSWORD_LINK}
          </StyledLink>
        </CheckboxFieldWrapper>
      </StyledForm>
      <StyledText>{SIGN_IN_FORM_TEXT.SIGN_UP_TEXT}</StyledText>
      <StyledButton type='default' onClick={handleNavigateToSignUp}>
        {SIGN_IN_FORM_TEXT.SIGN_UP_BUTTON_TEXT}
      </StyledButton>
    </>
  );
};

export default SignInForm;
