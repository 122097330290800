import { FC, useState, useEffect } from 'react';

import { EMPTY_STRING } from 'constants/common';
import { IPaymentCard, PaymentCardRole } from 'types/customer';

import { IEditPaymentMethodContainerProps } from './types';
import EditPaymentMethod from './EditPaymentMethod';

const EditPaymentMethodContainer: FC<IEditPaymentMethodContainerProps> = ({
  paymentCards,
  currentPaymentSourceId = '',
  isUpdatePaymentMethodLoading,
  goToAddPaymentMethodStep,
  handleUpdatePaymentMethod,
}) => {
  const [selectedPaymentSourceId, setSelectedPaymentSourceId] =
    useState<string>(currentPaymentSourceId);

  const handleSubmitPaymentMethod = () => {
    handleUpdatePaymentMethod(selectedPaymentSourceId);
  };

  useEffect(() => {
    setSelectedPaymentSourceId(currentPaymentSourceId);
  }, [currentPaymentSourceId]);

  const primaryCard: IPaymentCard = {
    ...(paymentCards.find(
      (card) => card.role === PaymentCardRole.primary,
    ) as IPaymentCard),
    sourceId: EMPTY_STRING,
  };

  const paymentOverridedCards = [primaryCard, ...paymentCards];

  return (
    <EditPaymentMethod
      paymentCards={paymentOverridedCards}
      selectedPaymentSourceId={selectedPaymentSourceId}
      isSubmitPaymentMethodLoading={isUpdatePaymentMethodLoading}
      handleChangePaymentMethod={setSelectedPaymentSourceId}
      handleSubmitPaymentMethod={handleSubmitPaymentMethod}
      handleAddPaymentMethod={goToAddPaymentMethodStep}
    />
  );
};

export default EditPaymentMethodContainer;
