import styled from 'styled-components';
import { Radio } from 'antd';

import { COLORS } from 'constants/colorPalette';

export const StyledRadioButton = styled(Radio)<{ withBanner: boolean }>(
  ({ withBanner }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '40px',
    ...(withBanner ? { paddingTop: '96px' } : {}),
    margin: 0,
    border: `2px solid ${COLORS.blue400}`,
    backgroundColor: COLORS.white,
    borderRadius: '12px',
    '& span.ant-radio + *': {
      padding: 0,
      width: '100%',
    },
    '& .ant-radio-inner': {
      width: '32px',
      height: '32px',
      '&:after': {
        width: '32px',
        height: '32px',
        marginTop: '-16px',
        marginLeft: '-16px',
      },
    },
    '@media (max-width: 768px)': {
      padding: '16px',
      ...(withBanner ? { paddingTop: '60px' } : {}),
      '& .ant-radio-inner': {
        width: '24px',
        height: '24px',
        '&:after': {
          width: '24px',
          height: '24px',
          marginTop: '-12px',
          marginLeft: '-12px',
        },
      },
    },
  }),
);

export const StyledPlanWrapper = styled('div')({
  textAlign: 'left',
  paddingLeft: '40px',
  width: '100%',
  '@media (max-width: 768px)': {
    paddingLeft: '16px',
  },
});

export const StyledPlanPrice = styled('p')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '12px',
  fontSize: '48px',
  lineHeight: '66px',
  fontWeight: 800,
  color: COLORS.gray900,
  marginBottom: '8px',
  '@media (max-width: 768px)': {
    fontSize: '32px',
    lineHeight: '26px',
  },
});

export const StyledPlanText = styled('p')({
  fontSize: '24px',
  lineHeight: '34px',
  fontWeight: 600,
  color: COLORS.blue500,
  marginBottom: '8px',
  '@media (max-width: 768px)': {
    fontSize: '18px',
    lineHeight: '22px',
  },
});

export const StyledPlanTip = styled('span')({
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 600,
  color: COLORS.gray500,
  '@media (max-width: 768px)': {
    fontSize: '16px',
    lineHeight: '20px',
  },
});

export const StyledSavingsLabel = styled('span')({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '10px 20px 10px 28px',
  backgroundColor: COLORS.gray100,
  borderRadius: '12px',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '34px',
  color: COLORS.blue600,
  '@media (max-width: 768px)': {
    fontSize: '20px',
    lineHeight: '24px',
  },
});

export const StyledBanner = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  borderBottom: `1px solid ${COLORS.blue400}`,
  overflow: 'hidden',
});
