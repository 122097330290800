import { FC } from 'react';

import HelpLink from 'components/HelpLink';

import { StyledHeader, StyledLogo, StyledInnerHeader } from './styled';
import { IHeaderProps } from './types';

const Header: FC<IHeaderProps> = ({ isDesktopVersion, handleLogoClick }) => (
  <StyledHeader isDesktopVersion={isDesktopVersion}>
    <StyledInnerHeader>
      <StyledLogo
        isDesktopVersion={isDesktopVersion}
        onClick={handleLogoClick}
      />
      <HelpLink />
    </StyledInnerHeader>
  </StyledHeader>
);

export default Header;
