import { useMemo } from 'react';

import { NOT_AVAILABLE } from 'constants/common';
import { useGetUserFromQueryContext } from 'hooks';
import { formatPhoneNumber } from 'components/Inputs/PhoneNumberInput/utils';

import { IBillingAddressFormValues } from './types';

export const useFormattedBillingAddress = (
  billingAddress: IBillingAddressFormValues,
): IBillingAddressFormValues =>
  useMemo<IBillingAddressFormValues>(
    () => ({
      ...billingAddress,
      phoneNumber: formatPhoneNumber({
        value: billingAddress.phoneNumber,
        countryCode: billingAddress.countryCode,
        formatType: 'sliceCountryCode',
      }),
      isSameAsHome: billingAddress.isSameAsHome ?? false,
    }),
    [billingAddress],
  );

export const useCustomerBillingAddress = (): IBillingAddressFormValues => {
  const customer = useGetUserFromQueryContext();

  const {
    _id: _customerId,
    role: _role,
    avatar: _avatar,
    billingAddress: _billingAddress,
    ...customerBillingAddress
  } = customer;

  return {
    ...customerBillingAddress,
    company: NOT_AVAILABLE,
    isSameAsHome: true,
  };
};
