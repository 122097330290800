import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import { LearnMoreModalHeader } from 'components/LearnMoreModal';

export const LearnMoreModalTitle = styled('h2')({
  marginBottom: '16px',
  fontSize: '32px',
  lineHeight: '44px',
  fontWeight: 600,
});

export const LearnMoreModalBoldText = styled('p')({
  marginBottom: '16px',
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 800,
  color: COLORS.gray900,
});

export const LearnMoreModalText = styled('p')({
  marginBottom: '20px',
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 500,
  color: COLORS.gray600,
});

export const NotVerifiedModalTitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
  width: '100%',
  padding: '20px',
  marginBottom: '32px',
  backgroundColor: COLORS.gray50,
  '> h2': {
    marginBottom: 0,
    color: COLORS.red600,
  },
});

export const NotVerifiedModalHeader = styled(LearnMoreModalHeader)({
  '> h3': {
    fontSize: '16px',
    letterSpacing: '0.2px',
  },
});
