import { FC } from 'react';
import { RadioChangeEvent } from 'antd';

import ModalDialog from 'components/ModalDialog';
import { ISubscriptionPlan } from 'types/subscriptions';

import {
  ButtonsWrapper,
  ModalContentWrapper,
  ModalDescription,
  ModalTitle,
  StyledButton,
  StyledRadioGroup,
} from './styled';
import {
  SELECT_SUBSCRIPTION_PLAN_MODAL_TEXT,
  SELECT_SUBSCRIPTION_PLAN_MODAL_WIDTH,
} from './constants';
import { ChangePlanField, ISelectSubscriptionPlanModalProps } from './types';
import SubscriptionRadioButton from './SubscriptionRadioButton';

const SelectSubscriptionPlanModal: FC<ISelectSubscriptionPlanModalProps> = ({
  formik,
  subscriptionPlans,
  selectedSubscriptionPlan,
  isModalOpened,
  isChangeSubscriptionPlanLoading,
  handleChangeSubscriptionPlan,
  handleCloseModal,
}) => {
  const { dirty, handleSubmit } = formik;
  const isSubmitButtonDisabled = isChangeSubscriptionPlanLoading || !dirty;
  const [, monthlyPlan] = subscriptionPlans;

  return (
    <ModalDialog
      width={SELECT_SUBSCRIPTION_PLAN_MODAL_WIDTH}
      isModalOpened={isModalOpened}
      handleCloseModal={handleCloseModal}
    >
      <ModalContentWrapper onSubmit={handleSubmit}>
        <ModalTitle>{SELECT_SUBSCRIPTION_PLAN_MODAL_TEXT.TITLE}</ModalTitle>
        <ModalDescription>
          {SELECT_SUBSCRIPTION_PLAN_MODAL_TEXT.DESCRIPTION}
        </ModalDescription>
        <StyledRadioGroup
          size='small'
          id={ChangePlanField.planId}
          value={selectedSubscriptionPlan}
          onChange={({ target }: RadioChangeEvent) =>
            handleChangeSubscriptionPlan(target.value)
          }
        >
          {subscriptionPlans?.map((subscription: ISubscriptionPlan) => (
            <SubscriptionRadioButton
              key={subscription.id}
              plan={subscription}
              monthlyPlanPrice={monthlyPlan?.pricePerYear as number}
            />
          ))}
        </StyledRadioGroup>
        <ButtonsWrapper>
          <StyledButton type='ghost' onClick={handleCloseModal}>
            {SELECT_SUBSCRIPTION_PLAN_MODAL_TEXT.CANCEL_BUTTON}
          </StyledButton>
          <StyledButton
            htmlType='submit'
            loading={isChangeSubscriptionPlanLoading}
            disabled={isSubmitButtonDisabled}
          >
            {SELECT_SUBSCRIPTION_PLAN_MODAL_TEXT.CHANGE_BUTTON}
          </StyledButton>
        </ButtonsWrapper>
      </ModalContentWrapper>
    </ModalDialog>
  );
};

export default SelectSubscriptionPlanModal;
