import styled from 'styled-components';
import {
  CardCVV as CBCardCVV,
  CardExpiry as CBCardExpiry,
  CardNumber as CBCardNumber,
  CardComponent as CBCardComponent,
} from '@chargebee/chargebee-js-react-wrapper';

import { COLORS } from 'constants/colorPalette';

export const CardComponent = styled(CBCardComponent)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  marginBottom: '32px',
});

export const CardComponentRow = styled('div')({
  display: 'flex',
  gap: '32px',
});

export const CardCVV = styled(CBCardCVV)({
  width: '100%',
});

export const CardExpiry = styled(CBCardExpiry)({
  width: '100%',
});

export const CardNumber = styled(CBCardNumber)({
  width: '100%',
});

export const CardFieldWrapper = styled('div')({
  width: '100%',
  height: '54px',
});

export const CardFieldError = styled('p')({
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: 500,
  color: COLORS.red500,
  marginBottom: 0,
});
