import styled from 'styled-components';

import Button from 'components/Button';
import { COLORS } from 'constants/colorPalette';

export const VerifiedModalWrapper = styled('div')({
  width: '100%',
  padding: '50px 28px',
  textAlign: 'center',
});

export const VerifiedModalSubtitle = styled('h2')({
  fontSize: '32px',
  lineHeight: '38px',
  fontWeight: 600,
  color: COLORS.green700,
  marginBottom: '35px',
});

export const VerifiedModalTitle = styled('h1')({
  fontSize: '48px',
  lineHeight: '66px',
  fontWeight: 600,
  color: COLORS.gray900,
  marginBottom: '8px',
});

export const VerifiedModalText = styled('p')({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 500,
  color: COLORS.gray500,
  marginBottom: '40px',
});

export const VerifiedModalButton = styled(Button)({
  width: '100%',
});
