import { ReactElement } from 'react';

import {
  IqdLargeIcon as IQDFlag,
  NotVerifiedCloseIcon as NotVerifiedIcon,
  VerifiedGuardIcon as VerifiedIcon,
} from 'assets/vectors';
import { EMPTY_STRING } from 'constants/common';
import { COLORS } from 'constants/colorPalette';
import { TLookupByCurrencyType } from 'types/currencies';

import { TLookupBySNStatusType } from './types';

export const CURRENCIES_FLAGS_LOOKUP: TLookupByCurrencyType<ReactElement> = {
  IQD: <IQDFlag />,
  IDR: <IQDFlag />,
  VND: <IQDFlag />,
  //TODO: replace after adding icons for diff currencies to mocks
};

export const SN_STATUS_BY_TYPE_LOOKUP: TLookupBySNStatusType<
  ReactElement | string
> = {
  default: EMPTY_STRING,
  success: <VerifiedIcon />,
  error: <NotVerifiedIcon />,
};

export const SN_STATUS_COLOR_BY_TYPE_LOOKUP: TLookupBySNStatusType = {
  default: COLORS.gray800,
  success: COLORS.green700,
  error: COLORS.red500,
};

export const SN_STATUS_TEXT_BY_TYPE_LOOKUP: TLookupBySNStatusType = {
  default: 'Verify your',
  success: 'Verified',
  error: 'Serial # Not Verified',
};
