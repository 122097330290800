import styled from 'styled-components';
import { Layout } from 'antd';

import { COLORS } from 'constants/colorPalette';

const { Content } = Layout;

export const StyledMainLayout = styled(Layout)({
  position: 'relative',
  height: '100vh',
  overflowX: 'hidden',
});

export const StyledLayout = styled(Layout)({
  minHeight: '100vh',
  height: '100%',
});

export const StyledList = styled('ul')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '600px',
  listStyleType: 'none',
});

export const StyledContent = styled(Content)({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  maxHeight: '100%',
  padding: '20px 60px 0',
  background: COLORS.gray50,
  overflowY: 'scroll',
  '@media (max-width: 768px)': {
    padding: '16px',
  },
});
