import { FC } from 'react';
import { RadioChangeEvent } from 'antd';

import { EDIT_PAYMENT_METHOD_CONTENT, MIN_CARDS_VALUE } from './constants';
import {
  EditPaymentMethodWrapper,
  EditPaymentMethodTitle,
  StyledRadioGroup,
  EditPaymentMethodButton,
  StyledNoDataMessage,
} from './styled';
import { IEditPaymentMethodProps } from './types';
import PaymentMethodRadioButton from './PaymentMethodRadioButton';

const EditPaymentMethod: FC<IEditPaymentMethodProps> = ({
  paymentCards,
  selectedPaymentSourceId,
  isSubmitPaymentMethodLoading,
  handleChangePaymentMethod,
  handleSubmitPaymentMethod,
  handleAddPaymentMethod,
}) => (
  <EditPaymentMethodWrapper>
    <EditPaymentMethodTitle>
      {EDIT_PAYMENT_METHOD_CONTENT.title}
    </EditPaymentMethodTitle>
    {paymentCards.length > MIN_CARDS_VALUE ? (
      <>
        <StyledRadioGroup
          size='large'
          value={selectedPaymentSourceId}
          onChange={(e: RadioChangeEvent) =>
            handleChangePaymentMethod(e.target.value)
          }
        >
          {paymentCards.map((card) => (
            <PaymentMethodRadioButton
              key={`payment-card-#${card.sourceId}`}
              card={card}
            />
          ))}
        </StyledRadioGroup>
        <EditPaymentMethodButton type='link' onClick={handleAddPaymentMethod}>
          {EDIT_PAYMENT_METHOD_CONTENT.addPaymentMethodButton}
        </EditPaymentMethodButton>
        <EditPaymentMethodButton
          type='primary'
          loading={isSubmitPaymentMethodLoading}
          onClick={handleSubmitPaymentMethod}
        >
          {EDIT_PAYMENT_METHOD_CONTENT.updatePaymentMethodButton}
        </EditPaymentMethodButton>
      </>
    ) : (
      <>
        <StyledNoDataMessage message={EDIT_PAYMENT_METHOD_CONTENT.noData} />
        <EditPaymentMethodButton onClick={handleAddPaymentMethod}>
          {EDIT_PAYMENT_METHOD_CONTENT.addPaymentMethodButton}
        </EditPaymentMethodButton>
      </>
    )}
  </EditPaymentMethodWrapper>
);

export default EditPaymentMethod;
