import { FC } from 'react';

import { IPaymentBannerProps, PaymentBannerType } from './types';
import { StyledPaymentBanner, StyledInfoIcon } from './styled';

const PaymentBanner: FC<IPaymentBannerProps> = ({
  type = PaymentBannerType.default,
  text,
  withIcon = false,
  ...props
}) => (
  <StyledPaymentBanner type={type} {...props}>
    {withIcon && <StyledInfoIcon />}
    {text}
  </StyledPaymentBanner>
);

export default PaymentBanner;
