import { FC } from 'react';

import ModalDialog from 'components/ModalDialog';
import { BillingAddressForm } from 'components/BillingAddress';

import { BILLING_ADDRESS_MODAL_TITLE } from './constants';
import { BillingAddressModalWrapper, BillingAddressModalTitle } from './styled';
import { IBillingAddressModalProps } from './types';

const BillingAddressModal: FC<IBillingAddressModalProps> = ({
  billingAddress,
  title = BILLING_ADDRESS_MODAL_TITLE,
  formButtonsText,
  isModalOpened,
  isSubmitBillingAddressLoading,
  isAsHomeCheckboxHidden,
  handleCloseModal,
  handleSubmitBillingAddress,
  afterClose,
}) => (
  <ModalDialog
    destroyOnClose
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
    afterClose={afterClose}
  >
    <BillingAddressModalWrapper>
      <BillingAddressModalTitle>{title}</BillingAddressModalTitle>
      <BillingAddressForm
        isOnDirtySubmitDisable
        initialBillingAddressValues={billingAddress}
        buttonsText={formButtonsText}
        isAsHomeCheckboxHidden={isAsHomeCheckboxHidden}
        isFormActionLoading={isSubmitBillingAddressLoading}
        handleCancelBillingAddressForm={handleCloseModal}
        handleSubmitBillingAddressForm={handleSubmitBillingAddress}
      />
    </BillingAddressModalWrapper>
  </ModalDialog>
);

export default BillingAddressModal;
