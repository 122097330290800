import { FC } from 'react';

import { PaymentFormCheckbox, PaymentCheckboxLink } from '../styled';
import { AGREEMENT_CONTENT } from './constants';
import { AgreementWrapper } from './styled';
import { IAgreementProps } from './types';

const Agreement: FC<IAgreementProps> = ({ isChecked, handleChange }) => (
  <AgreementWrapper>
    <PaymentFormCheckbox checked={isChecked} onChange={handleChange}>
      {AGREEMENT_CONTENT.agreementText}{' '}
      <PaymentCheckboxLink type='link'>
        {AGREEMENT_CONTENT.userAgreementButtonText}
      </PaymentCheckboxLink>{' '}
      and{' '}
      <PaymentCheckboxLink type='link'>
        {AGREEMENT_CONTENT.privacyPolicyButtonText}
      </PaymentCheckboxLink>
    </PaymentFormCheckbox>
  </AgreementWrapper>
);

export default Agreement;
