import { IShowModalConfirmProps } from 'utils/showConfirmModal/types';

export const SUBSCRIPTION_ACTIONS_TOOLTIP = 'Subscription management';

export const LOCK_SUBSCRIPTION_CONFIRM_TEXT = {
  titleText: 'Are you sure you want to lock your subscription?',
  contentText: `Please note if you lock the subscription - you will lose access to the serial number details immediately. The subscription will canceled without giving any credits for the unused period. 
    Note! If you would like to access your serial number details till the end of the active subscription, we advice you to cancel subscription on its last day.`,
  okText: 'Lock',
  cancelText: 'Cancel',
} as IShowModalConfirmProps;

export const ACTIONS_BUTTONS_TEXT = {
  editPayment: 'Edit payment method',
  editPlan: 'Edit plan',
  lock: 'Lock',
};
