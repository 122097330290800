import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import PaymentBanner from 'pages/Payment/PaymentBanner';

export const PaymentInfoWrapper = styled('div')<{ withBanner: boolean }>(
  ({ withBanner }) => ({
    position: 'relative',
    width: '100%',
    padding: '32px 32px 0 32px',
    paddingBottom: withBanner ? '80px' : '20px',
    border: `2px solid ${COLORS.gray300}`,
    borderRadius: '12px',
    marginBottom: '44px',
    '@media (max-width: 768px)': {
      padding: '26px 26px 0 26px',
      paddingBottom: withBanner ? '60px' : '14px',
    },
  }),
);

export const PaymentInfoTitle = styled('h3')({
  fontSize: '24px',
  lineHeight: '34px',
  fontWeight: 600,
  color: COLORS.gray900,
  marginBottom: '20px',
});

export const PaymentInfoRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingBottom: '12px',
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 600,
  '&:first-of-type': {
    borderBottom: `1px solid ${COLORS.gray300}`,
    marginBottom: '12px',
  },
});

export const PaymentInfoRowText = styled('span')({
  color: COLORS.gray600,
});

export const PaymentInfoRowValue = styled('span')({
  color: COLORS.gray900,
});

export const StyledPaymentBanner = styled(PaymentBanner)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
  borderTop: `1px solid ${COLORS.blue400}`,
});
