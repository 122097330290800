import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';
import { PaymentIcons } from 'components/Payment';

export const AddPaymentMethodWrapper = styled('div')({
  width: '100%',
  marginBottom: '63px',
});

export const AddPaymentMethodTitle = styled('h3')({
  marginBottom: '40px',
  fontSize: '32px',
  fontWeight: 600,
});

export const PaymentMethodWrapper = styled('div')({
  width: '100%',
  minHeight: '317px',
});

export const StyledBackButton = styled(Button)({
  display: 'inherit',
  margin: '0 auto 25px 0',
  paddingLeft: 0,
  fontSize: '16px',
  fontWeight: 500,
  color: COLORS.gray700,
  ':hover': {
    color: COLORS.gray600,
  },
});

export const StyledArrowLeft = styled('div')({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  margin: '0 8px 1px 0',
  borderTop: '2px solid',
  borderRight: '2px solid',
  borderColor: COLORS.gray700,
  transform: 'rotate(225deg)',
  transition: 'border-color .3s',
});

export const StyledPaymentIcons = styled(PaymentIcons)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  marginBottom: '53px',
});

export const StyledButton = styled(Button)({
  width: '100%',
});
