import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';

export const PaymentAndBillingAddressWrapper = styled('div')({
  padding: '60px 80px 0',
  '@media (max-width: 768px)': {
    padding: '30px 16px',
  },
});

export const SectionWrapper = styled('div')({
  marginBottom: '40px',
  width: '100%',
  paddingLeft: '12px',
  borderLeft: `1px solid ${COLORS.gray200}`,
  textAlign: 'left',
});

export const SectionTitleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '20px',
  marginBottom: '5px',
  width: '100%',
});

export const SectionTitle = styled('h2')({
  margin: 0,
  fontSize: '20px',
  fontWeight: 600,
  color: COLORS.gray900,
});

export const StyledSectionLabel = styled('span')({
  color: COLORS.gray500,
});

export const StyledSectionText = styled('p')({
  margin: 0,
  fontSize: '18px',
  fontWeight: 500,
  color: COLORS.gray900,
  wordBreak: 'break-word',
});

export const StyledButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '14px',
  fontWeight: 500,
  '&.ant-btn.ant-btn-ghost': {
    width: '82px',
    padding: '5px 16px',
    borderColor: COLORS.gray400,
    borderRadius: '4px',
    '& > svg': {
      marginLeft: '8px',
    },
  },
});

export const StyledArrowRight = styled('div')({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  borderTop: '2px solid',
  borderRight: '2px solid',
  borderColor: COLORS.gray700,
  transform: 'rotate(45deg)',
  transition: 'border-color .3s',
});
