import { FC } from 'react';
import { useNavigate } from 'react-router';

import { APP_ROUTE } from 'constants/appRoutes';

import LearnMoreModalFooter from './LearnMoreModalFooter';

const LearnMoreModalFooterContainer: FC = () => {
  const navigateTo = useNavigate();

  const handleButtonClick = () => {
    navigateTo(APP_ROUTE.help);
  };

  return <LearnMoreModalFooter handleButtonClick={handleButtonClick} />;
};

export default LearnMoreModalFooterContainer;
