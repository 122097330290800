import {
  visaIconSrc,
  mastercardIconSrc,
  discoverIconSrc,
  amexIconSrc,
} from 'assets/images/cardBrandsLarge';
import {
  VisaBrandIcon,
  MasterCarBrandIcon,
  DiscoverBrandIcon,
  AmexBrandIcon,
} from 'assets/vectors/cardBrands';
import { TPaymentCardBrandLookups } from 'types/customer';

export const ALLOWED_CARD_BRANDS = [
  'visa',
  'mastercard',
  'discover',
  'american_express',
] as const;

export const CARD_LOGO_LOOKUP: TPaymentCardBrandLookups<JSX.Element> = {
  visa: <VisaBrandIcon />,
  mastercard: <MasterCarBrandIcon />,
  discover: <DiscoverBrandIcon />,
  american_express: <AmexBrandIcon />,
};

export const CARD_LOGO_LARGE_LOOKUP: TPaymentCardBrandLookups<JSX.Element> = {
  visa: <img src={visaIconSrc} alt='visaIconLarge' />,
  mastercard: <img src={mastercardIconSrc} alt='mastercardIconLarge' />,
  discover: <img src={discoverIconSrc} alt='discoverIconLarge' />,
  american_express: <img src={amexIconSrc} alt='amexIconLarge' />,
};

export const CARD_NAME_LOOKUP: TPaymentCardBrandLookups = {
  visa: 'Visa',
  mastercard: 'MasterCard',
  discover: 'Discover',
  american_express: 'American Express',
};

export const OTHER_CARD_NAME = 'Other';
