import { FC } from 'react';
import type { MenuProps } from 'antd';

import Tooltip from 'components/Tooltip';
import Dropdown from 'components/Dropdown';
import {
  SelectSubscriptionPlanModal as EditSubscriptionPlanModal,
  PaymentMethodModal,
} from 'components/Payment';

import { DropdownMenuIcon } from './styled';
import { ISubscriptionActionsProps } from './types';
import {
  ACTIONS_BUTTONS_TEXT,
  SUBSCRIPTION_ACTIONS_TOOLTIP,
} from './constants';

const SubscriptionActions: FC<ISubscriptionActionsProps> = ({
  subscription,
  paymentCards,
  isEditSubscriptionModalOpened,
  isPaymentMethodModalOpened,
  isUpdatePaymentMethodLoading,
  handleLockButtonClick,
  handleCloseEditSubscriptionModal,
  handleOpenEditSubscriptionModal,
  handleOpenPaymentMethodModal,
  handleClosePaymentMethodModal,
  handleUpdatePaymentMethod,
  handleChangeSubscriptionPlanSuccess,
}) => {
  const dropdownItems: MenuProps['items'] = [
    {
      key: `${subscription._id}#1`,
      label: ACTIONS_BUTTONS_TEXT.editPlan,
      onClick: handleOpenEditSubscriptionModal,
    },
    {
      key: `${subscription._id}#2`,
      label: ACTIONS_BUTTONS_TEXT.lock,
      onClick: handleLockButtonClick,
    },
    {
      key: `${subscription._id}#3`,
      label: ACTIONS_BUTTONS_TEXT.editPayment,
      onClick: handleOpenPaymentMethodModal,
    },
  ];

  return (
    <>
      <Dropdown
        items={dropdownItems}
        trigger={['click']}
        placement='bottomRight'
      >
        <Tooltip title={SUBSCRIPTION_ACTIONS_TOOLTIP} placement='right'>
          <DropdownMenuIcon />
        </Tooltip>
      </Dropdown>
      <EditSubscriptionPlanModal
        subscription={subscription}
        isModalOpened={isEditSubscriptionModalOpened}
        handleCloseModal={handleCloseEditSubscriptionModal}
        handleChangeSubscriptionPlanSuccess={
          handleChangeSubscriptionPlanSuccess
        }
      />
      <PaymentMethodModal
        paymentCards={paymentCards}
        currentPaymentSourceId={subscription?.overrideCard?.sourceId}
        isUpdatePaymentMethodLoading={isUpdatePaymentMethodLoading}
        isModalOpened={isPaymentMethodModalOpened}
        handleCloseModal={handleClosePaymentMethodModal}
        handleUpdatePaymentMethod={handleUpdatePaymentMethod}
      />
    </>
  );
};

export default SubscriptionActions;
