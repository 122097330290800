import { FC } from 'react';

import { StyledSubTitle } from './styled';
import { IPageSubtitleProps } from './types';

const PageSubtitle: FC<IPageSubtitleProps> = ({ children, ...props }) => (
  <StyledSubTitle {...props}>{children}</StyledSubTitle>
);

export default PageSubtitle;
