import { FC } from 'react';
import { Dropdown as AntdDropdown } from 'antd';

import { TDropdownProps } from './types';
import { StyledMenu } from './styled';

export const Dropdown: FC<TDropdownProps> = ({ children, items, ...props }) => (
  <AntdDropdown
    trigger={['click']}
    overlay={<StyledMenu items={items} />}
    getPopupContainer={(triggerNode: HTMLElement) =>
      triggerNode?.parentNode as HTMLElement
    }
    {...props}
  >
    {children}
  </AntdDropdown>
);

export default Dropdown;
