import { useMutation, UseMutationResult } from 'react-query';

import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { NOTIFICATIONS_TEXT } from 'api/constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { NotificationType } from 'types/notifications';
import { openNotificationWithIcon } from 'utils/showNotification';
import { transformRoute } from 'utils/routing';

const useCancelSubscription = (): UseMutationResult<
  void,
  TAxiosRequestError,
  string
> => {
  const handleCancelSubscriptionSuccess = () => {
    openNotificationWithIcon(
      NotificationType.success,
      NOTIFICATION_SUCCESS_MESSAGE.subscriptionLocked,
    );
    queryClient.refetchQueries([QUERY_KEYS.SUBSCRIPTIONS_LIST]);
    queryClient.refetchQueries([QUERY_KEYS.SUBSCRIPTION_PLANS]);
  };

  const handleCancelSubscriptionError = (error: TAxiosRequestError) => {
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data.message || NOTIFICATIONS_TEXT.WRONG,
    );
  };

  return useMutation(
    async (subscriptionId: string) => {
      await apiClient.patch(
        transformRoute(ApiRoutes.CANCEL_SUBSCRIPTION, subscriptionId),
      );
    },
    {
      onSuccess: handleCancelSubscriptionSuccess,
      onError: handleCancelSubscriptionError,
    },
  );
};

export default useCancelSubscription;
