import { TFormikConfig } from 'types/formik';
import { ISerialNumber } from 'types/serialNumber';
import { TPhoneNumberCountryCode } from 'types/phoneNumbers';

export enum ShareSmsFormField {
  PHONE_NUMBER = 'phoneNumber',
  COUNTRY_CODE = 'countryCode',
}

export interface IShareSmsFormValues {
  [ShareSmsFormField.PHONE_NUMBER]: string;
  [ShareSmsFormField.COUNTRY_CODE]: TPhoneNumberCountryCode;
}

export interface IShareSmsFormContainerProps {
  serialNumber: ISerialNumber;
  handleCloseModal: () => void;
}

export interface IShareSmsFormProps
  extends Omit<IShareSmsFormContainerProps, 'serialNumber'> {
  formik: TFormikConfig<IShareSmsFormValues> & {
    initialValues: IShareSmsFormValues;
  };
  handleChangePhoneNumberValue: (string: string) => void;
  handleChangeCountryCodeValue: (value: unknown) => void;
}
