import { FC } from 'react';

import Badge from '../Badge';
import { TSubscriptionStatusesProps } from '../Badge/types';

const ActiveSubscriptionBadge: FC<TSubscriptionStatusesProps> = ({
  isSmallSize,
}) => <Badge type='success-light' title='Active' isSmallSize={isSmallSize} />;

export default ActiveSubscriptionBadge;
