import styled from 'styled-components';

export const PaymentMethodModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '60px 90px 30px',
  textAlign: 'center',
  minHeight: '462px',
  '@media (max-width: 600px)': {
    padding: '60px 0',
  },
});
