import { FC } from 'react';

import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';

import ShareSmsModal from './ShareSmsModal';
import { IShareSmsModalContainerProps } from './types';

const ShareSmsModalContainer: FC<IShareSmsModalContainerProps> = ({
  serialNumber,
}) => {
  const { isShareSmsModalOpen, handleShareSmsModalClose } =
    useSerialNumbersDetailsModalContext();

  return (
    <ShareSmsModal
      isModalOpened={isShareSmsModalOpen}
      serialNumber={serialNumber}
      handleCloseModal={handleShareSmsModalClose}
    />
  );
};

export default ShareSmsModalContainer;
