import { FC } from 'react';

import { PasswordInput } from 'components/Inputs';
import CustomAlert from 'components/CustomAlert';

import { StyledForm, StyledButton } from './styled';
import { RESET_PASSWORD_TEXT, LINK_EXPIRED_SUBSTRING } from '../constants';
import { IResetPasswordFormProps, ResetPasswordField } from '../types';

const ResetPasswordForm: FC<IResetPasswordFormProps> = ({
  formik,
  resetPasswordErrorMessage,
  checkResetExpiredErrorMessage,
  isCheckResetExpiredError,
  isResetPasswordLoading,
}) => {
  const { errors, getFieldProps, handleSubmit } = formik;

  const isLinkExpiredMessage =
    resetPasswordErrorMessage &&
    resetPasswordErrorMessage.includes(LINK_EXPIRED_SUBSTRING);

  return (
    <>
      {isCheckResetExpiredError || isLinkExpiredMessage ? (
        <CustomAlert
          type='error'
          message={checkResetExpiredErrorMessage || resetPasswordErrorMessage}
        />
      ) : (
        <StyledForm onSubmit={handleSubmit}>
          <PasswordInput
            withPopover
            labelText={RESET_PASSWORD_TEXT.passwordLabel}
            errorMessage={errors.password}
            {...getFieldProps(ResetPasswordField.password)}
          />
          <StyledButton
            htmlType='submit'
            size='large'
            loading={isResetPasswordLoading}
          >
            {RESET_PASSWORD_TEXT.resetButtonText}
          </StyledButton>
        </StyledForm>
      )}
    </>
  );
};

export default ResetPasswordForm;
