import { FC, useState, useEffect, useRef } from 'react';

import { env } from 'config/env';
import { EMPTY_ARRAY, EMPTY_OBJECT, NULL_INDEX } from 'constants/common';
import { useGetNotifications, useReadNotifications } from 'api/notifications';
import { useToggle } from 'hooks';
import { INotification } from 'types/notification';

import {
  NOTIFICATIONS_PER_PAGE,
  DEFAULT_PAGE_VALUE,
  DEFAULT_UNREAD_AMOUNT,
} from './constants';
import Notifications from './Notifications';
import { splitNotifications } from './utils';

const NotificationsContainer: FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const notificationIconRef = useRef<HTMLDivElement | null>(null);

  const [page, setPage] = useState<number>(DEFAULT_PAGE_VALUE);
  const [unreadAmount, setUnreadAmount] = useState<number>(
    DEFAULT_UNREAD_AMOUNT,
  );
  const [totalQueryAmount, setTotalQueryAmount] = useState<number>(
    DEFAULT_UNREAD_AMOUNT,
  );
  const [filters, setFilter] = useState(EMPTY_OBJECT);
  const [notifications, setNotifications] =
    useState<INotification[]>(EMPTY_ARRAY);
  const [isUnreadShown, setIsUnreadShown] = useState<boolean>(false);

  const [isNotificationBarVisible, setNotificationBarVisible] =
    useToggle(false);

  const {
    data: notificationsResponse,
    refetch,
    isFetching,
  } = useGetNotifications(!isNotificationBarVisible, {
    page,
    filters,
    currency: env.REACT_APP_CURRENCY_TYPE || 'IQD', //TODO: replace after adding different app types by currency
    limit: NOTIFICATIONS_PER_PAGE,
  });

  useEffect(() => {
    if (isNotificationBarVisible) {
      refetch();
    } else {
      setPage(DEFAULT_PAGE_VALUE);
      setTotalQueryAmount(DEFAULT_UNREAD_AMOUNT);
    }
    setFilter((filter) => ({
      ...filter,
      endDate: isNotificationBarVisible ? new Date() : null,
    }));
  }, [isNotificationBarVisible]);

  const { mutate: readNotifications } = useReadNotifications();

  const { todayNotifications, olderNotifications } = splitNotifications(
    isUnreadShown
      ? notifications.filter((notification) => !notification.isRead)
      : notifications,
  );
  useEffect(() => {
    if (notificationsResponse && !isFetching) {
      const updatedNotificationsList =
        page !== DEFAULT_PAGE_VALUE
          ? notifications.concat(notificationsResponse.notifications)
          : notificationsResponse.notifications;
      const updatedTotalAmount =
        page !== DEFAULT_PAGE_VALUE
          ? totalQueryAmount + notificationsResponse.totalQueryItems
          : notificationsResponse.totalQueryItems;
      setNotifications(updatedNotificationsList);
      setUnreadAmount(notificationsResponse.totalUnreadItems);
      setTotalQueryAmount(updatedTotalAmount);
    }
  }, [notificationsResponse, isFetching]);

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      const isIconClicked = Boolean(
        notificationIconRef.current &&
          notificationIconRef.current.contains(event.target as Node),
      );
      const isClickedOutside =
        ref.current && !ref.current.contains(event.target as Node);
      const shouldBeClosed =
        isNotificationBarVisible && isClickedOutside && !isIconClicked;

      if (shouldBeClosed) {
        setNotificationBarVisible();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isNotificationBarVisible]);

  const onHandleChangeVisibility = () => {
    setNotificationBarVisible();
  };

  const handleClickPagination = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  const handleReadAllNotifications = () => {
    if (unreadAmount) {
      readNotifications(notifications[NULL_INDEX]?.createdAt, {
        onSettled: () => {
          setUnreadAmount(DEFAULT_UNREAD_AMOUNT);
          setNotifications((notifications) =>
            notifications.map((notification) => ({
              ...notification,
              isRead: true,
            })),
          );
        },
      });
    }
  };

  const handleChangeQuery = (value: boolean) => {
    setFilter({
      endDate: new Date(),
      ...(value ? { isRead: !value } : EMPTY_OBJECT),
    });
    setIsUnreadShown(value);
    setPage(DEFAULT_PAGE_VALUE);
  };

  const handleReadOne = ({ _id: notificationToReadId }: INotification) => {
    const updatedList = notifications.map((notification) =>
      notification._id === notificationToReadId
        ? { ...notification, isRead: true }
        : notification,
    );

    setNotifications(updatedList);
    setUnreadAmount((prevAmount) => prevAmount - 1);

    const loadedUnreadNotificationsAmount = notifications.filter(
      (notification) => !notification.isRead,
    )?.length;

    if (
      isUnreadShown &&
      loadedUnreadNotificationsAmount <= NOTIFICATIONS_PER_PAGE &&
      unreadAmount >= NOTIFICATIONS_PER_PAGE
    ) {
      refetch();
    }
  };

  const hasMore = Boolean(
    (notificationsResponse?.totalItems || 0) - totalQueryAmount,
  );

  const isNotificationsEmpty =
    !todayNotifications?.length && !olderNotifications?.length;

  return (
    <Notifications
      config={notifications}
      todayNotifications={todayNotifications}
      olderNotifications={olderNotifications}
      totalAmount={notificationsResponse?.totalItems}
      isNotificationsEmpty={isNotificationsEmpty}
      isUnreadShown={isUnreadShown}
      hasMore={hasMore}
      unreadAmount={unreadAmount}
      isNotificationBarVisible={isNotificationBarVisible}
      onHandleChangeVisibility={onHandleChangeVisibility}
      handleChangeQuery={handleChangeQuery}
      handleClickPagination={handleClickPagination}
      handleReadAllNotifications={handleReadAllNotifications}
      handleReadOne={handleReadOne}
      isLoading={isFetching}
      boxRef={ref}
      notificationIconRef={notificationIconRef}
    />
  );
};

export default NotificationsContainer;
