import { FC } from 'react';

import Badge from '../Badge';
import { TSubscriptionStatusesProps } from '../Badge/types';

const CanceledSubscriptionBadge: FC<TSubscriptionStatusesProps> = ({
  isSmallSize,
}) => <Badge type='error-light' title='Canceled' isSmallSize={isSmallSize} />;

export default CanceledSubscriptionBadge;
