import styled from 'styled-components';
import { Checkbox } from 'antd';

import Button from 'components/Button';
import { PaymentIcons } from 'components/Payment';
import { COLORS } from 'constants/colorPalette';

export const PaymentFormWrapper = styled('div')({
  border: `2px solid ${COLORS.gray300}`,
  borderRadius: '12px',
  backgroundColor: COLORS.white,
  margin: '60px auto 44px',
});

export const StyledPaymentIcons = styled(PaymentIcons)({
  display: 'flex',
  justifyContent: 'center',
  gap: '32px',
  padding: '35px',
  borderBottom: `2px solid ${COLORS.gray100}`,
  '@media (max-width: 768px)': {
    gap: '16px',
    padding: '16px 12px',
  },
});

export const StyledPaymentForm = styled('div')({
  padding: '0 16px',
  maxWidth: '448px',
  width: '100%',
  margin: '32px auto',
  textAlign: 'left',
});

export const PaymentFormTitle = styled('h3')({
  fontSize: '24px',
  lineHeight: '34px',
  fontWeight: 600,
  color: COLORS.gray800,
  marginBottom: '2px',
});

export const PaymentFormSubtitle = styled('p')({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 500,
  color: COLORS.gray500,
  marginBottom: '32px',
});

export const PaymentFormCheckbox = styled(Checkbox)({
  '& .ant-checkbox + span': {
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: 500,
    color: COLORS.gray700,
    padding: '0 16px',
    whiteSpace: 'pre-line',
    '@media (max-width: 768px)': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
});

export const PaymentCheckboxLink = styled(Button)({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 500,
  padding: 0,
  height: '25px',
  border: 'none',
  '@media (max-width: 768px)': {
    fontSize: '14px',
    lineHeight: '20px',
    height: '20px',
  },
});

export const PaymentInfoWrapper = styled('div')({
  padding: '0 80px 30px',
  width: '100%',
  textAlign: 'left',
  '@media (max-width: 768px)': {
    padding: '0 16px 30px',
  },
});

export const PaymentSubmitButton = styled(Button)({
  width: '100%',
  marginBottom: '24px',
});

export const PaymentSecureText = styled('p')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '12px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
  color: COLORS.gray500,
  marginBottom: 0,
  '> svg': {
    width: '16px',
    height: '16px',
  },
});
