import { FC } from 'react';

import { LEARN_MORE_MODAL_FOOTER_TEXT } from './constants';
import {
  LearnMoreModalFooterWrapper,
  LearnMoreModalFooterText,
  LearnMoreModalFooterButton,
} from './styled';
import { ILearnMoreModalFooterProps } from './types';

const LearnMoreModalFooter: FC<ILearnMoreModalFooterProps> = ({
  handleButtonClick,
}) => (
  <LearnMoreModalFooterWrapper>
    <LearnMoreModalFooterText>
      {LEARN_MORE_MODAL_FOOTER_TEXT.footerTitle}
    </LearnMoreModalFooterText>
    <LearnMoreModalFooterButton size='large' onClick={handleButtonClick}>
      {LEARN_MORE_MODAL_FOOTER_TEXT.footerButtonText}
    </LearnMoreModalFooterButton>
  </LearnMoreModalFooterWrapper>
);

export default LearnMoreModalFooter;
