import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import PaymentBanner from 'pages/Payment/PaymentBanner';

export const PaymentWrapper = styled('div')({
  width: '100%',
  padding: '0 18px 60px',
  margin: '60px auto',
  textAlign: 'center',
  maxWidth: '900px',
});

export const PaymentTitle = styled('h1')({
  fontSize: '32px',
  lineHeight: '44px',
  fontWeight: 800,
  marginBottom: '36px',
  color: COLORS.gray900,
  '@media (max-width: 768px)': {
    fontSize: '26px',
    lineHeight: '38px',
  },
});

export const StyledPaymentBanner = styled(PaymentBanner)({
  background: COLORS.gray100,
  border: `1px solid ${COLORS.gray300}`,
  borderRadius: '12px',
  marginBottom: '32px',
});
