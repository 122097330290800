export const EMAIL_PATTERN = {
  GENERAL_STRUCTURE: /^[A-Za-z0-9!#$%&'*+\-=?^_`.{|}~]+@[A-Za-z0-9-.]+$/,
  GENERAL_TRAILINGS: /(\.\.|--)/,
  LOCAL_PART_GENERAL: /^([A-Za-z0-9!#$%&'*+\-=?^_`.{|}~]){1,64}@/,
  LOCAL_PART_BORDERS:
    /^([A-Za-z0-9!#$%&'*+=?^_`{|}~]|([^-.][A-Za-z0-9!#$%&'*+\-=?^_`.{|}~]*[^-.]))@/,
  DOMAIN_PART_GENERAL: /@([A-Za-z0-9-]{1,125}\.)+[A-Za-z0-9-]{2,125}$$/,
  DOMAIN_PART_TOTAL_LENGTH: /@.{4,190}$/,
  DOMAIN_PART_BORDERS: /@[^-].+[^-]$/,
};

export const VALIDATION_TEST_NAME = {
  GENERAL_TRAILINGS: 'no-trailings',
  DOMAIN_BORDERS: 'no-hyphens-start-end',
};

export const USER_VALIDATION_ERROR_MESSAGES = {
  emailRequired: 'Please enter your email',
  emailInvalid: 'Please enter a valid email',
  createPassword: 'Please create your password',
  numbersAndLettersAllowedError: 'Only Latin letters and numbers are allowed',
  passwordLengthError: 'Must contain at least 10 characters',
  oneLatinLetterContainError: 'Must contain at least one Latin letter',
  oneNumberContainError: 'Must contain at least one number',
  oneUppercaseLetterContainError: 'Must contain at least one uppercase letter',
  oneLowerCaseLetterContainError: 'Must contain at least one lowercase letter',
  firstNameRequired: 'Please enter your first name',
  lastNameRequired: 'Please enter your last name',
  countryCodeRequired: 'Please enter code',
  phoneNumberRequired: 'Please enter your phone number',
  phoneNumberInvalid: 'Please enter a valid phone number',
  addressRequired: 'Please enter your address',
};

export const MAX_VERIFICATION_CODE_LENGTH = 6;
export const MIN_PASSWORD_LENGTH = 10;
export const MIN_PHONE_NUMBER_LENGTH = 10;
export const SERIAL_NUMBER_LENGTH = 19;
export const MAX_EMAIL_LENGTH = 70;
export const MAX_NAME_LENGTH = 150;
export const MAX_COMPANY_NAME_LENGTH = 150;
export const MAX_ADDRESS_LENGTH = 150;
export const MAX_CITY_LENGTH = 50;
export const MAX_ZIP_FIELD_LENGTH = 50;
