import { OtherBrandsIcon } from 'assets/vectors/cardBrands';
import {
  CARD_LOGO_LARGE_LOOKUP,
  CARD_LOGO_LOOKUP,
  CARD_NAME_LOOKUP,
  OTHER_CARD_NAME,
} from 'constants/payment';

import { checkIsAllowedCardBrand } from './checkIsAllowedCardBrand';

export const getCardIconByBrand = (brand: string): JSX.Element => {
  const isAllowedCardBrand = checkIsAllowedCardBrand(brand);

  return isAllowedCardBrand ? CARD_LOGO_LOOKUP[brand] : <OtherBrandsIcon />;
};

export const getCardLargeIconByBrand = (brand: string): JSX.Element => {
  const isAllowedCardBrand = checkIsAllowedCardBrand(brand);

  return isAllowedCardBrand ? (
    CARD_LOGO_LARGE_LOOKUP[brand]
  ) : (
    <OtherBrandsIcon />
  );
};

export const getCardNameByBrand = (brand: string): string => {
  const isAllowedCardBrand = checkIsAllowedCardBrand(brand);

  return isAllowedCardBrand ? CARD_NAME_LOOKUP[brand] : OTHER_CARD_NAME;
};
