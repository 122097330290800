import { FC } from 'react';

import {
  LearnMoreModalHeaderWrapper,
  LearnMoreModalHeaderTitle,
  LearnMoreModalHeaderSubtitle,
} from './styled';
import { ILearnMoreModalHeaderProps } from './types';

const LearnMoreModalHeader: FC<ILearnMoreModalHeaderProps> = ({
  title,
  subtitle,
  ...props
}) => (
  <LearnMoreModalHeaderWrapper {...props}>
    <LearnMoreModalHeaderTitle>{title}</LearnMoreModalHeaderTitle>
    <LearnMoreModalHeaderSubtitle>{subtitle}</LearnMoreModalHeaderSubtitle>
  </LearnMoreModalHeaderWrapper>
);

export default LearnMoreModalHeader;
