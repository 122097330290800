import { FC } from 'react';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { NOT_AVAILABLE, NULL_TYPE_VALUE } from 'constants/common';
import { INITIAL_BILLING_ADDRESS_FORM_VALUES } from 'components/BillingAddress/constants';
import { BILLING_ADDRESS_VALIDATION_SCHEME } from 'components/BillingAddress/validation';
import {
  useCustomerBillingAddress,
  useFormattedBillingAddress,
} from 'components/BillingAddress/hooks';
import { BillingAddressFormField } from 'components/BillingAddress/types';
import { useFormik } from 'hooks';

import { DEFAULT_BUTTONS_TEXT } from './constants';
import { IBillingAddressFormContainerProps } from './types';
import BillingAddressForm from './BillingAddressForm';

export const BillingAddressFormContainer: FC<
  IBillingAddressFormContainerProps
> = ({
  buttonsText = DEFAULT_BUTTONS_TEXT,
  initialBillingAddressValues = INITIAL_BILLING_ADDRESS_FORM_VALUES,
  isFormActionLoading = false,
  isOnDirtySubmitDisable = false,
  isAsHomeCheckboxHidden = false,
  handleSubmitBillingAddressForm,
  ...props
}) => {
  const billingAddressFromCustomer = useCustomerBillingAddress();
  const formattedBillingAddressFromCustomer = useFormattedBillingAddress(
    billingAddressFromCustomer,
  );

  const formattedInitialBillingAddressValues = useFormattedBillingAddress(
    initialBillingAddressValues,
  );

  const formik = useFormik({
    initialValues: formattedInitialBillingAddressValues,
    validationSchema: BILLING_ADDRESS_VALIDATION_SCHEME,
    enableReinitialize: true,
    onSubmit: handleSubmitBillingAddressForm,
  });

  const handleAddressCheckboxCheck = ({ target }: CheckboxChangeEvent) => {
    if (target.checked) {
      formik.setValues({
        ...formattedBillingAddressFromCustomer,
        [BillingAddressFormField.COMPANY]: NOT_AVAILABLE,
        [BillingAddressFormField.SAME_WITH_PROFILE]: true,
      });
    } else {
      formik.setFieldValue(BillingAddressFormField.SAME_WITH_PROFILE, false);
      formik.setFieldValue(BillingAddressFormField.COMPANY, NULL_TYPE_VALUE);
    }
  };

  const isFormDisabled = Boolean(
    formik.values[BillingAddressFormField.SAME_WITH_PROFILE],
  );

  return (
    <BillingAddressForm
      formik={formik}
      buttonsText={buttonsText}
      isAsHomeCheckboxHidden={isAsHomeCheckboxHidden}
      isOnDirtySubmitDisable={isOnDirtySubmitDisable}
      isFormActionLoading={isFormActionLoading}
      isFormDisabled={isFormDisabled}
      handleAddressCheckboxCheck={handleAddressCheckboxCheck}
      {...props}
    />
  );
};

export default BillingAddressFormContainer;
