import { FC } from 'react';

import { NotVerifiedIcon } from 'assets/vectors';
import LearnMoreModal, {
  LearnMoreModalFooter,
  LearnMoreModalSteps,
} from 'components/LearnMoreModal';
import { IModalDialogProps } from 'components/ModalDialog/types';

import {
  LearnMoreModalTitle,
  NotVerifiedModalTitleWrapper,
  NotVerifiedModalHeader,
} from './styled';
import {
  NOT_VERIFIED_MODAL_CONTENT,
  SERIAL_NUMBER_MODAL_WIDTH,
} from './constants';

const NotVerifiedModal: FC<IModalDialogProps> = ({
  isModalOpened,
  handleCloseModal,
}) => (
  <LearnMoreModal
    width={SERIAL_NUMBER_MODAL_WIDTH}
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
  >
    <>
      <NotVerifiedModalTitleWrapper>
        <NotVerifiedIcon />
        <LearnMoreModalTitle>
          {NOT_VERIFIED_MODAL_CONTENT.title}
        </LearnMoreModalTitle>
      </NotVerifiedModalTitleWrapper>
      <NotVerifiedModalHeader
        title={NOT_VERIFIED_MODAL_CONTENT.subtitle}
        subtitle={NOT_VERIFIED_MODAL_CONTENT.text}
      />
      <LearnMoreModalSteps stepsList={NOT_VERIFIED_MODAL_CONTENT.steps} />
      <LearnMoreModalFooter />
    </>
  </LearnMoreModal>
);

export default NotVerifiedModal;
