import { useState } from 'react';
import { useQuery } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { QUERY_KEYS } from 'constants/queryKeys';
import { PDF_BLOB_TYPE } from 'pages/SerialNumberDetails/utils/constants';
import {
  UPLOAD_PROGRESS_TOTAL_PERCENTAGE,
  DOWNLOAD_PROGRESS_TOTAL_PERCENTAGE,
} from 'api/constants';
import { NULL_VALUE } from 'constants/common';

import { IUploadEncryptPdfPayload, TUploadEncryptPdf } from './types';
import {
  FORM_DATA_FILE,
  FORM_DATA_OPTIONS,
  RESPONSE_JSON_TYPE,
  STALE_TIME_MS,
} from './constants';

const useUploadEncryptPdf = ({
  pdfBytes,
  pdfWithPassword,
  serialNumberId,
  isEnabled,
}: IUploadEncryptPdfPayload): TUploadEncryptPdf => {
  const [progress, setProgress] = useState<number>(NULL_VALUE);

  const queryResult = useQuery(
    [QUERY_KEYS.UPLOAD_ENCRYPT_PDF, serialNumberId, pdfWithPassword],
    async () => {
      const formData = new FormData();

      const blobOptions = new Blob(
        [
          JSON.stringify({
            pdfWithPassword,
            serialNumberId,
          }),
        ],
        {
          type: RESPONSE_JSON_TYPE,
        },
      );

      const blobFile = new Blob([pdfBytes as ArrayBuffer], {
        type: PDF_BLOB_TYPE,
      });

      formData.append(FORM_DATA_FILE, blobFile);
      formData.append(FORM_DATA_OPTIONS, blobOptions);

      const { data: uploadEncryptPdfResponse } = await apiClient.post(
        ApiRoutes.SERIAL_NUMBER_UPLOAD_PDF,
        formData,
        {
          onDownloadProgress: (progressEvent) =>
            setProgress(
              UPLOAD_PROGRESS_TOTAL_PERCENTAGE +
                Math.floor(
                  (progressEvent.loaded / progressEvent.total) *
                    DOWNLOAD_PROGRESS_TOTAL_PERCENTAGE,
                ),
            ),
          onUploadProgress: (progressEvent) =>
            setProgress(
              Math.floor(
                (progressEvent.loaded / progressEvent.total) *
                  UPLOAD_PROGRESS_TOTAL_PERCENTAGE,
              ),
            ),
        },
      );
      return uploadEncryptPdfResponse;
    },
    {
      enabled: isEnabled,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME_MS, // 10 minutes
    },
  );

  return { ...queryResult, progress };
};

export default useUploadEncryptPdf;
