import { FC } from 'react';

import { SAVE_AND_SHARE_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { PhoneNumberInput } from 'components/Inputs';

import {
  CancelButton,
  ShareActionsWrapper,
  ShareButton,
  SharedSubtitle,
} from '../styled';
import { SHARE_MODAL_SUBTITLE } from '../constants';
import { SHARE_SMS_FORM_TEXT } from './constants';
import { SmsInputLabel } from './styled';
import { IShareSmsFormProps, ShareSmsFormField } from './types';

const ShareSmsForm: FC<IShareSmsFormProps> = ({
  formik,
  handleCloseModal,
  handleChangeCountryCodeValue,
  handleChangePhoneNumberValue,
}) => {
  const { errors, handleSubmit, getFieldProps } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <SharedSubtitle>{SHARE_MODAL_SUBTITLE}</SharedSubtitle>
      <SmsInputLabel>{SHARE_SMS_FORM_TEXT.PHONE_INPUT_LABEL}</SmsInputLabel>
      <PhoneNumberInput
        phoneNumberInputProps={{
          id: ShareSmsFormField.PHONE_NUMBER,
          labelText: SHARE_SMS_FORM_TEXT.PHONE_NUMBER_LABEL,
          errorMessage: errors.phoneNumber,
          handleChangePhoneNumberFormik: handleChangePhoneNumberValue,
          ...getFieldProps(ShareSmsFormField.PHONE_NUMBER),
        }}
        countrySelectProps={{
          id: ShareSmsFormField.COUNTRY_CODE,
          errorMessage: errors.countryCode,
          handleChangeCountryCodeFormik: handleChangeCountryCodeValue,
          ...getFieldProps(ShareSmsFormField.COUNTRY_CODE),
        }}
      />
      <ShareActionsWrapper>
        <CancelButton type='ghost' onClick={handleCloseModal}>
          {SHARE_SMS_FORM_TEXT.CANCEL_LABEL}
        </CancelButton>
        <ShareButton
          htmlType='submit'
          id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
          type='primary'
        >
          {SHARE_SMS_FORM_TEXT.SHARE_LABEL}
        </ShareButton>
      </ShareActionsWrapper>
    </form>
  );
};

export default ShareSmsForm;
