import { FC, useEffect, useState } from 'react';

import { ISubscriptionPlan } from 'types/subscriptions';

import { checkIsYearlyPlan } from '../utils';
import { ISelectSubscriptionContainerProps } from './types';
import SelectSubscription from './SelectSubscription';

const SelectSubscriptionContainer: FC<ISelectSubscriptionContainerProps> = ({
  serialNumber,
  subscriptionPlans,
  selectedSubscription,
  handleChangeSubscription,
}) => {
  const [displayedSubscriptions, setDisplayedSubscriptions] = useState<
    ISubscriptionPlan[]
  >([]);

  useEffect(() => {
    if (subscriptionPlans.length) {
      const yearlyPlan = subscriptionPlans.find((plan) =>
        checkIsYearlyPlan(plan),
      ) as ISubscriptionPlan;
      handleChangeSubscription(yearlyPlan);
      setDisplayedSubscriptions([yearlyPlan]);
    }
  }, [subscriptionPlans]);

  const handleSwitchSubscriptions = () =>
    setDisplayedSubscriptions(subscriptionPlans);

  return (
    <SelectSubscription
      serialNumber={serialNumber}
      subscriptionPlans={subscriptionPlans}
      displayedSubscriptions={displayedSubscriptions}
      selectedSubscription={selectedSubscription}
      handleChangeSubscription={handleChangeSubscription}
      handleSwitchSubscriptions={handleSwitchSubscriptions}
    />
  );
};

export default SelectSubscriptionContainer;
