import { IResponseMessage } from 'constants/messages';
import { TFormik } from 'types/formik';
import { TPhoneNumberCountryCode } from 'types/phoneNumbers';

export enum UserStepperKeys {
  email,
  verificationCode,
  password,
  fullName,
  phoneNumber,
  address,
}

export type TUserStepText = {
  [key in UserStepperKeys]: {
    title: string;
    subtitle: string;
    hint?: string;
  };
};

export enum UserFormField {
  EMAIL = 'email',
  VERIFICATION_CODE = 'verificationCode',
  PASSWORD = 'password',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  COUNTRY_CODE = 'countryCode',
  ADDRESS = 'address',
}

export interface IUserFormValues {
  [UserFormField.EMAIL]: string;
  [UserFormField.VERIFICATION_CODE]: string;
  [UserFormField.PASSWORD]: string;
  [UserFormField.FIRST_NAME]: string;
  [UserFormField.LAST_NAME]: string;
  [UserFormField.PHONE_NUMBER]: string;
  [UserFormField.COUNTRY_CODE]: TPhoneNumberCountryCode;
  [UserFormField.ADDRESS]: string;
}

export interface IUserFieldsProps {
  formik: TFormik<IUserFormValues>;
  handleChangeResponseMessage?: (message: IResponseMessage | null) => void;
  responseMessage?: IResponseMessage | null;
}

export interface TUserFormContainerProps {
  currentStep: UserStepperKeys;
  formValues: IUserFormValues | null;
  handleFormValues: (values: IUserFormValues) => void;
  handleChangeStep: (key: UserStepperKeys) => void;
  handleBackToVerificationStep: () => void;
  isSignUpLoading?: boolean;
}

export interface TUserFormProps {
  formik: TFormik<IUserFormValues>;
  currentStep: UserStepperKeys;
  handleBack: () => void;
  handleChangeResponseMessage: (message: IResponseMessage | null) => void;
  isSignUpLoading?: boolean;
  responseMessage?: IResponseMessage | null;
}
