import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const IconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '60px',
  height: '60px',
  border: `1px solid ${COLORS.gray300}`,
  borderRadius: '12px',
  '> img': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '40px',
    height: '40px',
  },
});
