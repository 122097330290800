import { FC } from 'react';

import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';

import ShareEmailModal from './ShareEmailModal';
import { IShareEmailModalContainerProps } from './types';

const ShareEmailModalContainer: FC<IShareEmailModalContainerProps> = ({
  serialNumber,
}) => {
  const { isShareEmailModalOpen, handleShareEmailModalClose } =
    useSerialNumbersDetailsModalContext();

  return (
    <ShareEmailModal
      isModalOpened={isShareEmailModalOpen}
      serialNumber={serialNumber}
      handleCloseModal={handleShareEmailModalClose}
    />
  );
};

export default ShareEmailModalContainer;
