import styled from 'styled-components';

export const ContentText = styled('p')({
  maxWidth: '500px',
  marginBottom: 0,
  fontWeight: 500,
  whiteSpace: 'pre-line',
  letterSpacing: '0.2px',
  lineHeight: '19.6px',
});
