import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const BillingAddressModalWrapper = styled('div')({
  width: '100%',
  padding: '30px 18px',
});

export const BillingAddressModalTitle = styled('h3')({
  marginBottom: '20px',
  fontSize: '24px',
  fontWeight: 600,
  color: COLORS.gray900,
});
