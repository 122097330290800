import { FC } from 'react';

import { Loader } from 'components/Loader';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { calculatePercentage } from 'utils/numbers';

import {
  DISCOUNT_BANNER_TEXT,
  PAYMENT_PAGE_TEXT,
  TRIAL_BANNER_TEXT,
} from './constants';
import { IPaymentProps } from './types';
import { PaymentTitle, PaymentWrapper, StyledPaymentBanner } from './styled';
import { checkIsFirstSubscription, checkIsYearlyPlan } from './utils';
import SelectSubscription from './SelectSubscription';
import PaymentBanner, { PaymentBannerType } from './PaymentBanner';
import PaymentForm from './PaymentForm';
import PaymentWidgets from './PaymentWidgets';

const Payment: FC<IPaymentProps> = ({
  serialNumber,
  subscriptionPlans,
  selectedSubscription,
  customerPaymentAndBillingAddress,
  isLoading,
  handleChangeSubscription,
}) => {
  const isEarlySubscriptionSelected = checkIsYearlyPlan(selectedSubscription);
  const withPaymentBanner = checkIsFirstSubscription(serialNumber);

  const [yearlyPlan, monthlyPlan] = subscriptionPlans;
  const monthlyPlanPrice = monthlyPlan?.pricePerYear as number;
  const yearlyPlanPrice = yearlyPlan?.price as number;
  const savingsPercentage = calculatePercentage(
    monthlyPlanPrice - yearlyPlanPrice,
    monthlyPlanPrice,
  );

  return (
    <HelmetPageTitle title={PAYMENT_PAGE_TEXT.pageTitle}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isEarlySubscriptionSelected && (
            <PaymentBanner
              type={PaymentBannerType.large}
              text={`${DISCOUNT_BANNER_TEXT}: ${savingsPercentage}% off`}
              withIcon
            />
          )}
          {serialNumber && (
            <PaymentWrapper>
              <PaymentTitle>{PAYMENT_PAGE_TEXT.title}</PaymentTitle>
              {withPaymentBanner && (
                <StyledPaymentBanner text={TRIAL_BANNER_TEXT} withIcon />
              )}
              <SelectSubscription
                serialNumber={serialNumber}
                subscriptionPlans={subscriptionPlans}
                selectedSubscription={selectedSubscription}
                handleChangeSubscription={handleChangeSubscription}
              />
              {selectedSubscription && (
                <PaymentForm
                  serialNumber={serialNumber}
                  subscription={selectedSubscription}
                  paymentAndBillingAddress={customerPaymentAndBillingAddress}
                  isEarlySubscriptionSelected={isEarlySubscriptionSelected}
                />
              )}
              <PaymentWidgets />
            </PaymentWrapper>
          )}
        </>
      )}
    </HelmetPageTitle>
  );
};

export default Payment;
