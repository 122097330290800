import { FC } from 'react';

import { MENU_TITLE } from 'constants/menu';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { PageSubtitle } from 'components/UnauthorizedPages';
import UnauthorizedPageWrapper from 'components/Layouts/UnauthorizedLayout/UnauthorizedPageWrapper';

import { SIGN_IN_TEXT } from './constants';
import { StyledLogo } from './styled';
import SignInForm from './SignInForm';

const SignIn: FC = () => (
  <HelmetPageTitle title={MENU_TITLE.signIn}>
    <UnauthorizedPageWrapper>
      <StyledLogo />
      <PageSubtitle>{SIGN_IN_TEXT}</PageSubtitle>
      <SignInForm />
    </UnauthorizedPageWrapper>
  </HelmetPageTitle>
);

export default SignIn;
