import { BillingAddressFormField } from 'components/BillingAddress/types';
import {
  getEmailValidationSchema,
  getFullNameValidationSchema,
  getPhoneNumberValidationSchema,
  getAddressValidationSchema,
} from 'utils/validation/userValidationScheme';

const fullNameValidationSchema = getFullNameValidationSchema(
  BillingAddressFormField.FIRST_NAME,
  BillingAddressFormField.LAST_NAME,
);

const emailValidationSchema = getEmailValidationSchema(
  BillingAddressFormField.EMAIL,
);

const phoneNumberValidationSchema = getPhoneNumberValidationSchema(
  BillingAddressFormField.COUNTRY_CODE,
  BillingAddressFormField.PHONE_NUMBER,
);

const addressValidationSchema = getAddressValidationSchema(
  BillingAddressFormField.ADDRESS,
);

export const BILLING_ADDRESS_VALIDATION_SCHEME = fullNameValidationSchema
  .concat(emailValidationSchema)
  .concat(phoneNumberValidationSchema)
  .concat(addressValidationSchema);
