import { FC } from 'react';

import ModalDialog from 'components/ModalDialog';

import { PAYMENT_METHOD_MODAL_WIDTH } from './constants';
import { PaymentMethodModalWrapper } from './styled';
import { IPaymentMethodModalProps } from './types';
import EditPaymentMethod from './EditPaymentMethod';
import AddPaymentMethod from './AddPaymentMethod';

const PaymentMethodModal: FC<IPaymentMethodModalProps> = ({
  paymentCards,
  currentPaymentSourceId,
  isAddPaymentMethodStep,
  isUpdatePaymentMethodLoading,
  isModalOpened,
  handleCloseModal,
  goToAddPaymentMethodStep,
  goToEditPaymentMethodStep,
  handleUpdatePaymentMethod,
  ...props
}) => (
  <ModalDialog
    destroyOnClose
    width={PAYMENT_METHOD_MODAL_WIDTH}
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
    {...props}
  >
    <PaymentMethodModalWrapper>
      {isAddPaymentMethodStep ? (
        <AddPaymentMethod
          goToEditPaymentMethodStep={goToEditPaymentMethodStep}
        />
      ) : (
        <EditPaymentMethod
          paymentCards={paymentCards}
          currentPaymentSourceId={currentPaymentSourceId}
          isUpdatePaymentMethodLoading={isUpdatePaymentMethodLoading}
          goToAddPaymentMethodStep={goToAddPaymentMethodStep}
          handleUpdatePaymentMethod={handleUpdatePaymentMethod}
        />
      )}
    </PaymentMethodModalWrapper>
  </ModalDialog>
);

export default PaymentMethodModal;
