import { EMPTY_STRING } from 'constants/common';

import { ForgotPasswordField } from './types';

export const INITIAL_FORGOT_PASSWORD_VALUES = {
  [ForgotPasswordField.email]: EMPTY_STRING,
};

export const FORGOT_PASSWORD_TEXT = {
  sendLinkButtonText: 'Send link to email',
  inputLabel: 'Email',
  didntReceiveEmail: "Didn't receive an email?",
  resendButtonText: 'Resend',
  backLinkText: 'Back',
  verifyPageTitle: 'Verify Your Email',
  forgotPageTitle: 'Reset your password?',
  forgotPasswordAlert:
    "Password reset link could not be sent as the time interval limit of 2 minutes didn't pass yet. Please wait a bit more to resend the link.",
};

export const RESEND_LINK_TIMER_DURATION_MS = 120000;

export const ERR_NETWORK_CODE = 'ERR_NETWORK';
