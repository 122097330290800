import styled from 'styled-components';

export const LearnMoreModalWrapper = styled('div')({
  width: '100%',
  marginTop: '40px',
  padding: '0 30px',
  '@media (max-height: 768px)': {
    maxHeight: '630px',
    overflowY: 'auto',
  },
  '@media (max-width: 768px)': {
    padding: '0 12px 0 0',
  },
});
