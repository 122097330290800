export const WELCOME_PAGE_TEXT = {
  title:
    "DinarVerify is a fin-tech company that created the world's first smart Certificate of Authenticity.",
  text: 'Your certificate has a unique serial number on it that is powered by the DinarDatabase. DinarVerify protects you with data. The DinarDatabase houses all of the data you will need to verify your IQDs are authentic. Additionally, DinarVerify houses all of the information you will have to provide a bank or exchange when you’re ready to exchange your IQDs back to USD. ',
  benefits: [
    'Currency Verification',
    'Banknote Verification',
    'Dealer Verification',
    'Currency Condition Verification',
    'Proof of Purchase',
    'Proof of Ownership',
    'Dealer Background Check',
    'Dealer Records',
    'Authenticity Verification',
    'Central Bank Issued Verification',
    'Serial Number Verification',
    'Serial Number Translation',
    'Order Records',
    'Payment Records',
    'Full Exchange Audit',
    'Banknote Pictures Front & Back',
  ],
  warningTitle: 'Warning!',
  warningText:
    'Please be advised that if you fail to unlock your serial numbers within 90 days our system will automatically delete your Dinar records from the database. This means if you change your mind after 90 days or find out that you in fact need the data to complete an exchange we will not be able to recover it. The only way to protect your Dinar records is to unlock them today',
};
