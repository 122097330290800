import { FC } from 'react';

import SerialNumberInput from 'components/SerialNumberInput';
import SerialNumberStatusByCurrency, {
  SNStatusType,
} from 'components/SerialNumberStatusByCurrency';
import { SERIAL_NUMBER_LENGTH } from 'constants/validations';
import { SIGN_UP_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import {
  FormWrapper,
  FormFieldWrapper,
  StyledButton,
  StyledLearnMoreButton,
} from 'pages/SignUp/styled';

import { ISerialNumberFormProps, SerialNumberFormField } from './types';
import {
  SERIAL_NUMBER_BUTTON_TEXT,
  SERIAL_NUMBER_FORM_TEXT,
} from './constants';

const SerialNumberForm: FC<ISerialNumberFormProps> = ({
  formik: { handleSubmit, values, errors, submitCount, getFieldProps },
  isNotVerified,
  isVerifyLoading,
  handleChangeSerialNumber,
  handleLearnMoreModalOpen,
}) => {
  const serialNumberStatusType = isNotVerified
    ? SNStatusType.error
    : SNStatusType.default;

  const isSubmitButtonDisabled =
    values[SerialNumberFormField.SERIAL_NUMBER].length < SERIAL_NUMBER_LENGTH;

  const notVerifiedErrorMessage = submitCount
    ? errors[SerialNumberFormField.SERIAL_NUMBER]
    : undefined;
  const errorMessage = isNotVerified
    ? notVerifiedErrorMessage
    : errors[SerialNumberFormField.SERIAL_NUMBER];

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <SerialNumberStatusByCurrency type={serialNumberStatusType} />
      <StyledLearnMoreButton type='link' onClick={handleLearnMoreModalOpen}>
        {SERIAL_NUMBER_FORM_TEXT.leanMoreLink}
      </StyledLearnMoreButton>
      <FormFieldWrapper>
        <SerialNumberInput
          id={SerialNumberFormField.SERIAL_NUMBER}
          maxLength={SERIAL_NUMBER_LENGTH}
          labelText={SERIAL_NUMBER_FORM_TEXT.serialNumberLabel}
          errorMessage={errorMessage}
          defaultValue={values[SerialNumberFormField.SERIAL_NUMBER]}
          handleChangeFormikState={handleChangeSerialNumber}
          {...getFieldProps(SerialNumberFormField.SERIAL_NUMBER)}
        />
      </FormFieldWrapper>
      <StyledButton
        htmlType='submit'
        id={SIGN_UP_ACTIONS_BUTTONS_ID}
        loading={isVerifyLoading}
        disabled={isSubmitButtonDisabled}
      >
        {isNotVerified
          ? SERIAL_NUMBER_BUTTON_TEXT.notVerifiedSubmit
          : SERIAL_NUMBER_BUTTON_TEXT.submit}
      </StyledButton>
    </FormWrapper>
  );
};

export default SerialNumberForm;
