export enum PaymentBannerType {
  default = 'default',
  large = 'large',
}

export interface IPaymentBannerProps {
  text: string;
  type?: PaymentBannerType;
  withIcon?: boolean;
}
