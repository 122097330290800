import { FC } from 'react';

import { LogoWhite } from 'assets/vectors';

import { FOOTER_CONTENT } from './constants';
import {
  FooterContent,
  FooterInfo,
  FooterTitle,
  FooterWrapper,
  StyledButton,
} from './styled';
import { IFooter } from './types';

const Footer: FC<IFooter> = ({ handleProceed }) => (
  <FooterWrapper>
    <FooterInfo>
      <LogoWhite />
      <FooterTitle>{FOOTER_CONTENT.TITLE}</FooterTitle>
      <FooterContent>{FOOTER_CONTENT.CONTENT}</FooterContent>
      <StyledButton onClick={handleProceed}>
        {FOOTER_CONTENT.BUTTON_TEXT}
      </StyledButton>
    </FooterInfo>
  </FooterWrapper>
);

export default Footer;
