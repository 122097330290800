export const SIGN_IN_FORM_TEXT = {
  EMAIL_LABEL: 'Email',
  PASSWORD_LABEL: 'Password',
  REMEMBER_ME: 'Remember me',
  FORGOT_PASSWORD_LINK: 'Forgot your password?',
  SIGN_UP_TEXT: 'New to DinarVerify?',
  SIGN_UP_BUTTON_TEXT: 'Create an account',
};

export const VALIDATION_ERROR_MESSAGES = {
  EMAIL_REQUIRED: 'Please enter your email',
  EMAIL_INVALID: 'Please enter a valid email',
  PASSWORD_REQUIRED: 'Please enter your password',
  PASSWORD_SHORT: 'At least 10 characters',
};
