import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const LearnMoreModalHeaderWrapper = styled('div')({
  marginBottom: '44px',
  textAlign: 'center',
});

export const LearnMoreModalHeaderTitle = styled('h2')({
  marginBottom: '16px',
  fontSize: '32px',
  fontWeight: 800,
  textAlign: 'center',
  color: COLORS.gray900,
});

export const LearnMoreModalHeaderSubtitle = styled('h3')({
  fontSize: '18px',
  fontWeight: 500,
  textAlign: 'center',
  color: COLORS.gray600,
});
