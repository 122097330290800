import styled from 'styled-components';

import Button from 'components/Button';
import { COLORS } from 'constants/colorPalette';

import { PageSubTitle, PageTitle } from '../styled';

export const FooterWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '120px',
  width: '100%',
  height: '407px',
  backgroundColor: COLORS.gray800,
});

export const FooterInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '74px auto 84px',
  maxWidth: '575px',
});

export const FooterTitle = styled(PageTitle)({
  margin: '22px 0 8px',
  color: COLORS.white,
});

export const FooterContent = styled(PageSubTitle)({
  color: COLORS.white,
});

export const StyledButton = styled(Button)({
  marginTop: '35px',
  width: '225px',
});
