import { useState } from 'react';
import { useQuery } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { transformRoute } from 'utils/routing';
import { QUERY_KEYS } from 'constants/queryKeys';
import { generateSnDetailsCanvas } from 'pages/SerialNumberDetails/utils/exportPdf';
import { NULL_VALUE } from 'constants/common';
import {
  DOWNLOAD_PROGRESS_TOTAL_PERCENTAGE,
  UPLOAD_PROGRESS_TOTAL_PERCENTAGE,
} from 'api/constants';

import { FORM_DATA_FILENAME, RESPONSE_BLOB_TYPE } from './constants';
import { TGenerateExportPdf } from './types';

const useGenerateExportPdf = (
  requestId: string,
  isEnabled: boolean,
): TGenerateExportPdf => {
  const [progress, setProgress] = useState<number>(NULL_VALUE);

  const queryResult = useQuery(
    [QUERY_KEYS.GENERATE_EXPORT_PDF, requestId],
    async () => {
      const snDetailsCanvas = await generateSnDetailsCanvas();

      const data = await new Promise<ArrayBuffer>((resolve, reject) =>
        snDetailsCanvas.toBlob((blob) => {
          const form = new FormData();
          form.append(FORM_DATA_FILENAME, blob as Blob);

          apiClient
            .post(
              transformRoute(ApiRoutes.GENERATE_EXPORT_PDF, requestId),
              form,
              {
                responseType: RESPONSE_BLOB_TYPE,
                onDownloadProgress: (progressEvent) =>
                  setProgress(
                    UPLOAD_PROGRESS_TOTAL_PERCENTAGE +
                      Math.floor(
                        (progressEvent.loaded / progressEvent.total) *
                          DOWNLOAD_PROGRESS_TOTAL_PERCENTAGE,
                      ),
                  ),
                onUploadProgress: (progressEvent) =>
                  setProgress(
                    Math.floor(
                      (progressEvent.loaded / progressEvent.total) *
                        UPLOAD_PROGRESS_TOTAL_PERCENTAGE,
                    ),
                  ),
              },
            )
            .then(({ data }) => resolve(data))
            .catch((err) => reject(err));
        }),
      );

      return data;
    },
    {
      enabled: isEnabled,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return { ...queryResult, progress };
};

export default useGenerateExportPdf;
