import { FC, createRef, useState } from 'react';
import { useNavigate } from 'react-router';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { getPaymentSourceToken } from 'api/customers';
import {
  TPaymentSourcePayload,
  useCreateSubscription,
} from 'api/subscriptions';
import {
  EMPTY_ARRAY,
  EMPTY_STRING,
  NOT_AVAILABLE,
  NULL_TYPE_VALUE,
} from 'constants/common';
import { APP_ROUTE } from 'constants/appRoutes';
import { BillingAddressModal } from 'components/BillingAddress';
import { TComponentsRef } from 'components/Payment/CardDetails/types';
import { IBillingAddressFormValues } from 'components/BillingAddress/types';
import { useCustomerBillingAddress } from 'components/BillingAddress/hooks';
import { useConfirmNavigate, useToggle } from 'hooks';

import {
  AS_HOME_CHECKBOX_FIELD,
  COMPANY_FIELD,
  BILLING_ADDRESS_MODAL_TEXT,
  BILLING_ADDRESS_FORM_BUTTON_TEXT,
} from './constants';
import { IPaymentFormContainerProps } from './types';
import { checkIsBillingAddressChanged } from './utils';
import PaymentForm from './PaymentForm';

const PaymentFormContainer: FC<IPaymentFormContainerProps> = ({
  serialNumber,
  subscription,
  paymentAndBillingAddress,
  isEarlySubscriptionSelected,
}) => {
  const customerBillingAddress = useCustomerBillingAddress();

  const billingAddress = paymentAndBillingAddress?.billingAddress;
  const currentBillingAddress = billingAddress || customerBillingAddress;
  const paymentCards = paymentAndBillingAddress?.cards || EMPTY_ARRAY;

  const isNoCardsCustomer = !paymentCards.length;
  const isNewCustomer = isNoCardsCustomer && !billingAddress;
  const isPaymentAndBillingAddressCustomer =
    !isNoCardsCustomer && Boolean(billingAddress);

  const navigateTo = useNavigate();

  const [isConfirmNavigationEnabled, setIsConfirmNavigationEnabled] =
    useState<boolean>(true);

  useConfirmNavigate(isConfirmNavigationEnabled);

  const [isAddressCheckboxChecked, toggleAddressCheckbox] = useToggle(true);
  const [isAgreementChecked, toggleAgreementCheckbox] = useToggle();

  const [paymentSourceId, setPaymentSourceId] = useState<string>(EMPTY_STRING);

  const [isBillingAddressModalOpened, setBillingAddressModalOpened] =
    useState(false);

  const [billingAddressFormValues, setBillingAddressFormValues] =
    useState<IBillingAddressFormValues>(currentBillingAddress);

  const cardRef = createRef<TComponentsRef>();
  const [isCardFormValid, setIsCardFormValid] = useState<boolean>(
    isPaymentAndBillingAddressCustomer,
  );

  const {
    mutate: createSubscriptionMutate,
    isLoading: isCreateSubscriptionLoading,
  } = useCreateSubscription();

  const handleBillingAddressModalOpen = () =>
    setBillingAddressModalOpened(true);

  const handleBillingAddressModalClose = () =>
    setBillingAddressModalOpened(false);

  const handleAddressCheckbox = ({ target }: CheckboxChangeEvent) => {
    toggleAddressCheckbox();

    if (!target.checked) {
      setBillingAddressFormValues((prevState) => ({
        ...prevState,
        [COMPANY_FIELD]: NULL_TYPE_VALUE,
        [AS_HOME_CHECKBOX_FIELD]: target.checked,
      }));
      handleBillingAddressModalOpen();
    } else {
      setBillingAddressFormValues(customerBillingAddress);
    }
  };

  const submitPayment = (paymentSource: TPaymentSourcePayload) => {
    setIsConfirmNavigationEnabled(false);

    createSubscriptionMutate(
      {
        serialNumber: serialNumber.serialNumber,
        itemPriceId: subscription.id,
        billingAddress: billingAddressFormValues,
        ...paymentSource,
      },
      {
        onSuccess: () => navigateTo(APP_ROUTE.thankYou),
        onError: () => setIsConfirmNavigationEnabled(true),
      },
    );
  };

  const submitPaymentWithNewCard = async () => {
    const token = await getPaymentSourceToken(cardRef.current);
    token && submitPayment({ paymentSourceToken: token });
  };

  const submitPaymentWithCustomerCard = () => {
    submitPayment({ paymentSourceId });
  };

  const handleSubmitPayment = () => {
    isNoCardsCustomer
      ? submitPaymentWithNewCard()
      : submitPaymentWithCustomerCard();
  };

  const handleAfterBillingAddressClose = () => {
    if (isNewCustomer) {
      const isBillingAddressFormChanged = checkIsBillingAddressChanged(
        customerBillingAddress,
        billingAddressFormValues,
      );

      if (!(isBillingAddressFormChanged || isAddressCheckboxChecked)) {
        toggleAddressCheckbox();

        setBillingAddressFormValues((prevState) => ({
          ...prevState,
          [COMPANY_FIELD]: NOT_AVAILABLE,
          [AS_HOME_CHECKBOX_FIELD]: true,
        }));
      }
    }
  };

  const addBillingAddressModalTitle = isAddressCheckboxChecked
    ? BILLING_ADDRESS_MODAL_TEXT.asHomeAddressTitle
    : BILLING_ADDRESS_MODAL_TEXT.addBillingAddressTitle;

  const billingAddressModalTitle = isNewCustomer
    ? addBillingAddressModalTitle
    : BILLING_ADDRESS_MODAL_TEXT.defaultTitleFromModal;

  const addBillingAddressFomButtonsText = isAddressCheckboxChecked
    ? BILLING_ADDRESS_FORM_BUTTON_TEXT.asHomeAddressFormButtonsText
    : BILLING_ADDRESS_FORM_BUTTON_TEXT.addBillingAddressFormButtonsText;

  const billingAddressFormButtonsText = isNewCustomer
    ? addBillingAddressFomButtonsText
    : BILLING_ADDRESS_FORM_BUTTON_TEXT.defaultFormButtonsText;

  return (
    <>
      <PaymentForm
        serialNumber={serialNumber}
        paymentCards={paymentCards}
        billingAddress={billingAddress}
        currentBillingAddress={billingAddressFormValues}
        currentPaymentSourceId={paymentSourceId}
        subscription={subscription}
        cardRef={cardRef}
        isAddressCheckboxChecked={isAddressCheckboxChecked}
        isAgreementChecked={isAgreementChecked}
        isEarlySubscriptionSelected={isEarlySubscriptionSelected}
        isSubmitLoading={isCreateSubscriptionLoading}
        isCardFormValid={isCardFormValid}
        handleAddressCheckbox={handleAddressCheckbox}
        handleAgreementCheckbox={toggleAgreementCheckbox}
        handleOpenBillingAddressModal={handleBillingAddressModalOpen}
        handleSubmitPayment={handleSubmitPayment}
        handleCheckIsCardFormValid={setIsCardFormValid}
        handleUpdatePaymentMethod={setPaymentSourceId}
      />
      <BillingAddressModal
        title={billingAddressModalTitle}
        billingAddress={billingAddressFormValues}
        formButtonsText={billingAddressFormButtonsText}
        isModalOpened={isBillingAddressModalOpened}
        isAsHomeCheckboxHidden={isNewCustomer}
        handleCloseModal={handleBillingAddressModalClose}
        handleSubmitBillingAddress={setBillingAddressFormValues}
        afterClose={handleAfterBillingAddressClose}
      />
    </>
  );
};

export default PaymentFormContainer;
