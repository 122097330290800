import { TFormik } from 'types/formik';
import { TPhoneNumberCountryCode } from 'types/phoneNumbers';

export enum BillingAddressFormField {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  COUNTRY_CODE = 'countryCode',
  PHONE_NUMBER = 'phoneNumber',
  COMPANY = 'company',
  ADDRESS = 'address',
  SAME_WITH_PROFILE = 'isSameAsHome',
}

export interface IBillingAddressFormValues {
  [BillingAddressFormField.FIRST_NAME]: string;
  [BillingAddressFormField.LAST_NAME]: string;
  [BillingAddressFormField.EMAIL]: string;
  [BillingAddressFormField.COUNTRY_CODE]: TPhoneNumberCountryCode;
  [BillingAddressFormField.PHONE_NUMBER]: string;
  [BillingAddressFormField.ADDRESS]: string;
  [BillingAddressFormField.COMPANY]?: string | null;
  [BillingAddressFormField.SAME_WITH_PROFILE]?: boolean;
}

export interface IBillingAddressFieldsContainerProps {
  formik: TFormik<IBillingAddressFormValues>;
  isFormDisabled: boolean;
}

export interface IBillingAddressFieldsProps
  extends IBillingAddressFieldsContainerProps {
  handleChangePhoneNumberValue: (value: string) => void;
  handleChangeCountryCodeValue: (value: unknown) => void;
}
