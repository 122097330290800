import styled from 'styled-components';
import { Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colorPalette';

export const RadioButtonWrapper = styled('span')<{ isPrimary: boolean }>(
  ({ isPrimary }) => ({
    padding: isPrimary ? '20px 0 0' : '20px 0',
    backgroundColor: isPrimary ? COLORS.gray50 : COLORS.white,
    border: `1px solid ${COLORS.gray300}`,
    borderRadius: '8px',
  }),
);

export const StyledRadioButton = styled(Radio)({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  padding: '0 24px',
  '& span.ant-radio + *': {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '33px',
    textAlign: 'left',
  },
  '& .ant-radio-inner': {
    width: '24px',
    height: '24px',
    '&:after': {
      width: '24px',
      height: '24px',
      marginTop: '-12px',
      marginLeft: '-12px',
    },
  },
});

export const PaymentMethodBrandLogo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '54px',
  height: '54px',
  border: `1px solid ${COLORS.gray300}`,
  borderRadius: '12px',
  '> img': {
    width: '100%',
  },
  '> svg': {
    width: '100%',
    height: '100%',
  },
});

export const PaymentMethodBrandName = styled('p')({
  marginBottom: '4px',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  color: COLORS.gray900,
});

export const PaymentMethodBrandNamePrefix = styled('span')({
  display: 'block',
});

export const PaymentMethodCardNumbers = styled('p')({
  marginBottom: 0,
  fontSize: '12px',
  fontWeight: 400,
  color: COLORS.gray500,
});

export const PaymentMethodInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  padding: '6px 12px',
  margin: '15px 0 0',
  backgroundColor: COLORS.blue50,
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
});

export const PaymentMethodInfoText = styled('p')({
  width: '100%',
  margin: 0,
  fontSize: '12px',
  fontWeight: 500,
  color: COLORS.blue900,
  textAlign: 'left',
});

export const PaymentMethodInfoIcon = styled(InfoCircleOutlined)({
  fontSize: '16px',
  color: COLORS.blue900,
});
