import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import CustomAlert from 'components/CustomAlert';

export const StyledAlert = styled(CustomAlert)({
  borderRadius: '2px',
});

export const FormFieldWrapper = styled('div')({
  marginBottom: '32px',
});

export const EmailSentText = styled('p')({
  fontSize: '16px',
  fontWeight: 500,
  marginBottom: '32px',
  color: COLORS.gray700,
  textAlign: 'center',
  '& .highlighted': {
    fontWeight: 800,
    wordBreak: 'break-word',
  },
});
