import { FC } from 'react';
import { RadioChangeEvent } from 'antd';

import { SubscriptionRadioButton } from 'components/Payment';

import { checkIsFirstSubscription } from '../utils';
import { ISelectSubscriptionProps } from './types';
import {
  PAYMENT_SUBSCRIPTION_BANNER_TEXT,
  SWITCH_BUTTON_TEXT,
  YEAR_DISPLAYED_SUBSCRIPTIONS_LENGTH,
} from './constants';
import { StyledRadioGroup, StyledLinkButton } from './styled';
import PaymentBanner from '../PaymentBanner';

const SelectSubscription: FC<ISelectSubscriptionProps> = ({
  serialNumber,
  subscriptionPlans,
  displayedSubscriptions,
  selectedSubscription,
  handleChangeSubscription,
  handleSwitchSubscriptions,
}) => {
  const isSwitchButtonShown =
    displayedSubscriptions.length === YEAR_DISPLAYED_SUBSCRIPTIONS_LENGTH;
  const [, monthlyPlan] = subscriptionPlans;

  const withPaymentBanner = checkIsFirstSubscription(serialNumber);

  return (
    <>
      <StyledRadioGroup
        size='large'
        value={selectedSubscription}
        onChange={(e: RadioChangeEvent) =>
          handleChangeSubscription(e.target.value)
        }
      >
        {displayedSubscriptions.map((subscription) => (
          <SubscriptionRadioButton
            key={subscription.id}
            plan={subscription}
            monthlyPlanPrice={monthlyPlan?.pricePerYear as number}
            banner={
              withPaymentBanner ? (
                <PaymentBanner text={PAYMENT_SUBSCRIPTION_BANNER_TEXT} />
              ) : undefined
            }
          />
        ))}
      </StyledRadioGroup>
      {isSwitchButtonShown && (
        <StyledLinkButton type='link' onClick={handleSwitchSubscriptions}>
          {SWITCH_BUTTON_TEXT}
        </StyledLinkButton>
      )}
    </>
  );
};

export default SelectSubscription;
