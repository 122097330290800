import { FC } from 'react';

import { BillingAddressFields } from 'components/BillingAddress';
import { BillingAddressFormField } from 'components/BillingAddress/types';

import { SAME_AS_HOME_ADDRESS_LABEL_TEXT } from './constants';
import {
  FormWrapper,
  StyledCheckbox,
  ActionButtonsWrapper,
  StyledButton,
} from './styled';
import { IBillingAddressFormProps } from './types';

const BillingAddressForm: FC<IBillingAddressFormProps> = ({
  formik,
  isOnDirtySubmitDisable,
  isFormDisabled,
  isFormActionLoading,
  isAsHomeCheckboxHidden,
  buttonsText,
  handleCancelBillingAddressForm,
  handleAddressCheckboxCheck,
  ...props
}) => {
  const { values, isValid, dirty, getFieldProps, handleSubmit } = formik;
  const { cancelText, submitText } = buttonsText;

  const isSubmitButtonDisabled =
    !isValid || (isOnDirtySubmitDisable && !dirty) || isFormActionLoading;

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <BillingAddressFields
        formik={formik}
        isFormDisabled={isFormDisabled || isFormActionLoading}
        {...props}
      />
      {!isAsHomeCheckboxHidden && (
        <StyledCheckbox
          disabled={isFormActionLoading}
          checked={values[BillingAddressFormField.SAME_WITH_PROFILE]}
          {...getFieldProps(BillingAddressFormField.SAME_WITH_PROFILE)}
          onChange={handleAddressCheckboxCheck}
        >
          {SAME_AS_HOME_ADDRESS_LABEL_TEXT}
        </StyledCheckbox>
      )}
      <ActionButtonsWrapper>
        {cancelText && (
          <StyledButton
            type='default'
            disabled={isFormActionLoading}
            onClick={handleCancelBillingAddressForm}
          >
            {cancelText}
          </StyledButton>
        )}
        {submitText && (
          <StyledButton htmlType='submit' disabled={isSubmitButtonDisabled}>
            {submitText}
          </StyledButton>
        )}
      </ActionButtonsWrapper>
    </FormWrapper>
  );
};

export default BillingAddressForm;
