export const QUERY_KEYS = {
  USER_PROFILE: 'userProfile',
  GET_USER_FROM_LOCAL_STORAGE: 'loadUserEmailFromLocalStorage',
  CUSTOMER_DETAILS: 'customerDetails',
  CUSTOMER_PAYMENT_AND_BILLING_ADDRESS: 'customerPaymentAndBillingAddress',
  CHECK_RESET_IS_EXPIRED: 'checkResetIsExpired',
  DEALERS_LIST: 'dealersList',
  DEALERS_SELECT_LIST: 'dealersSelectList',
  CURRENCY_SERIES_SELECT_LIST: 'currencySeriesSelectList',
  DEALER_DETAILS: 'dealerDetails',
  CURRENCY_REQUESTS: 'currencyRequests',
  CURRENCIES_FOR_REQUEST: 'currenciesForRequest',
  ALL_CURRENCIES_FOR_REQUEST: 'allCurrenciesForRequest',
  GENERATE_EXPORT_PDF: 'generateExportPdf',
  REQUEST_DETAILS: 'requestDetails',
  REQUEST_INVOICE: 'requestInvoice',
  CERTIFICATES: 'certificates',
  RESOURCES: 'resources',
  DENOMINATIONS: 'denominations',
  SERIAL_NUMBERS: 'serialNumbers',
  SERIAL_NUMBER_DETAILS: 'serialNumberDetails',
  SERIAL_NUMBER_STATUS: 'serialNumberStatus',
  SUBSCRIPTION_PLANS: 'subscriptionsPlans',
  NOTIFICATIONS: 'notifications',
  SUBSCRIPTIONS_LIST: 'subscriptionsList',
  INVOICES_LIST: 'invoicesList',
  PAYMENT_INVOICE: 'paymentInvoice',
  PORTAL_SESSION: 'portalSession',
  DOWNLOADED_FILE: 'downloadedFile',
  UPLOAD_ENCRYPT_PDF: 'uploadEncryptPdf',
};
