import { FC } from 'react';

import BillingAddressFields from './BillingAddressFields';
import {
  BillingAddressFormField,
  IBillingAddressFieldsContainerProps,
} from './types';

const BillingAddressFieldsContainer: FC<
  IBillingAddressFieldsContainerProps
> = ({ formik, ...props }) => {
  const handleChangePhoneNumberValue = (value: string) =>
    formik.setFieldValue(BillingAddressFormField.PHONE_NUMBER, value);

  const handleChangeCountryCodeValue = (value: unknown) =>
    formik.setFieldValue(BillingAddressFormField.COUNTRY_CODE, value);

  return (
    <BillingAddressFields
      formik={formik}
      handleChangePhoneNumberValue={handleChangePhoneNumberValue}
      handleChangeCountryCodeValue={handleChangeCountryCodeValue}
      {...props}
    />
  );
};

export default BillingAddressFieldsContainer;
