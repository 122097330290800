import styled from 'styled-components';
import { Radio } from 'antd';

import Button from 'components/Button';
import NoDataMessage from 'components/NoDataMessage';

export const EditPaymentMethodWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
});

export const EditPaymentMethodTitle = styled('h3')({
  marginBottom: '24px',
  fontSize: '32px',
  fontWeight: 600,
});

export const StyledRadioGroup = styled(Radio.Group)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  marginBottom: '20px',
  maxHeight: '320px',
  overflowY: 'auto',
  padding: '0 12px 3px 0',
});

export const EditPaymentMethodButton = styled(Button)({
  width: '100%',
  marginBottom: '20px',
});

export const StyledNoDataMessage = styled(NoDataMessage)({
  height: 'auto',
  marginBottom: '24px',
});
