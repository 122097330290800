import { FC, useState } from 'react';
import { useNavigate } from 'react-router';

import { APP_ROUTE } from 'constants/appRoutes';

import { EntryStepModal } from '../LearnMoreModal';
import { IEntryStepContainerProps } from './types';
import EntryStep from './EntryStep';

const EntryStepContainer: FC<IEntryStepContainerProps> = ({
  handleNextStep,
}) => {
  const navigateTo = useNavigate();
  const navigateToSignIn = () => navigateTo(APP_ROUTE.signIn);

  const [isLearnMoreModalOpened, setLearnMoreModalOpened] = useState(false);

  const handleLearnMoreModalOpen = () => setLearnMoreModalOpened(true);

  const handleLearnMoreModalClose = () => setLearnMoreModalOpened(false);

  return (
    <>
      <EntryStep
        navigateToSignIn={navigateToSignIn}
        handleNextStep={handleNextStep}
        handleEntryStepModalOpen={handleLearnMoreModalOpen}
      />
      <EntryStepModal
        isModalOpened={isLearnMoreModalOpened}
        handleCloseModal={handleLearnMoreModalClose}
      />
    </>
  );
};

export default EntryStepContainer;
