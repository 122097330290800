import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';

import { APP_ROUTE } from 'constants/appRoutes';
import { SearchParams } from 'types/urlSearchParams';
import { unformatSerialNumber } from 'utils/format/serialNumbers';

export const useNavigateToPayment = () => {
  const navigateTo = useNavigate();

  return (serialNumber: string) =>
    navigateTo({
      pathname: APP_ROUTE.payment,
      search: createSearchParams({
        [SearchParams.serialNumber]: unformatSerialNumber(serialNumber),
      }).toString(),
    });
};
