export const triggerDownloadFile = (
  fileUrl: string,
  preferedFileName?: string,
): void => {
  const link = document.createElement('a');

  link.setAttribute('href', fileUrl);
  preferedFileName && link.setAttribute('download', preferedFileName);

  link.click();

  link.remove();
};
