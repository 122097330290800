import { FC } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { APP_ROUTE } from 'constants/appRoutes';
import { SearchParams } from 'types/urlSearchParams';
import { EMPTY_OBJECT } from 'constants/common';

import Welcome from './Welcome';

const WelcomeContainer: FC = () => {
  const navigateTo = useNavigate();
  const [searchParams] = useSearchParams(EMPTY_OBJECT);

  const handleNavigateToSignUp = () => {
    const serialNumber = searchParams.get(SearchParams.serialNumber);

    navigateTo({
      pathname: APP_ROUTE.signUp,
      search: createSearchParams({
        ...(serialNumber && { [SearchParams.serialNumber]: serialNumber }),
      }).toString(),
    });
  };

  const handleNavigateToSignIn = () => navigateTo(APP_ROUTE.signIn);

  return (
    <Welcome
      handleNavigateToSignUp={handleNavigateToSignUp}
      handleNavigateToSignIn={handleNavigateToSignIn}
    />
  );
};

export default WelcomeContainer;
