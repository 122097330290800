import { FC } from 'react';

import HelmetPageTitle from 'components/HelmetPageTitle';
import { PageTitle, PageSubtitle } from 'components/UnauthorizedPages';
import CustomAlert from 'components/CustomAlert';
import { Loader } from 'components/Loader';
import UnauthorizedPageWrapper from 'components/Layouts/UnauthorizedLayout/UnauthorizedPageWrapper';

import { MENU_TITLE } from 'constants/menu';

import ResetPasswordForm from './ResetPasswordForm';
import { StyledLogo } from './styled';
import { RESET_PASSWORD_TEXT } from './constants';
import { IResetPasswordProps } from './types';

const ResetPassword: FC<IResetPasswordProps> = ({
  checkResetExpiredErrorMessage,
  isCheckResetExpiredLoading,
  isCheckResetExpiredError,
}) => (
  <HelmetPageTitle title={MENU_TITLE.resetPassword}>
    <UnauthorizedPageWrapper>
      {isCheckResetExpiredLoading ? (
        <Loader />
      ) : (
        <>
          <StyledLogo />
          <PageTitle>{RESET_PASSWORD_TEXT.pageTitle}</PageTitle>
          <PageSubtitle>{RESET_PASSWORD_TEXT.pageSubTitle}</PageSubtitle>
          {isCheckResetExpiredError ? (
            <CustomAlert type='error' message={checkResetExpiredErrorMessage} />
          ) : (
            <ResetPasswordForm
              checkResetExpiredErrorMessage={checkResetExpiredErrorMessage}
              isCheckResetExpiredError={isCheckResetExpiredError}
            />
          )}
        </>
      )}
    </UnauthorizedPageWrapper>
  </HelmetPageTitle>
);

export default ResetPassword;
