import { FC } from 'react';

import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { formatPhoneNumber } from 'components/Inputs/PhoneNumberInput/utils';
import { useFormik } from 'hooks';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';

import ShareSmsForm from './ShareSmsForm';
import { INITIAL_SHARE_SMS_FORM_VALUES } from './constants';
import { shareSmsValidationSchema } from './validation';
import {
  IShareSmsFormContainerProps,
  IShareSmsFormValues,
  ShareSmsFormField,
} from './types';

const ShareSmsFormContainer: FC<IShareSmsFormContainerProps> = ({
  serialNumber,
  handleCloseModal,
}) => {
  const handleOnSubmit = (profileFormValues: IShareSmsFormValues) => {
    const { countryCode, phoneNumber } = profileFormValues;
    // TODO: update later, when Klaviyo SMS will be integrated
    console.log(
      {
        countryCode,
        phoneNumber: formatPhoneNumber({ value: phoneNumber, countryCode }),
      },
      serialNumber,
    );

    openNotificationWithIcon(
      NotificationType.success,
      NOTIFICATION_SUCCESS_MESSAGE.shareSerialNumberBySms,
    );
    handleCloseModal();
  };

  const formik = useFormik({
    initialValues: INITIAL_SHARE_SMS_FORM_VALUES,
    onSubmit: handleOnSubmit,
    validationSchema: shareSmsValidationSchema,
    enableReinitialize: true,
  });

  const handleChangePhoneNumberValue = (value: string) =>
    formik.setFieldValue(ShareSmsFormField.PHONE_NUMBER, value);

  const handleChangeCountryCodeValue = (value: unknown) =>
    formik.setFieldValue(ShareSmsFormField.COUNTRY_CODE, value);

  return (
    <ShareSmsForm
      formik={formik}
      handleCloseModal={handleCloseModal}
      handleChangePhoneNumberValue={handleChangePhoneNumberValue}
      handleChangeCountryCodeValue={handleChangeCountryCodeValue}
    />
  );
};

export default ShareSmsFormContainer;
