import { EMPTY_STRING } from 'constants/common';

import { ANTD_STYLE_CLASSNAMES } from './constants';
import { IChangeEvent } from './types';

export const getCardFieldClassName = (field: IChangeEvent | null): string =>
  `${ANTD_STYLE_CLASSNAMES.default} ${
    field?.error ? ANTD_STYLE_CLASSNAMES.error : EMPTY_STRING
  }`;

export const checkIsCardFieldValid = (field: IChangeEvent | null): boolean =>
  Boolean(field) && !(field?.empty || field?.error);
