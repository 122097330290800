import { FC } from 'react';

import LearnMoreModal, {
  LearnMoreModalFooter,
  LearnMoreModalHeader,
  LearnMoreModalSteps,
} from 'components/LearnMoreModal';
import { IModalDialogProps } from 'components/ModalDialog/types';

import {
  SERIAL_NUMBER_MODAL_CONTENT,
  SERIAL_NUMBER_MODAL_WIDTH,
} from './constants';

const SerialNumberModal: FC<IModalDialogProps> = ({
  isModalOpened,
  handleCloseModal,
}) => (
  <LearnMoreModal
    width={SERIAL_NUMBER_MODAL_WIDTH}
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
  >
    <>
      <LearnMoreModalHeader
        title={SERIAL_NUMBER_MODAL_CONTENT.title}
        subtitle={SERIAL_NUMBER_MODAL_CONTENT.subtitle}
      />
      <LearnMoreModalSteps stepsList={SERIAL_NUMBER_MODAL_CONTENT.steps} />
      <LearnMoreModalFooter />
    </>
  </LearnMoreModal>
);

export default SerialNumberModal;
