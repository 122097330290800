import styled from 'styled-components';
import { Radio } from 'antd';

import Button from 'components/Button';

export const StyledRadioGroup = styled(Radio.Group)({
  display: 'flex',
  flexDirection: 'column',
  gap: '60px',
  marginBottom: '32px',
});

export const StyledLinkButton = styled(Button)({
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 500,
  '> span': {
    textDecoration: 'underline',
  },
});
