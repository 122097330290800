import { FC, useEffect, useState } from 'react';

import { env } from 'config/env';

import { ICardDetailsContainerProps, IChangeEvent } from './types';
import CardDetails from './CardDetails';
import { checkIsCardFieldValid } from './utils';

const CardDetailsContainer: FC<ICardDetailsContainerProps> = ({
  cardRef,
  handleCheckIsCardFormValid,
}) => {
  const [cardNumberField, setCardNumberField] = useState<IChangeEvent | null>(
    null,
  );
  const [cardExpiryField, setCardExpiryField] = useState<IChangeEvent | null>(
    null,
  );
  const [cardCVVField, setCardCVVField] = useState<IChangeEvent | null>(null);

  useEffect(() => {
    if (!window.Chargebee.inited) {
      window.Chargebee.init({
        site: env.REACT_APP_CB_SITE,
        publishableKey: env.REACT_APP_CB_TOKEN,
      });
    }
  }, []);

  const checkIsCardFormValid = () => {
    const isCardNumberFieldValid = checkIsCardFieldValid(cardNumberField);
    const isCardExpiryFieldValid = checkIsCardFieldValid(cardExpiryField);
    const isCardCVVFieldValid = checkIsCardFieldValid(cardCVVField);

    handleCheckIsCardFormValid(
      isCardNumberFieldValid && isCardExpiryFieldValid && isCardCVVFieldValid,
    );
  };

  useEffect(() => {
    checkIsCardFormValid();
  }, [cardNumberField, cardExpiryField, cardCVVField]);

  return (
    <CardDetails
      cardRef={cardRef}
      cardNumberField={cardNumberField}
      cardExpiryField={cardExpiryField}
      cardCVVField={cardCVVField}
      handleCardNumberFieldChange={setCardNumberField}
      handleCardExpiryFieldChange={setCardExpiryField}
      handleCardCVVFieldChange={setCardCVVField}
    />
  );
};

export default CardDetailsContainer;
