import { FC, BaseSyntheticEvent, useState, useRef } from 'react';

import { getPaymentSourceToken, useAddCustomerPayment } from 'api/customers';
import { NULL_TYPE_VALUE } from 'constants/common';
import { TComponentsRef } from 'components/Payment/CardDetails/types';

import { IAddPaymentMethodContainerProps } from './types';
import AddPaymentMethod from './AddPaymentMethod';

const AddPaymentMethodContainer: FC<IAddPaymentMethodContainerProps> = ({
  goToEditPaymentMethodStep,
}) => {
  const cardRef = useRef<TComponentsRef>(NULL_TYPE_VALUE);

  const [isCardFormValid, setIsCardFormValid] = useState<boolean>(false);

  const {
    mutate: addCustomerPaymentMutate,
    isLoading: isAddCustomerPaymentLoading,
  } = useAddCustomerPayment();

  const handleCheckIsCardFormValid = (isValid: boolean) =>
    setIsCardFormValid(isValid);

  const handleSubmitPayment = async (event: BaseSyntheticEvent) => {
    event.preventDefault();

    const token = await getPaymentSourceToken(cardRef.current);

    if (isCardFormValid && token) {
      addCustomerPaymentMutate(
        { paymentSourceToken: token },
        { onSuccess: goToEditPaymentMethodStep },
      );
    }
  };

  return (
    <AddPaymentMethod
      cardRef={cardRef}
      isCardFormValid={isCardFormValid}
      isSubmitLoading={isAddCustomerPaymentLoading}
      handleSubmitPayment={handleSubmitPayment}
      handleCheckIsCardFormValid={handleCheckIsCardFormValid}
      goToEditPaymentMethodStep={goToEditPaymentMethodStep}
    />
  );
};

export default AddPaymentMethodContainer;
