import { EMPTY_STRING } from 'constants/common';
import { STATUS_CODES } from 'constants/errorCodes';
import { TAxiosRequestError } from 'api/types';

export const mapUpdateErrorMessage = (
  error?: TAxiosRequestError | null,
): string => {
  const code = error?.response?.data?.statusCode;
  const message = error?.response?.data?.message || EMPTY_STRING;

  if (code === STATUS_CODES.INTERNAL_SERVER_ERROR) {
    return EMPTY_STRING;
  }

  return message;
};
