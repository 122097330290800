import { FC, memo } from 'react';

import { SHARE_EMAIL_MODAL_CONTENT } from './constants';
import { IShareEmailModalProps } from './types';
import ShareEmailForm from '../ShareEmailForm';
import { SharePdfModal } from '../styled';

const ShareEmailModal: FC<IShareEmailModalProps> = ({
  serialNumber,
  isModalOpened,
  handleCloseModal,
}) => (
  <SharePdfModal
    destroyOnClose
    title={SHARE_EMAIL_MODAL_CONTENT.TITLE}
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
  >
    <ShareEmailForm
      serialNumber={serialNumber}
      handleCloseModal={handleCloseModal}
    />
  </SharePdfModal>
);

export default memo(ShareEmailModal);
