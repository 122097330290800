import { useMutation, UseMutationResult } from 'react-query';

import { apiClient, queryClient } from 'api/base';
import { NOTIFICATIONS_TEXT } from 'api/constants';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { QUERY_KEYS } from 'constants/queryKeys';
import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { NotificationType } from 'types/notifications';
import { openNotificationWithIcon } from 'utils/showNotification';
import { transformRoute } from 'utils/routing';

const useChangeSubscriptionPayment = (
  subscriptionId: string,
  onSuccess?: () => void,
): UseMutationResult<void, TAxiosRequestError, { paymentSourceId: string }> => {
  const handleChangePaymentSuccess = () => {
    openNotificationWithIcon(
      NotificationType.success,
      NOTIFICATION_SUCCESS_MESSAGE.subscriptionChanged,
    );

    onSuccess && onSuccess();
    queryClient.invalidateQueries([QUERY_KEYS.SUBSCRIPTIONS_LIST]);
  };

  const handleChangePaymentError = (error: TAxiosRequestError) =>
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data?.message || NOTIFICATIONS_TEXT.WRONG,
    );

  return useMutation(
    async (payload) => {
      await apiClient.put(
        transformRoute(ApiRoutes.CHANGE_SUBSCRIPTION_PAYMENT, subscriptionId),
        payload,
      );
    },
    {
      onSuccess: handleChangePaymentSuccess,
      onError: handleChangePaymentError,
    },
  );
};

export default useChangeSubscriptionPayment;
