import { FC } from 'react';

import {
  LearnMoreModalStepsWrapper,
  LearnMoreModalStepsTitle,
  LearnMoreModalStepWrapper,
  LearnMoreModalStepTextWrapper,
  LearnMoreModalStepTitle,
  LearnMoreModalStepSubtitle,
  LearnMoreModalStepImg,
} from './styled';
import { ILearnMoreModalStepsProps } from './types';

const LearnMoreModalSteps: FC<ILearnMoreModalStepsProps> = ({
  stepsList,
  stepsListTitle,
  withImages = true,
}) => (
  <LearnMoreModalStepsWrapper>
    {stepsListTitle && (
      <LearnMoreModalStepsTitle>{stepsListTitle}</LearnMoreModalStepsTitle>
    )}
    {stepsList.map(({ title, subtitle }, index) => (
      <LearnMoreModalStepWrapper key={index}>
        <LearnMoreModalStepTextWrapper>
          <LearnMoreModalStepTitle>{title}</LearnMoreModalStepTitle>
          <LearnMoreModalStepSubtitle>{subtitle}</LearnMoreModalStepSubtitle>
        </LearnMoreModalStepTextWrapper>
        {withImages && <LearnMoreModalStepImg />}
      </LearnMoreModalStepWrapper>
    ))}
  </LearnMoreModalStepsWrapper>
);

export default LearnMoreModalSteps;
