import { FC } from 'react';

import { LockIcon } from 'assets/vectors';
import { CardDetails } from 'components/Payment';

import { checkIsFirstSubscription } from '../utils';
import { PAYMENT_FORM_TEXT } from './constants';
import {
  PaymentFormWrapper,
  StyledPaymentForm,
  StyledPaymentIcons,
  PaymentFormTitle,
  PaymentFormSubtitle,
  PaymentFormCheckbox,
  PaymentCheckboxLink,
  PaymentSubmitButton,
  PaymentInfoWrapper,
  PaymentSecureText,
} from './styled';
import { IPaymentFormProps } from './types';
import { getSubmitPaymentButtonText } from './utils';
import Agreement from './Agreement';
import PaymentInfo from './PaymentInfo';
import PaymentAndBillingAddress from './PaymentAndBillingAddress';

const PaymentForm: FC<IPaymentFormProps> = ({
  serialNumber,
  paymentCards,
  billingAddress,
  currentBillingAddress,
  currentPaymentSourceId,
  subscription,
  cardRef,
  isAddressCheckboxChecked,
  isAgreementChecked,
  isEarlySubscriptionSelected,
  isSubmitLoading,
  isCardFormValid,
  handleAddressCheckbox,
  handleAgreementCheckbox,
  handleOpenBillingAddressModal,
  handleSubmitPayment,
  handleCheckIsCardFormValid,
  handleUpdatePaymentMethod,
}) => {
  const isNoCardsCustomer = !paymentCards.length;
  const isNewCustomer = isNoCardsCustomer && !billingAddress;

  const isSubmitButtonDisabled = !(
    isAgreementChecked &&
    subscription &&
    isCardFormValid
  );

  const isTrial = checkIsFirstSubscription(serialNumber);
  const submitButtonText = getSubmitPaymentButtonText(subscription.price);

  return (
    <PaymentFormWrapper>
      {billingAddress && (
        <PaymentAndBillingAddress
          paymentCards={paymentCards}
          billingAddress={currentBillingAddress}
          currentPaymentSourceId={currentPaymentSourceId}
          handleUpdatePaymentMethod={handleUpdatePaymentMethod}
          handleOpenBillingAddressModal={handleOpenBillingAddressModal}
        />
      )}
      {isNoCardsCustomer && (
        <>
          <StyledPaymentIcons />
          <StyledPaymentForm>
            <PaymentFormTitle>{PAYMENT_FORM_TEXT.title}</PaymentFormTitle>
            <PaymentFormSubtitle>
              {PAYMENT_FORM_TEXT.subtitle}
            </PaymentFormSubtitle>
            <CardDetails
              cardRef={cardRef}
              handleCheckIsCardFormValid={handleCheckIsCardFormValid}
            />
            {isNewCustomer && (
              <PaymentFormCheckbox
                checked={isAddressCheckboxChecked}
                onChange={handleAddressCheckbox}
              >
                {PAYMENT_FORM_TEXT.checkboxText}
                <PaymentCheckboxLink
                  type='link'
                  onClick={handleOpenBillingAddressModal}
                >
                  {PAYMENT_FORM_TEXT.addressButtonText}
                </PaymentCheckboxLink>
              </PaymentFormCheckbox>
            )}
          </StyledPaymentForm>
        </>
      )}
      <PaymentInfoWrapper>
        {subscription && (
          <PaymentInfo
            subscription={subscription}
            isTrial={isTrial}
            withBanner={isTrial && isEarlySubscriptionSelected}
          />
        )}
        <Agreement
          isChecked={isAgreementChecked}
          handleChange={handleAgreementCheckbox}
        />
        <PaymentSubmitButton
          disabled={isSubmitButtonDisabled}
          onClick={handleSubmitPayment}
          loading={isSubmitLoading}
        >
          {isTrial ? PAYMENT_FORM_TEXT.submitTrialButtonText : submitButtonText}
        </PaymentSubmitButton>
        <PaymentSecureText>
          <LockIcon />
          {PAYMENT_FORM_TEXT.paymentSecureText}
        </PaymentSecureText>
      </PaymentInfoWrapper>
    </PaymentFormWrapper>
  );
};

export default PaymentForm;
