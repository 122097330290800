import { useQuery, UseQueryResult } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { QUERY_KEYS } from 'constants/queryKeys';
import { ISubscriptionPlan } from 'types/subscriptions';

import { IGetPlansPayload } from './types';

const useGetSubscriptionPlansList = ({
  serialNumber,
}: IGetPlansPayload = {}): UseQueryResult<ISubscriptionPlan[]> =>
  useQuery(
    [QUERY_KEYS.SUBSCRIPTION_PLANS],
    async () => {
      const { data: listResponse } = await apiClient.get(
        ApiRoutes.GET_SUBSCRIPTION_PLANS,
        { params: { serialNumber } },
      );

      return listResponse;
    },
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

export default useGetSubscriptionPlansList;
