import { object, string } from 'yup';

import {
  EMAIL_PATTERN,
  VALIDATION_TEST_NAME,
  MIN_PHONE_NUMBER_LENGTH,
  USER_VALIDATION_ERROR_MESSAGES,
  MAX_EMAIL_LENGTH,
} from 'constants/validations';
import { testRegexNegative } from 'utils/validation/testRegexNegative';
import { formatToNumbersOnly } from 'utils/format/numbers';

export const getEmailValidationSchema = (formField: string) =>
  object({
    [formField]: string()
      .required(USER_VALIDATION_ERROR_MESSAGES.emailRequired)
      .matches(
        EMAIL_PATTERN.GENERAL_STRUCTURE,
        USER_VALIDATION_ERROR_MESSAGES.emailInvalid,
      )
      .matches(
        EMAIL_PATTERN.LOCAL_PART_GENERAL,
        USER_VALIDATION_ERROR_MESSAGES.emailInvalid,
      )
      .matches(
        EMAIL_PATTERN.LOCAL_PART_BORDERS,
        USER_VALIDATION_ERROR_MESSAGES.emailInvalid,
      )
      .test(
        VALIDATION_TEST_NAME.GENERAL_TRAILINGS,
        USER_VALIDATION_ERROR_MESSAGES.emailInvalid,
        testRegexNegative(EMAIL_PATTERN.GENERAL_TRAILINGS),
      )
      .matches(
        EMAIL_PATTERN.DOMAIN_PART_GENERAL,
        USER_VALIDATION_ERROR_MESSAGES.emailInvalid,
      )
      .matches(
        EMAIL_PATTERN.DOMAIN_PART_BORDERS,
        USER_VALIDATION_ERROR_MESSAGES.emailInvalid,
      )
      .matches(
        EMAIL_PATTERN.DOMAIN_PART_TOTAL_LENGTH,
        USER_VALIDATION_ERROR_MESSAGES.emailInvalid,
      )
      .max(MAX_EMAIL_LENGTH, USER_VALIDATION_ERROR_MESSAGES.emailInvalid),
  });

export const getPasswordValidationSchema = (formField: string) =>
  object({
    [formField]: string()
      .required(USER_VALIDATION_ERROR_MESSAGES.createPassword)
      .matches(
        /^[a-zA-Z0-9]*$/,
        USER_VALIDATION_ERROR_MESSAGES.numbersAndLettersAllowedError,
      )
      .min(10, USER_VALIDATION_ERROR_MESSAGES.passwordLengthError)
      .matches(
        /(?=.*?[a-zA-Z])/,
        USER_VALIDATION_ERROR_MESSAGES.oneLatinLetterContainError,
      )
      .matches(
        /(?=.*?[0-9])/,
        USER_VALIDATION_ERROR_MESSAGES.oneNumberContainError,
      )
      .matches(
        /(?=.*?[A-Z])/,
        USER_VALIDATION_ERROR_MESSAGES.oneUppercaseLetterContainError,
      )
      .matches(
        /(?=.*?[a-z])/,
        USER_VALIDATION_ERROR_MESSAGES.oneLowerCaseLetterContainError,
      ),
  });

export const getFullNameValidationSchema = (
  firstNameField: string,
  lastNameField: string,
) =>
  object({
    [firstNameField]: string()
      .trim()
      .required(USER_VALIDATION_ERROR_MESSAGES.firstNameRequired),
    [lastNameField]: string()
      .trim()
      .required(USER_VALIDATION_ERROR_MESSAGES.lastNameRequired),
  });

export const getPhoneNumberValidationSchema = (
  countryCodeField: string,
  phoneNumberField: string,
) =>
  object({
    [countryCodeField]: string()
      .nullable()
      .required(USER_VALIDATION_ERROR_MESSAGES.countryCodeRequired),
    [phoneNumberField]: string()
      .required(USER_VALIDATION_ERROR_MESSAGES.phoneNumberRequired)
      .transform(formatToNumbersOnly)
      .min(
        MIN_PHONE_NUMBER_LENGTH,
        USER_VALIDATION_ERROR_MESSAGES.phoneNumberInvalid,
      ),
  });

export const getAddressValidationSchema = (formField: string) =>
  object({
    [formField]: string()
      .trim()
      .required(USER_VALIDATION_ERROR_MESSAGES.addressRequired),
  });
