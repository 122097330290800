import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFormik } from 'hooks';
import { EMPTY_OBJECT } from 'constants/common';
import { SearchParams } from 'types/urlSearchParams';
import { formatSerialNumber } from 'components/SerialNumberInput/utils';

import { SerialNumberModal, NotVerifiedModal } from '../LearnMoreModal';
import { INITIAL_SERIAL_NUMBER_FORM_VALUES } from './constants';
import { SERIAL_NUMBER_VALIDATION_SCHEME } from './validation';
import {
  ISerialNumberFormContainerProps,
  ISerialNumberFormValues,
  SerialNumberFormField,
} from './types';
import SerialNumberForm from './SerialNumberForm';

const SerialNumberFormContainer: FC<ISerialNumberFormContainerProps> = ({
  serialNumber,
  handleSubmit,
  isNotVerified = false,
  isVerifyLoading = false,
}) => {
  const [isLearnMoreModalOpened, setLearnMoreModalOpened] = useState(false);

  const handleEntryStepModalOpen = () => setLearnMoreModalOpened(true);

  const handleEntryStepModalClose = () => setLearnMoreModalOpened(false);

  const [searchParams] = useSearchParams(EMPTY_OBJECT);

  useEffect(() => {
    const serialNumberFromLink = searchParams.get(
      SearchParams.serialNumber,
    ) as string;

    if (serialNumberFromLink) {
      handleChangeSerialNumber(formatSerialNumber(serialNumberFromLink));
    }
  }, []);

  const handleSerialNumberSubmit = ({
    serialNumber,
  }: ISerialNumberFormValues) => handleSubmit(serialNumber);

  const formik = useFormik({
    initialValues: { serialNumber } || INITIAL_SERIAL_NUMBER_FORM_VALUES,
    validationSchema: SERIAL_NUMBER_VALIDATION_SCHEME,
    enableReinitialize: true,
    onSubmit: handleSerialNumberSubmit,
  });

  const handleChangeSerialNumber = (value: string) => {
    if (isNotVerified) {
      formik.validateForm();
    }
    formik.setFieldValue(SerialNumberFormField.SERIAL_NUMBER, value);
  };

  return (
    <>
      <SerialNumberForm
        formik={formik}
        isNotVerified={isNotVerified}
        isVerifyLoading={isVerifyLoading}
        handleChangeSerialNumber={handleChangeSerialNumber}
        handleLearnMoreModalOpen={handleEntryStepModalOpen}
      />
      {isNotVerified ? (
        <NotVerifiedModal
          isModalOpened={isLearnMoreModalOpened}
          handleCloseModal={handleEntryStepModalClose}
        />
      ) : (
        <SerialNumberModal
          isModalOpened={isLearnMoreModalOpened}
          handleCloseModal={handleEntryStepModalClose}
        />
      )}
    </>
  );
};

export default SerialNumberFormContainer;
