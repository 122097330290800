import { ChangeEvent, FC } from 'react';

import { useVerifyCode } from 'api/auth';
import { TAxiosRequestError } from 'api/types';
import { EMPTY_STRING, NUMBERS_REG_EXP } from 'constants/common';
import { SERVER_ERROR_STRING } from 'constants/messages';
import { mapUpdateErrorMessage } from 'utils/errors/mapUpdateErrorMessage';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';

import { IUserFormValues, UserFormField } from '../types';
import VerificationCodeFields from './VerificationCodeFields';
import { IVerificationCodeFieldsContainerProps } from './types';

const VerificationCodeFieldsContainer: FC<
  IVerificationCodeFieldsContainerProps
> = ({ formik, responseMessage, handleChangeResponseMessage }) => {
  const {
    mutate: verifyCodeMutate,
    isLoading: isVerifyCodeLoading,
    error: verifyCodeError,
  } = useVerifyCode();

  const handleVerifyCodeSuccess = () => {
    handleChangeResponseMessage(null);
    formik.submitForm();
  };

  const handleVerifyCodeError = (error: TAxiosRequestError) => {
    const errorMessage = error.response?.data.message;

    if (errorMessage === SERVER_ERROR_STRING) {
      openNotificationWithIcon(
        NotificationType.error,
        mapUpdateErrorMessage(error),
      );
    } else {
      formik.setErrors({
        [UserFormField.VERIFICATION_CODE]: errorMessage,
      });
    }
  };

  const handleSubmit = ({ email, verificationCode }: IUserFormValues) =>
    verifyCodeMutate(
      { email, verificationCode },
      {
        onSuccess: handleVerifyCodeSuccess,
        onError: handleVerifyCodeError,
      },
    );

  const handleChangeNumericInputValue = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    const verificationCode = target.value.replace(
      NUMBERS_REG_EXP,
      EMPTY_STRING,
    );

    formik.setFieldValue(
      UserFormField.VERIFICATION_CODE,
      verificationCode || EMPTY_STRING,
    );
  };

  return (
    <VerificationCodeFields
      formik={formik}
      isVerifyCodeLoading={isVerifyCodeLoading}
      responseMessage={responseMessage}
      errorMessage={
        verifyCodeError?.response?.data?.message || verifyCodeError?.message
      }
      handleChangeNumericInputValue={handleChangeNumericInputValue}
      handleChangeResponseMessage={handleChangeResponseMessage}
      handleSubmit={handleSubmit}
    />
  );
};

export default VerificationCodeFieldsContainer;
