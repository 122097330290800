import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { NOTIFICATIONS_TEXT } from 'api/constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { NotificationType } from 'types/notifications';
import { openNotificationWithIcon } from 'utils/showNotification';

import { IRequestErrorPayload, ICreateSubscriptionPayload } from './types';

const useCreateSubscription = (): UseMutationResult<
  void,
  AxiosError<IRequestErrorPayload>,
  ICreateSubscriptionPayload
> => {
  const handleCreateSubscriptionSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEYS.USER_PROFILE]);
  };

  const handleCreateSubscriptionError = (
    error: AxiosError<IRequestErrorPayload>,
  ) => {
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data.message || NOTIFICATIONS_TEXT.WRONG,
    );
  };

  return useMutation(
    async (payload) => {
      await apiClient.post(ApiRoutes.SUBSCRIPTIONS, payload);
    },
    {
      onSuccess: handleCreateSubscriptionSuccess,
      onError: handleCreateSubscriptionError,
    },
  );
};

export default useCreateSubscription;
