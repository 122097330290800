import { useMutation, UseMutationResult } from 'react-query';

import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { QUERY_KEYS } from 'constants/queryKeys';
import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { NotificationType } from 'types/notifications';
import { openNotificationWithIcon } from 'utils/showNotification';
import { transformRoute } from 'utils/routing';

const useChangeSubscriptionPlan = (
  subscriptionId: string,
  onSuccess?: () => void,
): UseMutationResult<void, TAxiosRequestError, { planId?: string }> => {
  const handleChangeSuccess = () => {
    openNotificationWithIcon(
      NotificationType.success,
      NOTIFICATION_SUCCESS_MESSAGE.subscriptionChanged,
    );

    queryClient.refetchQueries([QUERY_KEYS.SUBSCRIPTIONS_LIST]);
    queryClient.refetchQueries([QUERY_KEYS.SUBSCRIPTION_PLANS]);

    onSuccess && onSuccess();
  };

  const handleChangeError = (error: TAxiosRequestError) =>
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data?.message,
    );

  return useMutation(
    async (payload) => {
      await apiClient.put(
        transformRoute(ApiRoutes.CHANGE_SUBSCRIPTION_PLAN, subscriptionId),
        payload,
      );
    },
    {
      onSuccess: handleChangeSuccess,
      onError: handleChangeError,
    },
  );
};

export default useChangeSubscriptionPlan;
