import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';

import {
  useChangeSubscriptionPlan,
  useGetSubscriptionPlansList,
} from 'api/subscriptions';
import { ISubscriptionPlan } from 'types/subscriptions';

import {
  ChangePlanField,
  ISelectSubscriptionPlanModalContainerProps,
} from './types';
import SelectSubscriptionPlanModal from './SelectSubscriptionPlanModal';

const SelectSubscriptionPlanModalContainer: FC<
  ISelectSubscriptionPlanModalContainerProps
> = ({
  subscription,
  handleChangeSubscriptionPlanSuccess,
  handleCloseModal,
  ...props
}) => {
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] =
    useState<ISubscriptionPlan | null>(null);

  const onChangePlanSuccess = () => {
    handleCloseModal();

    if (handleChangeSubscriptionPlanSuccess) {
      handleChangeSubscriptionPlanSuccess();
    }
  };

  const { data: subscriptionPlans } = useGetSubscriptionPlansList({
    serialNumber: subscription?.serialNumber,
  });

  const {
    mutate: changeSubscriptionPlanMutate,
    isLoading: isChangeSubscriptionPlanLoading,
  } = useChangeSubscriptionPlan(subscription._id, onChangePlanSuccess);

  const INITIAL_CHANGE_SUBS_PLAN_VALUES = {
    [ChangePlanField.planId]: subscription?.planId,
  };

  const handleChangeSubscriptionPlan = (plan?: ISubscriptionPlan) => {
    if (plan) {
      setSelectedSubscriptionPlan(plan);
      formik.setFieldValue(ChangePlanField.planId, plan.id);
    }
  };

  const handleSubmit = () => {
    const payload = {
      planId: formik.values.planId,
    };
    changeSubscriptionPlanMutate(payload);
  };

  const formik = useFormik({
    initialValues: INITIAL_CHANGE_SUBS_PLAN_VALUES,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (subscriptionPlans && subscriptionPlans?.length) {
      const currentPlan = subscriptionPlans?.find(
        (plan: ISubscriptionPlan) => plan.id === subscription.planId,
      );
      handleChangeSubscriptionPlan(currentPlan);
    }
  }, [subscriptionPlans]);

  return (
    <SelectSubscriptionPlanModal
      subscription={subscription}
      subscriptionPlans={subscriptionPlans || []}
      selectedSubscriptionPlan={selectedSubscriptionPlan}
      handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
      formik={formik}
      isChangeSubscriptionPlanLoading={isChangeSubscriptionPlanLoading}
      handleCloseModal={handleCloseModal}
      {...props}
    />
  );
};
export default SelectSubscriptionPlanModalContainer;
