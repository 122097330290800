import { FC } from 'react';

import { WelcomeWarningIcon, WelcomeCheckIcon } from 'assets/vectors';
import { MENU_TITLE } from 'constants/menu';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { UNAUTHORIZED_LAYOUT_TEXT } from 'components/Layouts/UnauthorizedLayout/constants';

import { WELCOME_PAGE_TEXT } from './constants';
import { IWelcomeProps } from './types';
import {
  WelcomePageWrapper,
  WelcomePageSection,
  WelcomePageTitle,
  StyledButtonsWrapper,
  StyledButton,
  WelcomePageText,
  WelcomePageBenefits,
  WelcomePageBenefit,
  WelcomePageWarningTitle,
} from './styled';

const Welcome: FC<IWelcomeProps> = ({
  handleNavigateToSignUp,
  handleNavigateToSignIn,
}) => (
  <HelmetPageTitle title={MENU_TITLE.welcome}>
    <WelcomePageWrapper>
      <WelcomePageSection>
        <WelcomePageTitle>{WELCOME_PAGE_TEXT.title}</WelcomePageTitle>
        <StyledButtonsWrapper>
          <StyledButton type='primary' onClick={handleNavigateToSignUp}>
            {UNAUTHORIZED_LAYOUT_TEXT.SIGN_UP}
          </StyledButton>
          <StyledButton type='default' onClick={handleNavigateToSignIn}>
            {UNAUTHORIZED_LAYOUT_TEXT.SIGN_IN}
          </StyledButton>
        </StyledButtonsWrapper>
        <WelcomePageText>{WELCOME_PAGE_TEXT.text}</WelcomePageText>
      </WelcomePageSection>
      <WelcomePageBenefits>
        {WELCOME_PAGE_TEXT.benefits.map((benefit, index) => (
          <WelcomePageBenefit key={index}>
            <WelcomeCheckIcon />
            <WelcomePageText>{benefit}</WelcomePageText>
          </WelcomePageBenefit>
        ))}
      </WelcomePageBenefits>
      <WelcomePageSection>
        <WelcomePageWarningTitle>
          <WelcomeWarningIcon />
          {WELCOME_PAGE_TEXT.warningTitle}
        </WelcomePageWarningTitle>
        <WelcomePageText>{WELCOME_PAGE_TEXT.warningText}</WelcomePageText>
      </WelcomePageSection>
    </WelcomePageWrapper>
  </HelmetPageTitle>
);

export default Welcome;
