import { FC } from 'react';
import { StarFilled } from '@ant-design/icons';

import {
  PaymentWidget,
  PaymentWidgetLogo,
  PaymentWidgetText,
  PaymentWidgetTitle,
  PaymentWidgetsWrapper,
  StarsComponentWrapper,
} from './styled';
import { WIDGETS_TEXT } from './constants';

const StarsComponent = () => (
  <StarsComponentWrapper>
    {Array.from({ length: 5 }, (_, i) => i).map((index) => (
      <StarFilled key={index} />
    ))}
  </StarsComponentWrapper>
);

const PaymentWidgets: FC = () => (
  <PaymentWidgetsWrapper>
    <PaymentWidget>
      <PaymentWidgetLogo>{WIDGETS_TEXT.logo}</PaymentWidgetLogo>
      <div>
        <PaymentWidgetTitle>{WIDGETS_TEXT.business}</PaymentWidgetTitle>
        <PaymentWidgetText>{WIDGETS_TEXT.rating}</PaymentWidgetText>
      </div>
    </PaymentWidget>
    <PaymentWidget>
      <PaymentWidgetLogo>{WIDGETS_TEXT.logo}</PaymentWidgetLogo>
      <div>
        <PaymentWidgetTitle>
          <StarsComponent />
          {WIDGETS_TEXT.stars}
        </PaymentWidgetTitle>
        <PaymentWidgetText>{WIDGETS_TEXT.reviews}</PaymentWidgetText>
      </div>
    </PaymentWidget>
  </PaymentWidgetsWrapper>
);

export default PaymentWidgets;
