import { lazy } from 'react';

export const Subscriptions = lazy(() => import('./Subscriptions'));
export const Profile = lazy(() => import('./Profile'));
export const SerialNumbers = lazy(() => import('./SerialNumbers'));
export const Help = lazy(() => import('./Help'));

export { default as NotFound } from './NotFound';
export { default as Dashboard } from './Dashboard';
export { default as SignUp } from './SignUp';
export { default as Payment } from './Payment';
export { default as SignIn } from './SignIn';
export { default as ForgotPassword } from './ForgotPassword';
export { default as ResetPassword } from './ResetPassword';
export { default as SerialNumbersDetails } from './SerialNumberDetails';
export { default as CurrencyDetails } from './CurrencyDetails';
export { default as ThankYou } from './ThankYou';
export { default as Welcome } from './Welcome';
