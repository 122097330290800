import styled from 'styled-components';
import { Modal as AntdModal } from 'antd';

import { COLORS } from 'constants/colorPalette';

export const Modal = styled(AntdModal)({
  '& button.ant-modal-close': {
    top: '18px',
    right: '9px',
    '& .anticon': {
      transform: 'rotate(45deg)',
      '& > svg': {
        fill: COLORS.gray300,
        fontSize: '26px',
      },
      '&:hover': {
        '& > svg': {
          fill: COLORS.gray500,
        },
      },
    },
  },
  '& .ant-modal-header': {
    padding: '32px 32px 0',
    borderBottom: 0,
    '& .ant-modal-title': {
      fontSize: '18px',
      fontWeight: '800',
      lineHeight: '25px',
      letterSpacing: '0.2px',
    },
  },
  '& .ant-modal-body': {
    padding: '20px 32px',
  },
  '@media (max-width: 490px)': {
    '& button.ant-modal-close': {
      top: '6px',
      right: 0,
      '& .anticon > svg': {
        fontSize: '24px',
      },
    },
    '& .ant-modal-header': {
      padding: '20px 20px 0',
      '& .ant-modal-title': {
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    '& .ant-modal-body': {
      padding: '20px',
    },
  },
});

export const ModalContentWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const ModalSubtitle = styled('div')({
  fontWeight: '500',
  fontSize: '18px',
  lineHeight: '25px',
  letterSpacing: '0.1px',
  marginBottom: '20px',
  color: COLORS.gray600,
  '@media (max-width: 490px)': {
    fontSize: '16px',
    lineHeight: '18px',
  },
});
