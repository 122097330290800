import { ALLOWED_CARD_BRANDS } from 'constants/payment';

import { ISerialNumber } from './serialNumber';
import { ISubscription } from './subscriptions';
import { TPhoneNumberCountryCode } from './phoneNumbers';

export interface ICustomer {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  phoneNumber: string;
  serialNumber: string;
  isArchived?: boolean;
}

export interface ICustomerInfo {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  phoneNumber: string;
  totalIqd: number;
  totalVnd: number;
  totalIdr: number;
  totalPaidCents: number;
  serialNumbersTotal: number;
  subscriptionsTotal: number;
  isArchived: boolean;
  isDeleted: boolean;
}

export interface ICustomerInfoWithSerialNumbers
  extends Omit<ICustomerInfo, 'serialNumbersTotal' | 'subscriptionsTotal'> {
  serialNumbers: ISerialNumber[];
  subscriptions: ISubscription[];
}

export interface IBillingAddress {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: TPhoneNumberCountryCode;
  address: string;
  isSameAsHome?: boolean;
  company?: string | null;
}

export type TPaymentSource = {
  paymentSourceToken: string;
};

export type TPaymentCardBrand = typeof ALLOWED_CARD_BRANDS[number];

export enum PaymentCardRole {
  primary = 'primary',
  backup = 'backup',
  none = 'none',
}

export type TPaymentCardBrandLookups<T = string> = {
  [key in TPaymentCardBrand]: T;
};

export interface IPaymentCard {
  sourceId: string;
  expiryMonth: number;
  expiryYear: number;
  maskedNumber: string;
  brand: TPaymentCardBrand;
  role: PaymentCardRole;
}
