import { useQuery, UseQueryResult } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { QUERY_KEYS } from 'constants/queryKeys';
import { STATUS_CODES } from 'constants/errorCodes';
import { NOTIFICATION_ERROR_MESSAGE } from 'constants/messages';
import { useTriggerNotFound } from 'hooks';
import { transformRoute } from 'utils/routing';
import { openNotificationWithIcon } from 'utils/showNotification';
import { TAxiosRequestError } from 'api/types';
import { NotificationType } from 'types/notifications';

import { IGetCustomerPaymentAndBillingAddressResponse } from './types';

const useGetCustomerPaymentAndBillingAddress = (
  customerId: string,
): UseQueryResult<
  IGetCustomerPaymentAndBillingAddressResponse,
  TAxiosRequestError
> => {
  const triggerNotFound = useTriggerNotFound();

  const handleGetCustomerPaymentAndBillingAddressError = (
    error: TAxiosRequestError,
  ) => {
    openNotificationWithIcon(
      NotificationType.error,
      NOTIFICATION_ERROR_MESSAGE.somethingWentWrong,
    );

    if (error?.code !== STATUS_CODES.ERR_NETWORK_CODE) {
      triggerNotFound();
    }
  };

  return useQuery(
    [QUERY_KEYS.CUSTOMER_PAYMENT_AND_BILLING_ADDRESS, { customerId }],
    async () => {
      const { data: userResponse } = await apiClient.get(
        transformRoute(
          ApiRoutes.GET_CUSTOMER_PAYMENT_AND_BILLING_ADDRESS,
          customerId,
        ),
      );

      return userResponse;
    },
    {
      keepPreviousData: false,
      retry: false,
      refetchOnWindowFocus: false,
      onError: handleGetCustomerPaymentAndBillingAddressError,
    },
  );
};

export default useGetCustomerPaymentAndBillingAddress;
