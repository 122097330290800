import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';

export const WelcomePageWrapper = styled('div')({
  width: '100%',
  maxWidth: '1170px',
  padding: '36px 0 47px',
  margin: 'auto',
  textAlign: 'center',
});

export const WelcomePageSection = styled('section')({
  width: '100%',
  maxWidth: '900px',
  margin: '0 auto 59px',
  textAlign: 'center',
  padding: '0 18px',
});

export const WelcomePageTitle = styled('h1')({
  fontSize: '32px',
  lineHeight: '40px',
  fontWeight: 600,
  color: COLORS.gray800,
  marginBottom: '32px',
});

export const WelcomePageText = styled('p')({
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 500,
  color: COLORS.gray600,
  marginBottom: '47px',
});

export const WelcomePageBenefits = styled('div')({
  width: '100%',
  margin: '0 auto 90px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px 32px',
  textAlign: 'left',
  '@media (max-width: 1400px)': {
    maxWidth: '640px',
  },
  '@media (max-width: 768px)': {
    padding: '0 18px',
  },
});

export const WelcomePageBenefit = styled('div')({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  width: 'calc(25% - 24px)',
  '> p': {
    color: COLORS.gray900,
    marginBottom: 0,
  },
  '@media (max-width: 1400px)': {
    width: 'calc(50% - 16px)',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
});

export const WelcomePageWarningTitle = styled(WelcomePageTitle)({
  color: COLORS.red600,
  marginBottom: '28px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
});

export const StyledButtonsWrapper = styled('div')({
  marginBottom: '44px',
});

export const StyledButton = styled(Button)({
  display: 'block',
  margin: '0 auto 18px',
  width: '100%',
  maxWidth: '416px',
});
