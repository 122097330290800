export enum SearchParams {
  tab = 'tab',
  serialNumber = 'serial-number',
  email = 'email',
  page = 'page',
  search = 'search',
  sortField = 'sortField',
  sortOrder = 'sortOrder',
  subpage = 'subpage',
  customerId = 'customerId',
}

export type TURLSearchParams = Partial<Record<SearchParams, string>>;

export type TSearchParamsPairs = [string, string?][];
