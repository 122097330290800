import {
  DEFAULT_PHONE_COUNTRY_CODE,
  EMPTY_STRING,
  NOT_AVAILABLE,
} from 'constants/common';

import { BillingAddressFormField } from './BillingAddressFields/types';

export const INITIAL_BILLING_ADDRESS_FORM_VALUES = {
  [BillingAddressFormField.FIRST_NAME]: EMPTY_STRING,
  [BillingAddressFormField.LAST_NAME]: EMPTY_STRING,
  [BillingAddressFormField.EMAIL]: EMPTY_STRING,
  [BillingAddressFormField.COUNTRY_CODE]: DEFAULT_PHONE_COUNTRY_CODE,
  [BillingAddressFormField.PHONE_NUMBER]: EMPTY_STRING,
  [BillingAddressFormField.COMPANY]: NOT_AVAILABLE,
  [BillingAddressFormField.ADDRESS]: EMPTY_STRING,
  [BillingAddressFormField.SAME_WITH_PROFILE]: true,
};
