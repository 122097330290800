export const APP_ROUTE = {
  unRegistered: '*',
  browser: '/',
  browserBase: '/',
  appBase: '/*',
  notFound: '/not-found',
  signIn: '/sign-in',
  signInHelp: '/sign-in/help',
  signUp: '/sign-up',
  payment: '/payment',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:resetJWT',
  dashboard: '/dashboard',
  requests: '/requests',
  newRequestDetails: '/requests/:requestId/new',
  completeRequestDetails: '/requests/:requestId/complete',
  serialNumbers: '/serial-numbers',
  certificates: '/certificates',
  customers: '/customers',
  employees: '/employees',
  dealers: '/dealers',
  createDealer: '/dealers/create-dealer',
  viewDealerDetails: '/dealers/:dealerId',
  analytics: '/analytics',
  subscriptions: '/subscriptions',
  profile: '/profile',
  serialNumberDetails: '/serial-numbers/:serialNumberId',
  serialNumberCurrencyDetails:
    '/serial-numbers/:serialNumberId/currency-details',
  help: '/help',
  thankYou: '/thank-you',
  welcome: '/welcome',
};
