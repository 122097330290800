import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

import { BADGE_BG_COLOR_LOOKUP, BADGE_FG_COLOR_LOOKUP } from './constants';
import { TBadgeLabelProps } from './types';

export const BadgeLabel = styled((props: TBadgeLabelProps) => (
  <div {...props} />
))(({ type, isSmallSize }) => ({
  width: 'max-content',
  padding: isSmallSize ? '4px 10px' : '4px 20px',
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 500,
  backgroundColor: BADGE_BG_COLOR_LOOKUP[type],
  color: BADGE_FG_COLOR_LOOKUP[type],
  borderRadius: '12px',
  '& ::first-letter': {
    textTransform: 'capitalize',
  },
}));

export const ContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const StyledCloseCircleFilledIcon = styled(CloseCircleFilled)({
  fontSize: '14px',
  color: COLORS.red600,
});

export const StyledGreenCheckIcon = styled(CheckCircleFilled)({
  fontSize: '14px',
  color: COLORS.green700,
});
