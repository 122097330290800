import { TComponentsRef } from 'components/Payment/CardDetails/types';
import { PAYMENT_ERROR_TEXT } from 'components/Payment/constants';
import { NotificationType } from 'types/notifications';
import { openNotificationWithIcon } from 'utils/showNotification';

export const getPaymentSourceToken = async (
  chargebeeCard: TComponentsRef | null,
): Promise<string | null> => {
  try {
    const { token } = (await chargebeeCard?.tokenize()) || {};

    return token || null;
  } catch (error) {
    openNotificationWithIcon(NotificationType.error, PAYMENT_ERROR_TEXT);
    return null;
  }
};
