import { FC, useEffect } from 'react';

import { useSendVerificationCode } from 'api/auth';
import { TAxiosRequestError } from 'api/types';
import {
  ResponseType,
  SEND_VERIFICATION_CODE_ERROR_STRING,
} from 'constants/messages';
import { mapUpdateErrorMessage } from 'utils/errors/mapUpdateErrorMessage';
import { getSendVerificationCodeSuccessMessage } from 'pages/Profile/utils';
import { useCountdown } from 'hooks';

import ResendCode from './ResendCode';
import { IResendCodeContainerProps } from './types';

const ResendCodeContainer: FC<IResendCodeContainerProps> = ({
  email,
  handleChangeResponseMessage,
}) => {
  const { timeLeft, reset: resetCountdown } = useCountdown();

  useEffect(() => {
    resetCountdown();
  }, []);

  const {
    mutate: sendVerificationCodeMutate,
    isLoading: isSendVerificationCodeLoading,
  } = useSendVerificationCode();

  const handleResendCodeSuccess = (email: string) => {
    handleChangeResponseMessage({
      type: ResponseType.SUCCESS,
      message: getSendVerificationCodeSuccessMessage(email),
    });

    resetCountdown();
  };

  const handleResendCodeError = (error: TAxiosRequestError) => {
    const errorMessage = error.response?.data.message;

    if (errorMessage === SEND_VERIFICATION_CODE_ERROR_STRING) {
      handleChangeResponseMessage({
        type: ResponseType.ERROR,
        message: mapUpdateErrorMessage(error),
      });
    }
  };

  const handleResendCode = () =>
    sendVerificationCodeMutate(email, {
      onSuccess: () => handleResendCodeSuccess(email),
      onError: handleResendCodeError,
    });

  return (
    <ResendCode
      resendButtonTimer={timeLeft}
      isSendVerificationCodeLoading={isSendVerificationCodeLoading}
      handleResendCode={handleResendCode}
    />
  );
};

export default ResendCodeContainer;
