import { FC } from 'react';

import ShareSmsForm from '../ShareSmsForm';
import { IShareSmsModalProps } from './types';
import { SHARE_EMAIL_MODAL_CONTENT } from './constants';
import { SharePdfModal } from '../styled';

const ShareSmsModal: FC<IShareSmsModalProps> = ({
  isModalOpened,
  serialNumber,
  handleCloseModal,
}) => (
  <SharePdfModal
    destroyOnClose
    title={SHARE_EMAIL_MODAL_CONTENT.TITLE}
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
  >
    <ShareSmsForm
      serialNumber={serialNumber}
      handleCloseModal={handleCloseModal}
    />
  </SharePdfModal>
);

export default ShareSmsModal;
