import { Checkbox } from 'antd';
import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';

export const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledCheckbox = styled(Checkbox)({
  width: 'fit-content',
  paddingTop: '24px',
  '& .ant-checkbox': {
    '& + span': {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: 1,
      color: COLORS.gray700,
      padding: '0 0 0 16px',
    },
  },
});

export const ActionButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '24px',
  marginTop: '32px',
});

export const StyledButton = styled(Button)({
  width: '100%',
});
