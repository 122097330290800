import { useQuery, UseQueryResult } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { QUERY_KEYS } from 'constants/queryKeys';
import { ISubscriptionPayments } from 'types/subscriptions';

import { IGetInvoicesListParams } from './types';

const useGetInvoicesList = (
  params: IGetInvoicesListParams,
  isEnabled: boolean,
): UseQueryResult<ISubscriptionPayments[], TAxiosRequestError> =>
  useQuery(
    [QUERY_KEYS.INVOICES_LIST, params],
    async () => {
      const { data: invoiceslistResponse } = await apiClient.get(
        ApiRoutes.GET_INVOICES_LIST,
        { params },
      );

      return invoiceslistResponse;
    },
    {
      keepPreviousData: true,
      enabled: isEnabled,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

export default useGetInvoicesList;
