import { object, string } from 'yup';

import {
  getEmailValidationSchema,
  getPasswordValidationSchema,
  getFullNameValidationSchema,
  getPhoneNumberValidationSchema,
  getAddressValidationSchema,
} from 'utils/validation/userValidationScheme';

import { UserFormField, UserStepperKeys } from './types';

const emailValidationSchema = getEmailValidationSchema(UserFormField.EMAIL);

const passwordValidationSchema = getPasswordValidationSchema(
  UserFormField.PASSWORD,
);

const fullNameValidationSchema = getFullNameValidationSchema(
  UserFormField.FIRST_NAME,
  UserFormField.LAST_NAME,
);

const verificationCodeValidationSchema = object().shape({
  [UserFormField.VERIFICATION_CODE]: string().trim(),
});

const phoneNumberValidationSchema = getPhoneNumberValidationSchema(
  UserFormField.COUNTRY_CODE,
  UserFormField.PHONE_NUMBER,
);

const addressValidationSchema = getAddressValidationSchema(
  UserFormField.ADDRESS,
);

export const USER_VALIDATION_SCHEME = {
  [UserStepperKeys.email]: emailValidationSchema,
  [UserStepperKeys.verificationCode]: verificationCodeValidationSchema,
  [UserStepperKeys.password]: passwordValidationSchema,
  [UserStepperKeys.fullName]: fullNameValidationSchema,
  [UserStepperKeys.phoneNumber]: phoneNumberValidationSchema,
  [UserStepperKeys.address]: addressValidationSchema,
};
