import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router';

import { APP_ROUTE } from 'constants/appRoutes';
import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';

import { IResetPasswordParamsPayload, IRequestErrorPayload } from './types';

const useResetPassword = (
  params: IResetPasswordParamsPayload,
): UseMutationResult<void, AxiosError<IRequestErrorPayload>, string> => {
  const navigateTo = useNavigate();

  const { resetJWT } = params;

  const handleResetSuccess = () => {
    openNotificationWithIcon(
      NotificationType.success,
      NOTIFICATION_SUCCESS_MESSAGE.passwordUpdated,
    );
    navigateTo(APP_ROUTE.signIn, {
      replace: true,
    });
  };

  const handleResetError = (error: TAxiosRequestError) =>
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data?.message,
    );

  return useMutation(
    async (newPassword: string) => {
      const { data: dataResponse } = await apiClient.post(
        `${ApiRoutes.RESET_PASSWORD}/${resetJWT}`,
        { password: newPassword },
      );

      return dataResponse;
    },
    { onSuccess: handleResetSuccess, onError: handleResetError },
  );
};

export default useResetPassword;
