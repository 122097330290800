import { ReactNode } from 'react';

import { banknoteImage, cbiImage } from 'assets/images';
import {
  COMMA_STRING,
  DASH_STRING,
  EMPTY_STRING,
  EMPTY_STRING_WITH_WHITESPACE,
  NOT_AVAILABLE,
} from 'constants/common';
import { formatDateString } from 'utils/format';
import { formatNumberAsMoney } from 'utils/format/numbers';
import {
  ISubscription,
  SubscriptionStatus,
  TSubscriptionStatus,
} from 'types/subscriptions';
import {
  ActiveSubscriptionBadge,
  CanceledSubscriptionBadge,
  LockedSerialNumberBadge,
  UnlockedSerialNumberBadge,
  VerifiedCustomerBadge,
} from 'components/Badges';
import ImageWithZoom from 'components/ImageWithZoom';
import {
  CurrencyType,
  ICurrenciesForRequest,
  ICurrenciesTable,
} from 'types/currencies';
import { ISerialNumberWithActions } from 'types/serialNumber';
import Tooltip from 'components/Tooltip';
import { ClockIcon } from 'assets/vectors';
import { checkIfSubscriptionActive } from 'utils/subscriptions';

import {
  ArabicNumbersWrapper,
  BanknotesWrapper,
  SubscriptionStatusWrapper,
} from './styled';
import { formatNumberWithSeparator } from '../format/numbers';
import { capitalizeFirstLetter } from '../strings';

export const renderDate = (value: string, notDefinedValue?: string) =>
  value ? formatDateString(value) : notDefinedValue || DASH_STRING;

export const renderSortPlug = () => 0;

export const renderNumbers = (number: number): React.ReactNode => {
  const formattedAmount = number
    .toLocaleString()
    .replace(COMMA_STRING, EMPTY_STRING_WITH_WHITESPACE);
  return formattedAmount;
};

export const renderNumberWithThousandsCommas = (value: number): ReactNode =>
  formatNumberWithSeparator(value, COMMA_STRING);

export const renderSerialNumberStatus = (
  subscriptionStatus: TSubscriptionStatus,
): React.ReactNode =>
  checkIfSubscriptionActive(subscriptionStatus) ? (
    <UnlockedSerialNumberBadge />
  ) : (
    <LockedSerialNumberBadge />
  );

export const renderSubscriptionStatus = (
  status: TSubscriptionStatus,
  { nextBillingAt }: ISubscription,
): React.ReactNode => (
  <SubscriptionStatusWrapper>
    {checkIfSubscriptionActive(status) ? (
      <ActiveSubscriptionBadge isSmallSize />
    ) : (
      <CanceledSubscriptionBadge isSmallSize />
    )}
    {status === SubscriptionStatus.trial && renderTrialTooltip(nextBillingAt)}
  </SubscriptionStatusWrapper>
);

export const renderMoneyDollars = (value: number): ReactNode =>
  formatNumberAsMoney(value);

export const renderMockBanknotes = (): ReactNode => (
  <BanknotesWrapper>
    <ImageWithZoom src={banknoteImage} />
    <ImageWithZoom src={banknoteImage} />
  </BanknotesWrapper>
);

export const renderCBI = (): ReactNode => <img src={cbiImage} />;

export const renderPossibleUndefined = (value: unknown) => value ?? DASH_STRING;

export const renderPossibleArabicSN = (
  value: string,
  currencies: ICurrenciesTable,
) =>
  currencies?.currency === CurrencyType.DINAR ? (
    <ArabicNumbersWrapper>{value}</ArabicNumbersWrapper>
  ) : (
    value
  );

export const renderCurrencyStatus = (): ReactNode => <VerifiedCustomerBadge />;

export const renderCurrencyCondition = (condition: string) =>
  capitalizeFirstLetter(String(renderPossibleUndefined(condition)));

export const renderCurrencySeries = (
  series: string,
  { currency }: ICurrenciesForRequest | ISerialNumberWithActions,
): ReactNode =>
  currency !== CurrencyType.DINAR
    ? NOT_AVAILABLE
    : (renderPossibleUndefined(series) as ReactNode);

export const renderTrialTooltip = (date?: Date): ReactNode => {
  const tillDateText = date ? ` till ${formatDateString(date)}` : EMPTY_STRING;

  return (
    <Tooltip title={`Trial${tillDateText}`} placement='right'>
      <ClockIcon />
    </Tooltip>
  );
};
