import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const PaymentWidgetsWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '32px',
});

export const PaymentWidget = styled('div')({
  width: 'calc(50% - 16px)',
  padding: '20px 18px',
  border: `2px solid ${COLORS.gray300}`,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 600,
  textAlign: 'left',
  '@media (max-width: 768px)': {
    width: '100%',
  },
});

export const PaymentWidgetLogo = styled('div')({
  width: '60px',
  height: '60px',
  border: `1px solid ${COLORS.gray300}`,
  borderRadius: '12px',
  padding: '16px 8px',
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 500,
  color: COLORS.gray400,
  marginBottom: 0,
});

export const PaymentWidgetTitle = styled('p')({
  color: COLORS.gray900,
  marginBottom: '2px',
});

export const PaymentWidgetText = styled('span')({
  color: COLORS.gray400,
  marginBottom: 0,
});

export const StarsComponentWrapper = styled('span')({
  marginRight: '16px',
  '& .anticon': {
    color: COLORS.primary500,
  },
});
