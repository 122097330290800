import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { ApiRoutes } from 'api/routes';
import { apiClient } from 'api/base';
import { QUERY_KEYS } from 'constants/queryKeys';
import { MS_IN_ONE_MINUTE } from 'constants/common';
import { useUserId } from 'hooks';

import {
  IErrorPayload,
  IGetNotificationsParams,
  INotificationsResponse,
} from './types';

const useGetNotifications = (
  isRefetchIntervalEnabled: boolean,
  params: IGetNotificationsParams,
): UseQueryResult<INotificationsResponse, AxiosError<IErrorPayload>> => {
  const customerId = useUserId();

  const { page, filters, limit, currency } = params;
  const { isRead, endDate } = filters;

  return useQuery(
    [QUERY_KEYS.NOTIFICATIONS, { isRead, page, customerId }],
    async () => {
      const { data: notificationsResponse } = await apiClient.get(
        ApiRoutes.GET_NOTIFICATIONS,
        {
          params: {
            page,
            isRead,
            endDate,
            limit,
            currency,
          },
        },
      );

      return notificationsResponse;
    },
    {
      keepPreviousData: false,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: isRefetchIntervalEnabled && MS_IN_ONE_MINUTE,
    },
  );
};

export default useGetNotifications;
