import { useMutation, UseMutationResult } from 'react-query';

import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { NOTIFICATIONS_TEXT } from 'api/constants';
import { TAxiosRequestError } from 'api/types';
import { QUERY_KEYS } from 'constants/queryKeys';
import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { useUserId } from 'hooks';
import { NotificationType } from 'types/notifications';
import { IPaymentCard, PaymentCardRole, TPaymentSource } from 'types/customer';
import { transformRoute } from 'utils/routing';
import { openNotificationWithIcon } from 'utils/showNotification';

import { IGetCustomerPaymentAndBillingAddressResponse } from './types';

const useAddCustomerPayment = (
  onSuccess?: (card: IPaymentCard) => void,
): UseMutationResult<IPaymentCard, TAxiosRequestError, TPaymentSource> => {
  const customerId = useUserId();

  const handleAddCustomerPaymentSuccess = (card: IPaymentCard) => {
    openNotificationWithIcon(
      NotificationType.success,
      NOTIFICATION_SUCCESS_MESSAGE.updatePayment,
    );

    queryClient.setQueryData<
      IGetCustomerPaymentAndBillingAddressResponse | undefined
    >(
      [QUERY_KEYS.CUSTOMER_PAYMENT_AND_BILLING_ADDRESS, { customerId }],
      (data) =>
        data && data.cards
          ? {
              ...data,
              cards: [...data.cards, card],
            }
          : data,
    );

    if (onSuccess && card.role === PaymentCardRole.none) {
      onSuccess(card);
    }
  };

  const handleAddCustomerPaymentError = (error: TAxiosRequestError) => {
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data?.message || NOTIFICATIONS_TEXT.WRONG,
    );
  };

  return useMutation(
    async (payload) => {
      const { data: addCustomerPaymentResponse } = await apiClient.post(
        transformRoute(ApiRoutes.ADD_CUSTOMER_PAYMENT, customerId),
        payload,
      );

      return addCustomerPaymentResponse;
    },
    {
      retry: false,
      onSuccess: handleAddCustomerPaymentSuccess,
      onError: handleAddCustomerPaymentError,
    },
  );
};

export default useAddCustomerPayment;
