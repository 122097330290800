export const CURRENCY_ABBREVIATION_LOOKUP = {
  IDR: 'IDR',
  IQD: 'IQD',
  VND: 'VND',
};

export const CURRENCY_NAME_LOOKUP = {
  IDR: 'Indonesian Rupiah',
  IQD: 'Iraqi Dinar',
  VND: 'Vietnam Dong',
};

export const CURRENCY_SHORT_NAME_LOOKUP = {
  IQD: 'Dinar',
  IDR: 'Rupiah',
  VND: 'Dong',
};
