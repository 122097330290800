import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';
import ModalDialog from 'components/ModalDialog';

export const InnerWrapper = styled('div')({
  margin: '12px auto 32px',
  padding: '40px 0 24px',
  maxWidth: '1430px',
  width: '100%',
  background: COLORS.white,
  border: `1px solid ${COLORS.gray300}`,
  boxShadow:
    '0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05)',
  borderRadius: '4px',
  '@media (max-width: 1024px)': {
    paddingTop: '16px',
    maxWidth: '770px',
  },
  '@media (max-width: 480px)': {
    maxWidth: '100%',
  },
});

export const ArrowRight = styled('div')({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  marginBottom: '1px',
  borderTop: '2px solid',
  borderRight: '2px solid',
  borderColor: COLORS.gray700,
  transform: 'rotate(45deg)',
  transition: 'border-color .3s',
});

export const HeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '24px',
  '@media (max-width: 1024px)': {
    marginBottom: '16px',
  },
});

export const TitleWithLabel = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  marginBottom: '24px',
  '@media (max-width: 1024px)': {
    marginBottom: '16px',
  },
});

export const SectionTitle = styled('h3')({
  display: 'flex',
  alignSelf: 'flex-start',
  fontWeight: 600,
  fontSize: '20px',
  margin: 0,
  color: COLORS.gray700,
});

export const ColumnsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '134px',
  '@media (max-width: 1024px)': {
    gap: '24px',
  },
  '@media (max-width: 768px)': {
    flexWrap: 'wrap',
    gap: 0,
  },
});

export const ColumnWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  minWidth: 0,
  '@media (max-width: 768px)': {
    width: '100%',
  },
});

export const Section = styled('div')({
  maxWidth: '1140px',
  width: 'calc(100% - 32px)',
  padding: '0 0 40px',
});

export const SectionWrapper = styled(Section)({
  margin: '0 auto 42px',
  paddingBottom: '44px',
  borderBottom: `1px solid ${COLORS.gray300}`,
  '@media (max-width: 1024px)': {
    marginBottom: '34px',
  },
});

export const TextWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 0',
  height: '52px',
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${COLORS.gray100}`,
  },
});

export const FieldTitle = styled('span')({
  marginRight: '16px',
  fontWeight: 800,
  color: COLORS.gray700,
  whiteSpace: 'nowrap',
});

export const FieldValue = styled('span')({
  fontWeight: 500,
  color: COLORS.gray400,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const StyledButton = styled(Button)({
  minWidth: '100px',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&.ant-btn.ant-btn-ghost': {
    borderColor: COLORS.gray200,
    borderRadius: '8px',
    '& span': {
      marginRight: '10px',
    },
  },
});

export const SharePdfModal = styled(ModalDialog)(({ title }) => ({
  maxWidth: '420px',
  whiteSpace: 'pre-line',
  ...(!title && {
    'button.ant-modal-close': {
      top: '0',
      right: '0',
    },
  }),
}));

export const SharedSubtitle = styled('p')({
  fontWeight: 500,
  fontSize: '14px',
  color: COLORS.gray800,
  marginBottom: '21px',
  paddingRight: '25px',
});

export const ShareActionsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px',
});

export const CancelButton = styled(Button)({
  fontWeight: 800,
  fontSize: '16px',
  height: '48px',
  width: '157px',
  color: COLORS.gray700,
  '&.ant-btn.ant-btn-ghost': {
    borderColor: COLORS.gray200,
    borderRadius: '4px',
  },
});

export const ShareButton = styled(Button)({
  height: '48px',
  minWidth: '157px',
  fontWeight: 800,
  fontSize: '16px',
});
