import { useMutation, UseMutationResult } from 'react-query';

import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { NOTIFICATIONS_TEXT } from 'api/constants';
import { TAxiosRequestError } from 'api/types';
import { QUERY_KEYS } from 'constants/queryKeys';
import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { NotificationType } from 'types/notifications';
import { IBillingAddress } from 'types/customer';
import { transformRoute } from 'utils/routing';
import { openNotificationWithIcon } from 'utils/showNotification';

const useUpdateCustomerBillingAddress = (
  customerId: string,
): UseMutationResult<void, TAxiosRequestError, IBillingAddress> => {
  const handleUpdateCustomerBillingAddressSuccess = () => {
    openNotificationWithIcon(
      NotificationType.success,
      NOTIFICATION_SUCCESS_MESSAGE.updatePaymentAndBillingAddress,
    );

    queryClient.invalidateQueries([
      QUERY_KEYS.CUSTOMER_PAYMENT_AND_BILLING_ADDRESS,
      { customerId },
    ]);
    queryClient.invalidateQueries([QUERY_KEYS.USER_PROFILE]);
  };

  const handleUpdateCustomerBillingAddressError = (
    error: TAxiosRequestError,
  ) => {
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data?.message || NOTIFICATIONS_TEXT.WRONG,
    );
  };

  return useMutation(
    async (payload: IBillingAddress) => {
      const { data: dataResponse } = await apiClient.put(
        transformRoute(ApiRoutes.UPDATE_CUSTOMER_BILLING_ADDRESS, customerId),
        payload,
      );

      return dataResponse;
    },
    {
      retry: false,
      onSuccess: handleUpdateCustomerBillingAddressSuccess,
      onError: handleUpdateCustomerBillingAddressError,
    },
  );
};

export default useUpdateCustomerBillingAddress;
