import { TFormikConfig } from 'types/formik';
import { ISerialNumber } from 'types/serialNumber';

export enum ShareEmailFormField {
  EMAIL = 'email',
}

export interface IShareEmailFormValues {
  [ShareEmailFormField.EMAIL]: string;
}

export interface IShareEmailFormContainerProps {
  serialNumber: ISerialNumber;
  handleCloseModal: () => void;
}

export interface IShareEmailFormProps
  extends Omit<IShareEmailFormContainerProps, 'serialNumber'> {
  formik: TFormikConfig<IShareEmailFormValues> & {
    initialValues: IShareEmailFormValues;
  };
  isSubmitActionLoading: boolean;
}
