import React, { createContext, useContext, useState } from 'react';

import useUploadEncryptPdf from 'api/serialNumbers/useUploadEncryptPdf';
import { COPY_LINK_WITH_PWD_MESSAGE } from 'pages/SerialNumberDetails/constants';
import { EMPTY_STRING, NULL_VALUE } from 'constants/common';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';
import {
  NOTIFICATION_ERROR_MESSAGE,
  NOTIFICATION_SUCCESS_MESSAGE,
} from 'constants/messages';

import {
  ISerialNumbersDetailsModalDialogs,
  ISerialNumbersDetailsModalProviderProps,
} from './types';

const DEFAULT_CONTEXT: ISerialNumbersDetailsModalDialogs = {
  uploadEncryptPdfProgress: NULL_VALUE,
  isShareModalOpen: false,
  handleShareModalOpen: () => null,
  handleShareModalClose: () => null,
  isShareEmailModalOpen: false,
  handleShareEmailModalOpen: () => null,
  handleShareEmailModalClose: () => null,
  isShareSmsModalOpen: false,
  handleShareSmsModalOpen: () => null,
  handleShareSmsModalClose: () => null,
  isShareQRModalOpen: false,
  handleShareQRModalOpen: () => null,
  handleShareQRModalClose: () => null,
  uploadedPdf: undefined,
  isPdfUploading: false,
  pdfWithPassword: false,
  handleSetPdfWithPassword: () => null,
  handleCopyPdfLink: () => new Promise(() => null),
  pdfBytes: undefined,
  refetchSerialNumberDetails: () => null,
};

const SerialNumbersDetailsModalContext = createContext(DEFAULT_CONTEXT);

export const SerialNumbersDetailsModalContextProvider: React.FC<
  ISerialNumbersDetailsModalProviderProps
> = ({ children, pdfBytes, serialNumberId, refetchSerialNumberDetails }) => {
  const [isShareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [isShareEmailModalOpen, setShareEmailModalOpen] =
    useState<boolean>(false);
  const [isShareSmsModalOpen, setShareSmsModalOpen] = useState<boolean>(false);
  const [isShareQRModalOpen, setShareQRModalOpen] = useState<boolean>(false);
  const [pdfWithPassword, setPdfWithPassword] = useState<boolean>(false);

  const {
    data: uploadedPdf,
    isFetching: isPdfUploading,
    progress: uploadEncryptPdfProgress,
  } = useUploadEncryptPdf({
    pdfBytes,
    pdfWithPassword,
    isEnabled: Boolean(pdfBytes),
    serialNumberId,
  });

  const handleShareModalOpen = () => {
    setPdfWithPassword(false);
    setShareModalOpen(true);
  };
  const handleShareModalClose = () => setShareModalOpen(false);

  const handleShareEmailModalOpen = () => {
    setShareModalOpen(false);
    setShareEmailModalOpen(true);
  };
  const handleShareEmailModalClose = () => setShareEmailModalOpen(false);

  const handleShareSmsModalOpen = () => {
    setShareModalOpen(false);
    setShareSmsModalOpen(true);
  };
  const handleShareSmsModalClose = () => setShareSmsModalOpen(false);

  const handleShareQRModalOpen = () => {
    setShareModalOpen(false);
    setShareQRModalOpen(true);
  };
  const handleShareQRModalClose = () => setShareQRModalOpen(false);

  const handleSetPdfWithPassword = () => setPdfWithPassword((value) => !value);

  const handleCopyPdfLink = (copyQr = false) => {
    const linkToCopy = copyQr ? uploadedPdf?.qrCodeUrl : uploadedPdf?.url;

    const passwordNotice =
      copyQr && pdfWithPassword
        ? `${COPY_LINK_WITH_PWD_MESSAGE} ${uploadedPdf?.password}`
        : EMPTY_STRING;

    return navigator.clipboard
      .writeText(`${linkToCopy} ${passwordNotice}`)
      .then(() =>
        openNotificationWithIcon(
          NotificationType.success,
          NOTIFICATION_SUCCESS_MESSAGE.linkCopied,
        ),
      )
      .catch(() =>
        openNotificationWithIcon(
          NotificationType.error,
          NOTIFICATION_ERROR_MESSAGE.somethingWentWrong,
        ),
      );
  };

  return (
    <SerialNumbersDetailsModalContext.Provider
      value={{
        uploadEncryptPdfProgress,
        pdfBytes,
        uploadedPdf,
        pdfWithPassword,
        isPdfUploading,
        isShareModalOpen,
        isShareQRModalOpen,
        isShareSmsModalOpen,
        isShareEmailModalOpen,
        handleCopyPdfLink,
        handleSetPdfWithPassword,
        handleShareModalOpen,
        handleShareModalClose,
        handleShareQRModalOpen,
        handleShareQRModalClose,
        handleShareSmsModalOpen,
        handleShareSmsModalClose,
        handleShareEmailModalOpen,
        handleShareEmailModalClose,
        refetchSerialNumberDetails,
      }}
    >
      {children}
    </SerialNumbersDetailsModalContext.Provider>
  );
};

export const useSerialNumbersDetailsModalContext =
  (): ISerialNumbersDetailsModalDialogs =>
    useContext(SerialNumbersDetailsModalContext);

export default SerialNumbersDetailsModalContext;
