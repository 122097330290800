import { FC } from 'react';

import { CURRENCY_SHORT_NAME_LOOKUP } from 'constants/currencies';
import { useAppCurrency } from 'hooks/useAppCurrency';

import {
  CURRENCIES_FLAGS_LOOKUP,
  SN_STATUS_BY_TYPE_LOOKUP,
  SN_STATUS_TEXT_BY_TYPE_LOOKUP,
} from './constants';
import {
  SNStatusByCurrencyWrapper,
  SNStatusWrapper,
  SNStatusText,
} from './styled';
import { SNStatusType, TSNStatusByCurrency } from './types';

const SNStatusByCurrency: FC<TSNStatusByCurrency> = ({ type }) => {
  const currency = useAppCurrency();

  return (
    <>
      <SNStatusByCurrencyWrapper type={type}>
        {CURRENCIES_FLAGS_LOOKUP[currency]}
        <SNStatusWrapper>{SN_STATUS_BY_TYPE_LOOKUP[type]}</SNStatusWrapper>
      </SNStatusByCurrencyWrapper>
      {type !== SNStatusType.success && (
        <SNStatusText type={type}>
          {SN_STATUS_TEXT_BY_TYPE_LOOKUP[type]}
          {type === SNStatusType.default && (
            <>&nbsp;{CURRENCY_SHORT_NAME_LOOKUP[currency]}</>
          )}
        </SNStatusText>
      )}
    </>
  );
};

export default SNStatusByCurrency;
