import { FC } from 'react';

import { SavingsIcon } from 'assets/vectors';
import { renderMoneyDollars } from 'utils/renders';
import { calculatePercentage } from 'utils/numbers';
import { checkIsYearlyPlan } from 'pages/Payment/utils';

import {
  StyledPlanPrice,
  StyledPlanText,
  StyledPlanTip,
  StyledPlanWrapper,
  StyledRadioButton,
  StyledSavingsLabel,
  StyledBanner,
} from './styled';
import { ISubscriptionRadioButtonProps } from './types';

const SubscriptionRadioButton: FC<ISubscriptionRadioButtonProps> = ({
  plan,
  monthlyPlanPrice,
  banner,
}) => {
  const isYearlyPlan = checkIsYearlyPlan(plan);
  const priceDollars = renderMoneyDollars(plan.pricePerMonth);
  const savings = isYearlyPlan ? monthlyPlanPrice - plan.price : 0;
  const savingsPercentage = isYearlyPlan
    ? calculatePercentage(savings, monthlyPlanPrice)
    : null;
  const savingsDollars = renderMoneyDollars(savings);
  const aggregatedPriceDollars = renderMoneyDollars(
    isYearlyPlan ? plan.pricePerYear : plan.pricePerMonth,
  );
  const optionalPriceDollars = renderMoneyDollars(monthlyPlanPrice);
  const paidPeriod = isYearlyPlan ? 'yearly' : 'monthly';

  const withBanner = isYearlyPlan && Boolean(banner);

  return (
    <StyledRadioButton value={plan} withBanner={withBanner}>
      <StyledPlanWrapper>
        {withBanner && <StyledBanner>{banner}</StyledBanner>}
        <StyledPlanPrice>
          {priceDollars}/ mo
          <StyledSavingsLabel>
            <SavingsIcon />
            {savingsPercentage && `${savingsPercentage}%  - `}
            {savingsDollars} savings
          </StyledSavingsLabel>
        </StyledPlanPrice>
        <StyledPlanText>Premium Verification</StyledPlanText>
        <StyledPlanTip>
          {isYearlyPlan && <s>{`${optionalPriceDollars} `}</s>}
          {aggregatedPriceDollars} paid {paidPeriod}
        </StyledPlanTip>
      </StyledPlanWrapper>
    </StyledRadioButton>
  );
};

export default SubscriptionRadioButton;
