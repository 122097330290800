import { EMPTY_STRING, DEFAULT_PHONE_COUNTRY_CODE } from 'constants/common';

import { UserFormField, UserStepperKeys, TUserStepText } from './types';

export const EMAIL_CODE_SENT_HINT = `We'll send you a 6-digit code. 
  It expires 10 minutes after you request it.`;

export const STEP_BACK_TEXT = 'Back';

export const VERIFICATION_STEP = null;
export const USER_FIRST_STEP = UserStepperKeys.email;
export const USER_LAST_STEP = UserStepperKeys.address;

export const USER_STEP_TEXT: TUserStepText = {
  [UserStepperKeys.email]: {
    title: "What's your email address?",
    subtitle: "You'll use this to login.",
    hint: EMAIL_CODE_SENT_HINT,
  },
  [UserStepperKeys.verificationCode]: {
    title: 'Enter your verification code',
    subtitle: '',
  },
  [UserStepperKeys.password]: {
    title: 'Create a password',
    subtitle: "You'll use this to login.",
  },
  [UserStepperKeys.fullName]: {
    title: "What's your name?",
    subtitle: '',
  },
  [UserStepperKeys.phoneNumber]: {
    title: "What's your phone number?",
    subtitle:
      "We'll send you a six-digit code. It expires 10 minutes after you request it.",
  },
  [USER_LAST_STEP]: {
    title: "What's your address?",
    subtitle: '',
  },
};

export const INITIAL_USER_FORM_VALUES = {
  [UserFormField.EMAIL]: EMPTY_STRING,
  [UserFormField.VERIFICATION_CODE]: EMPTY_STRING,
  [UserFormField.PASSWORD]: EMPTY_STRING,
  [UserFormField.FIRST_NAME]: EMPTY_STRING,
  [UserFormField.LAST_NAME]: EMPTY_STRING,
  [UserFormField.PHONE_NUMBER]: EMPTY_STRING,
  [UserFormField.COUNTRY_CODE]: DEFAULT_PHONE_COUNTRY_CODE,
  [UserFormField.ADDRESS]: EMPTY_STRING,
};

export const USER_FORM_TEXT = {
  emailLabel: 'Email',
  verificationCodeLabel: 'Enter the 6-digit code',
  passwordLabel: 'Create a password',
  firstNameLabel: 'First Name',
  lastNameLabel: 'Last name',
  phoneNumberLabel: 'Phone Number',
  addressLabel: 'Address',
  button: 'Continue',
  submitButton: 'Submit',
};
