import styled from 'styled-components';
import { Checkbox as AntdCheckbox } from 'antd';

import Button from 'components/Button';
import { Link } from 'components/Link';
import { COLORS } from 'constants/colorPalette';

export const StyledForm = styled('form')({
  width: '100%',
});

export const FieldWrapper = styled('div')({
  marginBottom: '32px',
});

export const Checkbox = styled(AntdCheckbox)({
  fontWeight: 500,
  color: COLORS.gray900,
});

export const CheckboxFieldWrapper = styled(FieldWrapper)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: `1px solid ${COLORS.gray300}`,
  paddingTop: '32px',
});

export const SignInButton = styled(Button)({
  width: '100%',
  marginBottom: '32px',
});

export const StyledText = styled('p')({
  width: '100%',
  marginBottom: '20px',
  fontSize: '16px',
  lineHeight: '22px',
  color: COLORS.gray900,
});

export const StyledButton = styled(Button)({
  width: '100%',
  marginBottom: '20px',
});

export const StyledLink = styled(Link)({
  fontSize: '14px',
  lineHeight: '20px',
});
