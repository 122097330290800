import styled from 'styled-components';
import { Radio } from 'antd';

import Button from 'components/Button';
import { COLORS } from 'constants/colorPalette';

const { Group } = Radio;

export const StyledRadioGroup = styled(Group)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginBottom: '32px',
});

export const StyledLinkButton = styled(Button)({
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 500,
  '> span': {
    textDecoration: 'underline',
  },
});

export const ModalTitle = styled('h2')({
  fontSize: '32px',
  fontWeight: 600,
  color: COLORS.gray900,
});

export const ModalContentWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const ModalDescription = styled('p')({
  fontSize: '16px',
  textAlign: 'center',
  whiteSpace: 'pre-line',
  width: '852px',
});

export const StyledButton = styled(Button)({
  width: '158px',
  height: '48px',
});

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  gap: '16px',
  alignSelf: 'flex-end',
});
