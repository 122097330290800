import { FC } from 'react';

import { renderMoneyDollars } from 'utils/renders';

import { PAYMENT_INFO_BANNER_TEXT, PAYMENT_INFO_TEXT } from './constants';
import { IPaymentInfoProps } from './types';
import {
  PaymentInfoWrapper,
  PaymentInfoTitle,
  PaymentInfoRow,
  PaymentInfoRowText,
  PaymentInfoRowValue,
  StyledPaymentBanner,
} from './styled';

const PaymentInfo: FC<IPaymentInfoProps> = ({
  subscription,
  withBanner,
  isTrial,
}) => (
  <PaymentInfoWrapper withBanner={withBanner}>
    <PaymentInfoTitle>{PAYMENT_INFO_TEXT.title}</PaymentInfoTitle>
    {isTrial && (
      <PaymentInfoRow>
        <PaymentInfoRowText>{PAYMENT_INFO_TEXT.trial}</PaymentInfoRowText>
        <PaymentInfoRowValue>
          {PAYMENT_INFO_TEXT.trialPeriod}
        </PaymentInfoRowValue>
      </PaymentInfoRow>
    )}
    <PaymentInfoRow>
      <PaymentInfoRowText>{PAYMENT_INFO_TEXT.price}:</PaymentInfoRowText>
      <PaymentInfoRowValue>
        {`${renderMoneyDollars(subscription.price)} + tax`}
      </PaymentInfoRowValue>
    </PaymentInfoRow>
    <PaymentInfoRow>
      <PaymentInfoRowText>{PAYMENT_INFO_TEXT.payToday}:</PaymentInfoRowText>
      <PaymentInfoRowValue>
        {renderMoneyDollars(isTrial ? 0 : subscription.price)}
      </PaymentInfoRowValue>
    </PaymentInfoRow>
    {withBanner && (
      <StyledPaymentBanner text={PAYMENT_INFO_BANNER_TEXT} withIcon />
    )}
  </PaymentInfoWrapper>
);

export default PaymentInfo;
