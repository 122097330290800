import { useMutation, UseMutationResult } from 'react-query';
import { isNull } from 'lodash';

import { apiClient, queryClient } from 'api/base';
import { NOTIFICATIONS_TEXT } from 'api/constants';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { QUERY_KEYS } from 'constants/queryKeys';
import { NotificationType } from 'types/notifications';
import { openNotificationWithIcon } from 'utils/showNotification';

import { IUseUpdateProfilePayload, IUseUpdateProfileResponse } from './types';

const useUpdateCustomerProfile = (
  onSuccess?: () => void,
): UseMutationResult<
  IUseUpdateProfileResponse,
  TAxiosRequestError,
  IUseUpdateProfilePayload
> => {
  const handleUpdateProfileSuccess: (
    response: IUseUpdateProfileResponse,
    payload: IUseUpdateProfilePayload,
  ) => void = (response, payload) => {
    const isPasswordChanged = payload.newPassword && payload.oldPassword;
    let successMessage = NOTIFICATION_SUCCESS_MESSAGE.updateProfileDetails;

    if (isNull(payload.avatar)) {
      successMessage = NOTIFICATION_SUCCESS_MESSAGE.deleteProfilePhoto;
    }

    if (isPasswordChanged && response) {
      successMessage =
        NOTIFICATION_SUCCESS_MESSAGE.updateProfileDetailsPassword;
    }

    queryClient.invalidateQueries([QUERY_KEYS.USER_PROFILE]);

    onSuccess && onSuccess();

    openNotificationWithIcon(NotificationType.success, successMessage);

    if (response?.sendingEmailErrorMessage) {
      openNotificationWithIcon(
        NotificationType.error,
        response.sendingEmailErrorMessage,
      );
    }
  };

  const handleUpdateProfileError = (error: TAxiosRequestError) =>
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data.message || NOTIFICATIONS_TEXT.WRONG,
    );

  return useMutation(
    async (profilePayload: IUseUpdateProfilePayload) => {
      const { data: dataResponse } = await apiClient.post(
        ApiRoutes.UPDATE_CUSTOMER_PROFILE,
        profilePayload,
      );

      return dataResponse;
    },
    {
      retry: false,
      onSuccess: handleUpdateProfileSuccess,
      onError: handleUpdateProfileError,
    },
  );
};

export default useUpdateCustomerProfile;
