import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const ArticleTitle = styled('span')({
  margin: '16px 0 8px',
  fontSize: '18px',
  fontWeight: 800,
  letterSpacing: '0.2px',
  color: COLORS.gray700,
  textAlign: 'left',
});

export const ArticleText = styled('span')({
  fontSize: '14px',
  fontWeight: 500,
  color: COLORS.gray600,
  textAlign: 'left',
  letterSpacing: '0.2px',
});

export const StyledLink = styled(Link)({
  marginTop: '8px',
  textAlign: 'left',
  fontSize: '20px',
  fontWeight: 600,
  letterSpacing: '-2%',
  lineHeight: '28px',
  textDecoration: 'underline',
});

export const ArticleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'flex-start',
  width: '303px',
  height: '363px',
  padding: '32px',
  backgroundColor: COLORS.white,
});
