import { FC, useState } from 'react';

import { IPaymentMethodModalContainerProps } from './types';
import PaymentMethodModal from './PaymentMethodModal';

const PaymentMethodModalContainer: FC<IPaymentMethodModalContainerProps> = ({
  paymentCards,
  currentPaymentSourceId,
  isUpdatePaymentMethodLoading,
  isModalOpened,
  handleCloseModal,
  handleUpdatePaymentMethod,
  ...props
}) => {
  const [isAddPaymentMethodStep, togglePaymentMethodStep] =
    useState<boolean>(false);

  const goToAddPaymentMethodStep = () => {
    togglePaymentMethodStep(true);
  };

  const goToEditPaymentMethodStep = () => {
    togglePaymentMethodStep(false);
  };

  const handleModalAfterClose = () => {
    goToEditPaymentMethodStep();
  };

  return (
    <PaymentMethodModal
      paymentCards={paymentCards}
      currentPaymentSourceId={currentPaymentSourceId}
      isAddPaymentMethodStep={isAddPaymentMethodStep}
      isUpdatePaymentMethodLoading={isUpdatePaymentMethodLoading}
      isModalOpened={isModalOpened}
      handleCloseModal={handleCloseModal}
      afterClose={handleModalAfterClose}
      goToAddPaymentMethodStep={goToAddPaymentMethodStep}
      goToEditPaymentMethodStep={goToEditPaymentMethodStep}
      handleUpdatePaymentMethod={handleUpdatePaymentMethod}
      {...props}
    />
  );
};

export default PaymentMethodModalContainer;
