import { FC } from 'react';

import { IBillingAddressFormValues } from 'components/BillingAddress/types';
import { formatBillingAddressForSubmit } from 'components/BillingAddress/utils';

import BillingAddressModal from './BillingAddressModal';
import { IBillingAddressModalProps } from './types';

const BillingAddressModalContainer: FC<IBillingAddressModalProps> = ({
  handleCloseModal,
  handleSubmitBillingAddress,
  ...props
}) => {
  const submitBillingAddress = (payload: IBillingAddressFormValues) => {
    const formattedBillingAddress = formatBillingAddressForSubmit(payload);
    handleSubmitBillingAddress(formattedBillingAddress);
    handleCloseModal();
  };

  return (
    <BillingAddressModal
      handleSubmitBillingAddress={submitBillingAddress}
      handleCloseModal={handleCloseModal}
      {...props}
    />
  );
};

export default BillingAddressModalContainer;
