import { FC, memo } from 'react';
import { Popover, Switch } from 'antd';

import { SAVE_AND_SHARE_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { useSerialNumbersDetailsModalContext } from 'contexts/SerialNumberDetailsModalContext';
import {
  EmailCircleIcon,
  MessageCircleIcon,
  QrCodeCircleIcon,
} from 'assets/vectors';
import { Loader } from 'components/Loader';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';
import {
  NOTIFICATION_ERROR_MESSAGE,
  NOTIFICATION_SUCCESS_MESSAGE,
} from 'constants/messages';

import {
  ButtonIcon,
  CopyLinkButton,
  CopyLinkWrapper,
  CopyPasswordWrapper,
  EmbedCodeWrapper,
  ShareModalErrorSubTitle,
  ShareModalErrorTitle,
  ShareModalErrorWrapper,
  ShareModalWrapper,
  SharePdfWrapper,
  StyledEmbedInfoNotice,
  StyledExclamationCircleOutlined,
  StyledInfoCircleIcon,
} from './styled';
import { SHARE_MODAL_CONTENT } from './constants';
import { SHARE_MODAL_SUBTITLE } from '../constants';
import { SharedSubtitle, SharePdfModal } from '../styled';

const ShareModal: FC = () => {
  const {
    pdfBytes,
    uploadedPdf,
    isPdfUploading,
    pdfWithPassword,
    isShareModalOpen,
    handleCopyPdfLink,
    handleShareModalClose,
    handleShareQRModalOpen,
    handleShareSmsModalOpen,
    handleSetPdfWithPassword,
    handleShareEmailModalOpen,
  } = useSerialNumbersDetailsModalContext();

  const handleCopyPdfPassword = () =>
    navigator.clipboard
      .writeText(uploadedPdf?.password as string)
      .then(() =>
        openNotificationWithIcon(
          NotificationType.success,
          NOTIFICATION_SUCCESS_MESSAGE.passwordCopied,
        ),
      )
      .catch(() =>
        openNotificationWithIcon(
          NotificationType.error,
          NOTIFICATION_ERROR_MESSAGE.somethingWentWrong,
        ),
      );

  return (
    <SharePdfModal
      title={pdfBytes && SHARE_MODAL_CONTENT.TITLE}
      isModalOpened={isShareModalOpen}
      handleCloseModal={handleShareModalClose}
    >
      {pdfBytes ? (
        <ShareModalWrapper>
          {isPdfUploading ? (
            <Loader />
          ) : (
            <>
              <SharedSubtitle>{SHARE_MODAL_SUBTITLE}</SharedSubtitle>
              <EmbedCodeWrapper>
                <span>
                  {SHARE_MODAL_CONTENT.EMBED_CODE}
                  <Popover
                    placement='right'
                    content={
                      <StyledEmbedInfoNotice>
                        {SHARE_MODAL_CONTENT.EMBED_CODE_INFO}
                      </StyledEmbedInfoNotice>
                    }
                  >
                    <StyledInfoCircleIcon />
                  </Popover>
                </span>
                <Switch
                  checked={pdfWithPassword}
                  onChange={handleSetPdfWithPassword}
                />
              </EmbedCodeWrapper>
              <CopyPasswordWrapper>
                <p>{SHARE_MODAL_CONTENT.COPY_LINK_TITLE}</p>
                {pdfWithPassword && (
                  <CopyLinkButton
                    type='link'
                    size='small'
                    onClick={handleCopyPdfPassword}
                  >
                    {SHARE_MODAL_CONTENT.COPY_PASSWORD_BUTTON}
                  </CopyLinkButton>
                )}
              </CopyPasswordWrapper>
              <CopyLinkWrapper>
                <span>{uploadedPdf?.url}</span>
                <CopyLinkButton
                  type='link'
                  size='small'
                  onClick={() => handleCopyPdfLink()}
                >
                  {SHARE_MODAL_CONTENT.COPY_LINK_BUTTON}
                </CopyLinkButton>
              </CopyLinkWrapper>
              <SharePdfWrapper>
                <p>{SHARE_MODAL_CONTENT.SHARE_PDF}</p>
                <div>
                  <ButtonIcon
                    id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
                    onClick={handleShareSmsModalOpen}
                  >
                    <MessageCircleIcon />
                    {SHARE_MODAL_CONTENT.SHARE_BY_SMS}
                  </ButtonIcon>
                  <ButtonIcon
                    id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
                    onClick={handleShareEmailModalOpen}
                  >
                    <EmailCircleIcon />
                    {SHARE_MODAL_CONTENT.SHARE_BY_EMAIL}
                  </ButtonIcon>
                  <ButtonIcon
                    id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
                    onClick={handleShareQRModalOpen}
                  >
                    <QrCodeCircleIcon />
                    {SHARE_MODAL_CONTENT.SHARE_BY_QR}
                  </ButtonIcon>
                </div>
              </SharePdfWrapper>
            </>
          )}
        </ShareModalWrapper>
      ) : (
        <ShareModalErrorWrapper>
          <StyledExclamationCircleOutlined />
          <div>
            <ShareModalErrorTitle>
              {SHARE_MODAL_CONTENT.ERROR_TITLE}
            </ShareModalErrorTitle>
            <ShareModalErrorSubTitle>
              {SHARE_MODAL_CONTENT.ERROR_DESCRIPTION}
            </ShareModalErrorSubTitle>
          </div>
        </ShareModalErrorWrapper>
      )}
    </SharePdfModal>
  );
};

export default memo(ShareModal);
