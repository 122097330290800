import { FC } from 'react';

import { getCardNameByBrand } from 'utils/payment';

import {
  BILLING_ADDRESS_CONTENT,
  EDIT_BUTTON_TEXT,
  PAYMENT_CONTENT,
} from './constants';
import {
  PaymentAndBillingAddressWrapper,
  SectionWrapper,
  SectionTitleWrapper,
  SectionTitle,
  StyledSectionText,
  StyledSectionLabel,
  StyledButton,
  StyledArrowRight,
} from './styled';
import { IPaymentAndBillingAddressProps } from './types';

const PaymentAndBillingAddress: FC<IPaymentAndBillingAddressProps> = ({
  billingAddress,
  card,
  handleEditBillingAddress,
  handleEditPayment,
}) => {
  const cardName = card && getCardNameByBrand(card.brand);

  return (
    <PaymentAndBillingAddressWrapper>
      <SectionWrapper>
        <SectionTitleWrapper>
          <SectionTitle>{BILLING_ADDRESS_CONTENT.title}</SectionTitle>
          <StyledButton type='ghost' onClick={handleEditBillingAddress}>
            {EDIT_BUTTON_TEXT}
            <StyledArrowRight />
          </StyledButton>
        </SectionTitleWrapper>
        <StyledSectionText>
          {billingAddress.firstName} {billingAddress.lastName}
        </StyledSectionText>
        <StyledSectionText>{billingAddress.email}</StyledSectionText>
        <StyledSectionText>{billingAddress.phoneNumber}</StyledSectionText>
        {billingAddress.company && (
          <StyledSectionText>{billingAddress.company}</StyledSectionText>
        )}
        <StyledSectionText>{billingAddress.address}</StyledSectionText>
      </SectionWrapper>
      {card && (
        <SectionWrapper>
          <SectionTitleWrapper>
            <SectionTitle>{PAYMENT_CONTENT.title}</SectionTitle>
            <StyledButton type='ghost' onClick={handleEditPayment}>
              {EDIT_BUTTON_TEXT}
              <StyledArrowRight />
            </StyledButton>
          </SectionTitleWrapper>
          <StyledSectionText>
            <StyledSectionLabel>{cardName}: </StyledSectionLabel>
            {card.maskedNumber}
          </StyledSectionText>
        </SectionWrapper>
      )}
    </PaymentAndBillingAddressWrapper>
  );
};

export default PaymentAndBillingAddress;
