import { FC } from 'react';

import { LogoLarge } from 'assets/vectors';
import { thankYouImage } from 'assets/images';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { MENU_TITLE } from 'constants/menu';

import {
  ARTICLES_MOCK,
  BANNER_DETAILS_LIST,
  THANK_YOU_PAGE_CONTENT,
} from './constants';
import {
  ActionButton,
  ArticlesWrapper,
  BannerDescription,
  BannerSubtitle,
  BannerTitle,
  BannerWrapper,
  PageSubTitle,
  PageTitle,
  PageWrapper,
  StyledImage,
} from './styled';
import { IThankYou } from './types';
import Footer from './Footer';
import ArticleCard from './ArticleCard';

const ThankYou: FC<IThankYou> = ({
  isInitialStep,
  handleProceed,
  handleSwitchThankYouPageStep,
}) => (
  <HelmetPageTitle title={MENU_TITLE.thankYou}>
    <PageWrapper>
      <LogoLarge />
      {isInitialStep ? (
        <>
          <PageTitle>{THANK_YOU_PAGE_CONTENT.TITLE}</PageTitle>
          <PageSubTitle>{THANK_YOU_PAGE_CONTENT.SUB_TITLE}</PageSubTitle>
          <ActionButton onClick={handleSwitchThankYouPageStep}>
            {THANK_YOU_PAGE_CONTENT.BUTTON_TEXT}
          </ActionButton>
          <BannerWrapper>
            <StyledImage src={thankYouImage} />
            <BannerTitle>{THANK_YOU_PAGE_CONTENT.BANNER_TITLE}</BannerTitle>
            <BannerSubtitle>
              {THANK_YOU_PAGE_CONTENT.BANNER_SUB_TITLE}
            </BannerSubtitle>
            {BANNER_DETAILS_LIST.map((detailsText, index) => (
              <BannerDescription key={index}>{detailsText}</BannerDescription>
            ))}
          </BannerWrapper>
        </>
      ) : (
        <>
          <PageTitle>{THANK_YOU_PAGE_CONTENT.LEARN_MORE_TITLE}</PageTitle>
          <ArticlesWrapper>
            {ARTICLES_MOCK.map((article, index) => (
              <ArticleCard article={article} key={index} />
            ))}
          </ArticlesWrapper>
          <Footer handleProceed={handleProceed} />
        </>
      )}
    </PageWrapper>
  </HelmetPageTitle>
);

export default ThankYou;
