import { IModalDialogProps } from 'components/ModalDialog/types';
import { TFormik } from 'types/formik';
import { ISubscription, ISubscriptionPlan } from 'types/subscriptions';

export interface ISelectSubscriptionPlanModalContainerProps
  extends IModalDialogProps {
  subscription: ISubscription;
  handleChangeSubscriptionPlanSuccess?: () => void;
}

export interface ISelectSubscriptionPlanModalProps
  extends ISelectSubscriptionPlanModalContainerProps {
  formik: TFormik<IChangePlanFormValues>;
  selectedSubscriptionPlan: ISubscriptionPlan | null;
  subscriptionPlans: ISubscriptionPlan[];
  isChangeSubscriptionPlanLoading: boolean;
  handleChangeSubscriptionPlan: (plan?: ISubscriptionPlan) => void;
}

export enum ChangePlanField {
  planId = 'planId',
}

export interface IChangePlanFormValues {
  [ChangePlanField.planId]: string;
}
