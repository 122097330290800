export const SHARE_MODAL_CONTENT = {
  TITLE: 'Share Your ownership record PDF',
  ERROR_TITLE: 'This feature is currently\nunavailable for technical reasons.',
  ERROR_DESCRIPTION:
    "Sorry, we can't generate PDF for this serial number.\nPlease contact customer support for troubleshooting and further assistance.",
  EMBED_CODE: 'Embed code',
  COPY_LINK_TITLE: 'Copy link to this PDF',
  COPY_LINK_BUTTON: 'Copy',
  COPY_PASSWORD_BUTTON: 'Copy password',
  SHARE_PDF: 'or share this PDF via',
  SHARE_BY_SMS: 'Share by text message',
  SHARE_BY_EMAIL: 'Share by Email',
  SHARE_BY_QR: 'Share by QR  code',
  EMBED_CODE_INFO:
    'To protect your record with password, please, switch the "Embed Code" toggle on. The password will be shared with a third party by email and by text message if you pick sharing by these options. If you choose sharing by QR code, the password will be embedded and copied with them. If you choose sharing by link you need to copy and send the link first and then copy and send the password. Please note that a new link and a new corresponding password will be generated when the page is reloaded. This password is not saved anywhere. Therefore, you need to generate a new link with a new password and share it one more time in case you lose necessary password.',
};
