import { ResetPasswordField } from './types';

export const INITIAL_RESET_PASSWORD_VALUES = {
  [ResetPasswordField.password]: '',
};

export const RESET_PASSWORD_TEXT = {
  passwordLabel: 'Password',
  resetButtonText: 'Reset password',
  pageTitle: 'Reset password',
  pageSubTitle: 'Please enter your new password',
};

export const LINK_EXPIRED_SUBSTRING = 'link has expired';
