import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

import { SNStatusType, TSNStatusByCurrency } from './types';
import { SN_STATUS_COLOR_BY_TYPE_LOOKUP } from './constants';

export const SNStatusByCurrencyWrapper = styled('div')<TSNStatusByCurrency>(
  ({ type }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '122px',
    height: '122px',
    margin: `0 auto ${type === SNStatusType.success ? '40px' : '24px'}`,
    border: `1px solid ${COLORS.gray300}`,
    borderRadius: '50%',
  }),
);

export const SNStatusWrapper = styled('div')({
  position: 'absolute',
  content: '""',
  bottom: 0,
  right: 0,
  lineHeight: 0,
  transform: 'translateX(30%)',
});

export const SNStatusText = styled('h2')<TSNStatusByCurrency>(({ type }) => ({
  marginBottom: `${type === SNStatusType.success ? '24px' : '18px'}`,
  fontSize: '32px',
  lineHeight: 1.2,
  fontWeight: 600,
  textAlign: 'center',
  color: SN_STATUS_COLOR_BY_TYPE_LOOKUP[type],
}));
