import { EMPTY_STRING } from 'constants/common';

import { SerialNumberFormField } from './types';

export const VALIDATION_ERROR_MESSAGES = {
  serialNumberRequired: 'Please, enter your Serial number',
  serialNumberInvalid: 'Please enter a valid serial number',
};

export const INITIAL_SERIAL_NUMBER_FORM_VALUES = {
  [SerialNumberFormField.SERIAL_NUMBER]: EMPTY_STRING,
};

export const SERIAL_NUMBER_FORM_TEXT = {
  leanMoreLink: 'Learn more',
  serialNumberLabel: 'Enter your serial number',
};

export const SERIAL_NUMBER_BUTTON_TEXT = {
  submit: 'Verify now',
  notVerifiedSubmit: 'Verify it',
};
