import styled from 'styled-components';

import { LogoLarge } from 'assets/vectors';
import BackToLink from 'components/BackToLink';
import Button from 'components/Button';
import { COLORS } from 'constants/colorPalette';
import CustomAlert from 'components/CustomAlert';
import { PageTitle } from 'components/UnauthorizedPages';

export const StyledFormWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media (max-width: 768px)': {
    maxWidth: '480px',
    alignItems: 'inherit',
  },
});

export const StyledForm = styled('form')({
  width: '100%',
});

export const PageWrapper = styled('div')({
  width: '100%',
  '@media (max-width: 768px)': {
    maxWidth: '480px',
  },
});

export const SendLinkButton = styled(Button)({
  width: '100%',
  margin: '40px 0 130px',
  '@media (max-width: 768px)': {
    margin: '40px 0',
  },
});

export const StyledText = styled('p')({
  marginBottom: 0,
  fontSize: '16px',
  color: COLORS.gray900,
  textAlign: 'center',
  '@media (max-width: 768px)': {
    textAlign: 'inherit',
  },
});

export const ResendDescription = styled('p')({
  width: '100%',
  fontSize: '18px',
  color: COLORS.gray600,
  textAlign: 'center',
  whiteSpace: 'pre-line',
  '@media (max-width: 768px)': {
    textAlign: 'inherit',
  },
});

export const ResendButtonWrapper = styled('p')({
  width: '100%',
  color: COLORS.gray900,
  textAlign: 'center',
});

export const ResendButton = styled(Button)({
  height: 'inherit',
  padding: 0,
});

export const StyledAlert = styled(CustomAlert)({
  marginBottom: '40px',
  whiteSpace: 'pre-line',
});

export const StyledBackToLink = styled(BackToLink)({
  alignSelf: 'flex-start',
  marginBottom: '24px',
  fontWeight: 600,
  fontSize: '16px',
});

export const ResendWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '178px',
  '@media (max-width: 768px)': {
    alignItems: 'baseline',
    marginBottom: '40px',
  },
});

export const StyledLogo = styled(LogoLarge)({
  height: '35px',
  marginBottom: '24px',
});

export const StyledPageTitle = styled(PageTitle)({
  marginBottom: '36px',
});

export const EmailSentText = styled('p')({
  fontSize: '16px',
  fontWeight: 500,
  marginBottom: '32px',
  color: COLORS.gray700,
  textAlign: 'center',
  '& .highlighted': {
    fontWeight: 800,
    wordBreak: 'break-word',
  },
});
