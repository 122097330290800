import styled from 'styled-components';

import Button from 'components/Button';

import { TActionButtonProps } from './types';

export const StyledButton = styled(
  ({ width: _, ...rest }: TActionButtonProps) => <Button {...rest} />,
)(({ width }) => ({
  width: `${width}px`,
  height: '32px',
  padding: '8px 6px',
  fontWeight: 500,
  fontFamily: 'Manrope, sans-serif',
  '&.ant-btn-loading': {
    pointerEvents: 'none',
  },
  '.ant-btn-loading-icon': {
    position: 'absolute',
    bottom: '3px',
    left: 'calc(50% - 8px)',
  },
  '&.ant-btn-ghost:not([disabled])': {
    borderRadius: '4px',
  },
  '&[disabled]': {
    pointerEvents: 'none',
  },
}));

export const ButtonContentWrapper = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
  bottom: '3px',
  gap: '10px',
});

export const TooltipOverlayStyles = {
  width: '50px',
  textAlign: 'center',
};
