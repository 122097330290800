import { useQuery, UseQueryResult } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { QUERY_KEYS } from 'constants/queryKeys';
import { transformRoute } from 'utils/routing';

import { IInvoicesListResponse, IGetCustomerInvoicesListParams } from './types';

const useGetCustomerInvoicesList = (
  customerId: string,
  params: IGetCustomerInvoicesListParams,
  isEnabled = true,
): UseQueryResult<IInvoicesListResponse, TAxiosRequestError> =>
  useQuery(
    [QUERY_KEYS.INVOICES_LIST, params],
    async () => {
      const { data: invoiceslistResponse } = await apiClient.get(
        transformRoute(ApiRoutes.GET_CUSTOMER_INVOICES_LIST, customerId),
        { params },
      );

      return invoiceslistResponse;
    },
    {
      keepPreviousData: true,
      enabled: isEnabled,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

export default useGetCustomerInvoicesList;
