import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { useGenerateExportPdf } from 'api/requests';
import { useGetSerialNumberDetails } from 'api/serialNumbers';
import { useGetCustomerPaymentAndBillingAddress } from 'api/customers';
import { SerialNumbersDetailsModalContextProvider } from 'contexts/SerialNumberDetailsModalContext';
import { useUserId } from 'hooks';
import { CurrencyType } from 'types/currencies';

import { TSerialNumberDetailsParams } from './types';
import { downloadPdf } from './utils/exportPdf';
import SerialNumberDetails from './SerialNumberDetails';

const SerialNumberDetailsContainer: FC = () => {
  const customerId = useUserId();
  const { serialNumberId } = useParams() as TSerialNumberDetailsParams;

  const {
    data: serialNumberDetailsResponse,
    isFetching: isSerialNumberDetailsFetching,
    refetch: refetchSerialNumberDetails,
  } = useGetSerialNumberDetails(serialNumberId);

  const {
    data: customerPaymentAndBillingAddressResponse,
    isLoading: isCustomerPaymentAndBillingAddressLoading,
  } = useGetCustomerPaymentAndBillingAddress(customerId);

  const isReadyToExport = useMemo(() => {
    const isSaveAndShareEnabled =
      serialNumberDetailsResponse?.saveAndShareAccess?.[
        serialNumberDetailsResponse?.currency as unknown as keyof typeof CurrencyType
      ]?.isActive;

    return Boolean(
      isSaveAndShareEnabled && serialNumberDetailsResponse?.request?._id,
    );
  }, [serialNumberDetailsResponse]);

  const {
    data: generatedPdf,
    isFetching: isPdfGenerating,
    progress: generatePdfProgress,
  } = useGenerateExportPdf(
    serialNumberDetailsResponse?.request?._id as string,
    isReadyToExport,
  );

  const exportPdf = useCallback(
    () => downloadPdf(generatedPdf as ArrayBuffer),
    [downloadPdf, generatedPdf],
  );

  return (
    <SerialNumbersDetailsModalContextProvider
      pdfBytes={generatedPdf}
      serialNumberId={serialNumberDetailsResponse?._id}
      refetchSerialNumberDetails={refetchSerialNumberDetails}
    >
      <SerialNumberDetails
        serialNumber={serialNumberDetailsResponse}
        paymentCards={customerPaymentAndBillingAddressResponse?.cards || []}
        generatePdfProgress={generatePdfProgress}
        isPdfGenerating={isPdfGenerating}
        isSerialNumberDetailsFetching={
          isSerialNumberDetailsFetching ||
          isCustomerPaymentAndBillingAddressLoading
        }
        handleExportPdf={exportPdf}
      />
    </SerialNumbersDetailsModalContextProvider>
  );
};

export default SerialNumberDetailsContainer;
