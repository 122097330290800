import { FC } from 'react';
import { Progress } from 'antd';

import Tooltip from 'components/Tooltip';
import { COLORS } from 'constants/colorPalette';
import { EMPTY_STRING } from 'constants/common';

import {
  ButtonContentWrapper,
  StyledButton,
  TooltipOverlayStyles,
} from './styled';
import { TActionButtonProps } from './types';

const ActionButton: FC<TActionButtonProps> = ({
  children,
  progress,
  isUploading,
  loading,
  icon,
  ...rest
}) => (
  <Tooltip
    title={isUploading ? `${progress}%` : null}
    placement='topRight'
    align={{ offset: [5, 0] }}
    overlayStyle={TooltipOverlayStyles as React.CSSProperties}
  >
    <div>
      <StyledButton type='ghost' loading={!isUploading && loading} {...rest}>
        <ButtonContentWrapper>
          {children}
          {isUploading ? (
            <Progress
              type='circle'
              trailColor={COLORS.gray300}
              strokeWidth={11}
              width={16}
              format={() => EMPTY_STRING}
              percent={progress}
            />
          ) : (
            icon
          )}
        </ButtonContentWrapper>
      </StyledButton>
    </div>
  </Tooltip>
);

export default ActionButton;
