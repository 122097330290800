import { useQuery, UseQueryResult } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { ISerialNumber } from 'types/serialNumber';

const useCheckSerialNumberForPayment = (
  serialNumber: string,
): UseQueryResult<ISerialNumber, TAxiosRequestError> => {
  return useQuery(
    [],
    async () => {
      const { data: serialNumberResponse } = await apiClient.get(
        ApiRoutes.CHECK_SERIAL_NUMBER_FOR_PAYMENT,
        {
          params: { serialNumber },
        },
      );

      return serialNumberResponse;
    },
    {
      enabled: Boolean(serialNumber),
      keepPreviousData: false,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};

export default useCheckSerialNumberForPayment;
