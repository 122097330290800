import { useQuery, UseQueryResult } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { QUERY_KEYS } from 'constants/queryKeys';

import { IGetSubscriptionsPayload, ISubscriptionsResponse } from './types';

const useGetSubscriptionsList = (
  payload: IGetSubscriptionsPayload,
): UseQueryResult<ISubscriptionsResponse> => {
  return useQuery(
    [QUERY_KEYS.SUBSCRIPTIONS_LIST, payload],
    async () => {
      const { data: subscriptionsResponse } = await apiClient.get(
        ApiRoutes.SUBSCRIPTIONS,
        { params: payload },
      );

      return subscriptionsResponse;
    },
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};

export default useGetSubscriptionsList;
