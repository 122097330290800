import { useMutation, UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router';
import { AxiosError } from 'axios';

import { QUERY_KEYS } from 'constants/queryKeys';
import { APP_ROUTE } from 'constants/appRoutes';
import { NULL_TYPE_VALUE } from 'constants/common';
import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';

import { IUseSignOutResponse } from './types';

const useSignOut = (): UseMutationResult<
  IUseSignOutResponse,
  AxiosError,
  void
> => {
  const navigateTo = useNavigate();

  const handleSignOutSuccess = () => {
    navigateTo(APP_ROUTE.welcome);
  };

  return useMutation(
    async () => {
      queryClient.setQueryData(QUERY_KEYS.USER_PROFILE, NULL_TYPE_VALUE);

      const { data: tokenResponse } = await apiClient.post(ApiRoutes.SIGN_OUT);

      return tokenResponse;
    },
    { onSuccess: handleSignOutSuccess },
  );
};

export default useSignOut;
