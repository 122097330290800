import { useMutation } from 'react-query';

import { ApiRoutes } from 'api/routes';
import { apiClient } from 'api/base';

const useReadNotifications = () =>
  useMutation(
    async (endDate?: string) => {
      await apiClient.put(ApiRoutes.READ_NOTIFICATIONS, {
        endDate,
      });
    },
    {
      retry: false,
    },
  );

export default useReadNotifications;
