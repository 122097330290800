import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colorPalette';

import { PaymentBannerType } from './types';

const LARGE_BANNER_STYLES = {
  fontSize: '38px',
  lineHeight: '90px',
  fontWeight: 700,
  '@media (max-width: 1024px)': {
    fontSize: '22px',
    lineHeight: '60px',
  },
};

const BANNER_STYLES = {
  fontSize: '24px',
  lineHeight: '64px',
  fontWeight: 600,
  '@media (max-width: 768px)': {
    fontSize: '18px',
    lineHeight: '48px',
  },
};

export const StyledPaymentBanner = styled('div')<{ type: PaymentBannerType }>(
  ({ type }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.blue600,
    background: COLORS.blue100,
    ...(type === PaymentBannerType.large ? LARGE_BANNER_STYLES : BANNER_STYLES),
  }),
);

export const StyledInfoIcon = styled(InfoCircleOutlined)({
  fontSize: '34px',
  color: COLORS.blue600,
  marginRight: '15px',
  '@media (max-width: 1024px)': {
    fontSize: '20px',
    color: COLORS.blue600,
    marginRight: '15px',
  },
});
