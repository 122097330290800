import { FC } from 'react';
import type { To as TTo } from 'react-router';

import { PREV_PAGE_INDEX } from './constants';
import { StyledLink, StyledLeftCircleOutlined, StyledLinkText } from './styled';
import { TBackToLinkProps } from './types';

const BackToLink: FC<TBackToLinkProps> = ({
  to,
  allowBack = true,
  children,
  isDisabled = false,
  ...props
}) => {
  const routingHistory = window.history.state.idx;
  const navigatePath =
    routingHistory && allowBack ? (PREV_PAGE_INDEX as TTo) : to;

  return (
    <StyledLink isDisabled={isDisabled} to={navigatePath} {...props}>
      <StyledLeftCircleOutlined />
      <StyledLinkText>{children}</StyledLinkText>
    </StyledLink>
  );
};

export default BackToLink;
