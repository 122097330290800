import { ISerialNumber } from 'types/serialNumber';
import { ISubscriptionPlan } from 'types/subscriptions';

const YEAR_PLAN_PERIOD = 'year';

export const checkIsYearlyPlan = (plan: ISubscriptionPlan | null): boolean =>
  plan?.period === YEAR_PLAN_PERIOD;

export const checkIsFirstSubscription = (
  serialNumber: ISerialNumber | null,
): boolean => !serialNumber?.subscriptionStatus;
