import { EMPTY_STRING } from 'constants/common';
import { CurrencyType } from 'types/currencies';

export const removeCurrencyPostfix = (value: string): string =>
  value.replace(
    new RegExp(
      ` (${CurrencyType.DINAR}|${CurrencyType.DONG}|${CurrencyType.RUPIAH})$`,
    ),
    EMPTY_STRING,
  );
