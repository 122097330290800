import { FC, useEffect, useState } from 'react';

import { CardDetails } from 'components/Payment';

import { ADD_PAYMENT_METHOD_CONTENT } from './constants';
import {
  AddPaymentMethodWrapper,
  PaymentMethodWrapper,
  StyledBackButton,
  StyledArrowLeft,
  AddPaymentMethodTitle,
  StyledPaymentIcons,
  StyledButton,
} from './styled';
import { IAddPaymentMethodProps } from './types';

const AddPaymentMethod: FC<IAddPaymentMethodProps> = ({
  cardRef,
  isSubmitLoading,
  isCardFormValid,
  handleSubmitPayment,
  handleCheckIsCardFormValid,
  goToEditPaymentMethodStep,
}) => {
  //TODO: check this, this helps to avoid bug with extra field card input
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    });
  }, []);

  return (
    <AddPaymentMethodWrapper>
      <StyledBackButton
        type='link'
        onClick={goToEditPaymentMethodStep}
        disabled={isSubmitLoading}
      >
        <StyledArrowLeft />
        {ADD_PAYMENT_METHOD_CONTENT.backButton}
      </StyledBackButton>
      <AddPaymentMethodTitle>
        {ADD_PAYMENT_METHOD_CONTENT.title}
      </AddPaymentMethodTitle>
      <PaymentMethodWrapper>
        <StyledPaymentIcons />
        {isVisible && (
          <form onSubmit={handleSubmitPayment}>
            <CardDetails
              cardRef={cardRef}
              handleCheckIsCardFormValid={handleCheckIsCardFormValid}
            />
            <StyledButton
              htmlType='submit'
              loading={isSubmitLoading}
              disabled={!isCardFormValid}
            >
              {ADD_PAYMENT_METHOD_CONTENT.submitButton}
            </StyledButton>
          </form>
        )}
      </PaymentMethodWrapper>
    </AddPaymentMethodWrapper>
  );
};

export default AddPaymentMethod;
