import { FC } from 'react';
import { isEmpty } from 'lodash';

import { useSendCustomerVerificationCode } from 'api/customers';
import { TAxiosRequestError } from 'api/types';
import {
  ResponseType,
  SEND_VERIFICATION_CODE_ERROR_STRING,
} from 'constants/messages';
import { mapUpdateErrorMessage } from 'utils/errors/mapUpdateErrorMessage';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';

import { getSendVerificationCodeSuccessMessage } from '../VerificationCodeFields/utils';
import { IUserFieldsProps, IUserFormValues, UserFormField } from '../types';
import EmailFields from './EmailFields';

const EmailFieldsContainer: FC<IUserFieldsProps> = ({
  formik,
  responseMessage,
  handleChangeResponseMessage,
}) => {
  const {
    mutate: sendCustomerVerificationCodeMutate,
    isLoading: isSendVerificationCodeLoading,
    error: sendVerificationCodeError,
  } = useSendCustomerVerificationCode();

  const handleSendVerificationCodeSuccess = (email: string) => {
    if (handleChangeResponseMessage) {
      handleChangeResponseMessage({
        type: ResponseType.SUCCESS,
        message: getSendVerificationCodeSuccessMessage(email),
      });
    }

    formik.submitForm();
  };

  const handleSendVerificationCodeError = (error: TAxiosRequestError) => {
    const errorMessage = error.response?.data.message;

    if (errorMessage === SEND_VERIFICATION_CODE_ERROR_STRING) {
      openNotificationWithIcon(
        NotificationType.error,
        mapUpdateErrorMessage(error),
      );
    } else {
      formik.setErrors({
        [UserFormField.EMAIL]: errorMessage,
      });
    }
  };

  const handleSubmit = async ({ email }: IUserFormValues) => {
    const errors = await formik.validateForm();

    if (email && isEmpty(errors)) {
      sendCustomerVerificationCodeMutate(email, {
        onSuccess: () => handleSendVerificationCodeSuccess(email),
        onError: handleSendVerificationCodeError,
      });
    }
  };

  return (
    <EmailFields
      formik={formik}
      isCheckLoading={isSendVerificationCodeLoading}
      responseMessage={responseMessage}
      errorMessage={
        sendVerificationCodeError?.response?.data?.message ||
        sendVerificationCodeError?.message
      }
      handleSubmit={handleSubmit}
    />
  );
};

export default EmailFieldsContainer;
