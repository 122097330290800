import { FC } from 'react';

import ModalDialog from 'components/ModalDialog';
import SerialNumberStatusByCurrency, {
  SNStatusType,
} from 'components/SerialNumberStatusByCurrency';
import {
  CURRENCY_ABBREVIATION_LOOKUP,
  CURRENCY_NAME_LOOKUP,
} from 'constants/currencies';
import { useAppCurrency } from 'hooks/useAppCurrency';
import { formatSerialNumber } from 'utils/format';

import { VERIFIED_MODAL_TEXT } from './constants';
import { ISerialNumberVerifiedModalProps } from './types';
import {
  VerifiedModalButton,
  VerifiedModalSubtitle,
  VerifiedModalText,
  VerifiedModalTitle,
  VerifiedModalWrapper,
} from './styled';

const SerialNumberVerifiedModal: FC<ISerialNumberVerifiedModalProps> = ({
  serialNumber,
  isModalOpened,
  handleCloseModal,
}) => {
  const currency = useAppCurrency();
  const currencyAbbr = CURRENCY_ABBREVIATION_LOOKUP[currency];
  const serialNumberText = `${
    VERIFIED_MODAL_TEXT.text
  } ${currencyAbbr} ${formatSerialNumber(serialNumber)}`;

  return (
    <ModalDialog
      isModalOpened={isModalOpened}
      handleCloseModal={handleCloseModal}
      closable={false}
      maskClosable={false}
    >
      <VerifiedModalWrapper>
        <VerifiedModalSubtitle>
          {VERIFIED_MODAL_TEXT.subtitle}
        </VerifiedModalSubtitle>
        <VerifiedModalTitle>
          {CURRENCY_NAME_LOOKUP[currency]}
        </VerifiedModalTitle>
        <VerifiedModalText>{serialNumberText}</VerifiedModalText>
        <SerialNumberStatusByCurrency type={SNStatusType.success} />
        <VerifiedModalButton onClick={handleCloseModal}>
          {VERIFIED_MODAL_TEXT.buttonText}
        </VerifiedModalButton>
      </VerifiedModalWrapper>
    </ModalDialog>
  );
};

export default SerialNumberVerifiedModal;
