import { FC } from 'react';
import { useNavigate } from 'react-router';

import { APP_ROUTE } from 'constants/appRoutes';

import { IHeaderContainerProps } from './types';
import Header from './Header';

const HeaderContainer: FC<IHeaderContainerProps> = ({
  isDesktopVersion = true,
}) => {
  const navigateTo = useNavigate();

  const handleLogoClick = () => navigateTo(APP_ROUTE.welcome);

  return (
    <Header
      isDesktopVersion={isDesktopVersion}
      handleLogoClick={handleLogoClick}
    />
  );
};

export default HeaderContainer;
