export * from './percentage';

const NUMBER_TYPE = 'number';

export const checkIsNumber = (value: unknown) => typeof value === NUMBER_TYPE;

export const calculateEnumLength = (...enums: object[]): number =>
  enums.reduce(
    (sum: number, selectedEnum) =>
      sum +
      Object.keys(selectedEnum).filter((key) => isNaN(Number(key))).length,
    0,
  );
