import { FC } from 'react';
import { isEmpty } from 'lodash';

import { APP_ROUTE } from 'constants/appRoutes';
import { EMPTY_STRING } from 'constants/common';
import { MENU_TITLE } from 'constants/menu';
import { ALERT_SUCCESS_MESSAGE, SERVER_ERROR_STRING } from 'constants/messages';
import { MAX_EMAIL_LENGTH } from 'constants/validations';
import { ALERT_TYPE } from 'components/Alert';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { DefaultInput } from 'components/Inputs';
import { Loader } from 'components/Loader';
import UnauthorizedPageWrapper from 'components/Layouts/UnauthorizedLayout/UnauthorizedPageWrapper';
import { getResendButtonDisableText } from 'constants/datetime';

import { FORGOT_PASSWORD_TEXT } from './constants';
import {
  StyledFormWrapper,
  StyledForm,
  ResendButton,
  ResendButtonWrapper,
  SendLinkButton,
  StyledAlert,
  StyledBackToLink,
  ResendDescription,
  ResendWrapper,
  StyledLogo,
  StyledPageTitle,
  EmailSentText,
} from './styled';
import { ForgotPasswordField, IForgotPasswordProps } from './types';

const ForgotPassword: FC<IForgotPasswordProps> = ({
  formik,
  resendButtonTimer,
  errorMessage,
  isFormVisible,
  isSendLinkLoading,
  onBackLinkClick,
  onResendButtonClick,
}) => {
  const { values, errors, handleSubmit, getFieldProps } = formik;

  const isResetLinkErrorMessage =
    errorMessage && errorMessage.includes(SERVER_ERROR_STRING);
  const isErrorAlertShown = errorMessage && isEmpty(errors);

  return (
    <HelmetPageTitle title={MENU_TITLE.forgotPassword}>
      <UnauthorizedPageWrapper>
        {isFormVisible ? (
          <StyledBackToLink to={APP_ROUTE.signIn}>
            {FORGOT_PASSWORD_TEXT.backLinkText}
          </StyledBackToLink>
        ) : (
          <StyledBackToLink
            allowBack={false}
            to={APP_ROUTE.forgotPassword}
            onClick={onBackLinkClick}
          >
            {FORGOT_PASSWORD_TEXT.backLinkText}
          </StyledBackToLink>
        )}
        <StyledLogo />
        <StyledPageTitle>
          {FORGOT_PASSWORD_TEXT.forgotPageTitle}
        </StyledPageTitle>
        {isFormVisible ? (
          <StyledFormWrapper>
            {isErrorAlertShown && !isResetLinkErrorMessage && (
              <StyledAlert type={ALERT_TYPE.ERROR} message={errorMessage} />
            )}
            <StyledForm onSubmit={handleSubmit}>
              <DefaultInput
                labelText={FORGOT_PASSWORD_TEXT.inputLabel}
                errorMessage={errors[ForgotPasswordField.email]}
                maxLength={MAX_EMAIL_LENGTH}
                {...getFieldProps(ForgotPasswordField.email)}
              />
              <SendLinkButton
                htmlType='submit'
                size='large'
                loading={isSendLinkLoading}
              >
                {FORGOT_PASSWORD_TEXT.sendLinkButtonText}
              </SendLinkButton>
            </StyledForm>
          </StyledFormWrapper>
        ) : (
          <ResendWrapper>
            {isResetLinkErrorMessage ? (
              <StyledAlert type={ALERT_TYPE.ERROR} message={errorMessage} />
            ) : (
              <EmailSentText>
                {ALERT_SUCCESS_MESSAGE.successResetLinkMessage(
                  values[ForgotPasswordField.email],
                )}
              </EmailSentText>
            )}
            {isSendLinkLoading ? (
              <Loader />
            ) : resendButtonTimer !== EMPTY_STRING ? (
              <ResendDescription>
                {getResendButtonDisableText(resendButtonTimer)}
              </ResendDescription>
            ) : (
              <ResendButtonWrapper>
                {FORGOT_PASSWORD_TEXT.didntReceiveEmail}&nbsp;
                <ResendButton
                  type='link'
                  htmlType='submit'
                  onClick={onResendButtonClick}
                >
                  {FORGOT_PASSWORD_TEXT.resendButtonText}
                </ResendButton>
              </ResendButtonWrapper>
            )}
          </ResendWrapper>
        )}
      </UnauthorizedPageWrapper>
    </HelmetPageTitle>
  );
};

export default ForgotPassword;
