import { FC } from 'react';

import { Loader } from 'components/Loader';

import {
  CardCVV,
  CardComponent,
  CardComponentRow,
  CardExpiry,
  CardNumber,
  CardFieldWrapper,
  CardFieldError,
} from './styled';
import { ICardDetailsProps } from './types';
import { getCardFieldClassName } from './utils';

const CardDetails: FC<ICardDetailsProps> = ({
  cardRef,
  cardNumberField,
  cardExpiryField,
  cardCVVField,
  handleCardNumberFieldChange,
  handleCardExpiryFieldChange,
  handleCardCVVFieldChange,
}) => {
  const cardNumberFieldClassName = getCardFieldClassName(cardNumberField);
  const cardExpiryFieldClassName = getCardFieldClassName(cardExpiryField);
  const cardCVVFieldClassName = getCardFieldClassName(cardCVVField);

  if (!window.Chargebee.inited) {
    return <Loader />;
  }

  return (
    <>
      <CardComponent ref={cardRef}>
        <CardComponentRow>
          <CardFieldWrapper>
            <CardNumber
              className={cardNumberFieldClassName}
              onChange={handleCardNumberFieldChange}
            />
            {cardNumberField?.error && (
              <CardFieldError>{cardNumberField?.error.message}</CardFieldError>
            )}
          </CardFieldWrapper>
        </CardComponentRow>
        <CardComponentRow>
          <CardFieldWrapper>
            <CardExpiry
              className={cardExpiryFieldClassName}
              onChange={handleCardExpiryFieldChange}
            />
            {cardExpiryField?.error && (
              <CardFieldError>{cardExpiryField?.error.message}</CardFieldError>
            )}
          </CardFieldWrapper>
          <CardFieldWrapper>
            <CardCVV
              className={cardCVVFieldClassName}
              onChange={handleCardCVVFieldChange}
            />
            {cardCVVField?.error && (
              <CardFieldError>{cardCVVField?.error.message}</CardFieldError>
            )}
          </CardFieldWrapper>
        </CardComponentRow>
      </CardComponent>
    </>
  );
};

export default CardDetails;
