import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';

import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { ICustomerInfo } from 'types/customer';
import { QUERY_KEYS } from 'constants/queryKeys';

import { IRequestErrorPayload, IUseSignUpPayload } from './types';

const useSignUp = (
  onSuccess: () => void,
  onError: (error: TAxiosRequestError) => void,
): UseMutationResult<
  ICustomerInfo,
  AxiosError<IRequestErrorPayload>,
  IUseSignUpPayload
> => {
  const handleSignUpSuccess = async () => {
    await queryClient.invalidateQueries([QUERY_KEYS.USER_PROFILE]);
    onSuccess();
  };

  return useMutation(
    async (payload: IUseSignUpPayload) => {
      const { data: tokenResponse } = await apiClient.post(
        ApiRoutes.SIGN_UP,
        payload,
      );

      return tokenResponse;
    },
    {
      onSuccess: handleSignUpSuccess,
      onError,
    },
  );
};

export default useSignUp;
