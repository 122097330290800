import { IBillingAddress } from './customer';
import { TUploadFile } from './files';
import { TPhoneNumberCountryCode } from './phoneNumbers';

export enum UserTypes {
  employee = 'employee',
  customer = 'customer',
}

export enum UserRoles {
  customer = 'customer',
}

export interface IUserInfo {
  _id: string;
  firstName: string;
  lastName: string;
  role: UserRoles;
  email: string;
  phoneNumber: string;
  countryCode: TPhoneNumberCountryCode;
  isArchived: boolean;
  address: string;
  billingAddress?: IBillingAddress;
  avatar?: TUploadFile | null;
  machineSN?: string;
}
