export const BILLING_ADDRESS_FORM_TEXT = {
  firstNameLabel: 'First name',
  lastNameLabel: 'Last name',
  emailLabel: 'Email',
  phoneNumberLabel: 'Phone number',
  companyLabel: 'Company',
  addressLabel: 'Address',
  cancelbutton: 'Cancel',
  submitButton: 'Save',
};
