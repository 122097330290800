import { FC, useState } from 'react';

import { PaymentMethodModal } from 'components/Payment';
import { PaymentCardRole } from 'types/customer';

import { IPaymentAndBillingAddressContainerProps } from './types';
import PaymentAndBillingAddress from './PaymentAndBillingAddress';

const PaymentAndBillingAddressContainer: FC<
  IPaymentAndBillingAddressContainerProps
> = ({
  billingAddress,
  paymentCards,
  currentPaymentSourceId,
  handleUpdatePaymentMethod,
  handleOpenBillingAddressModal,
}) => {
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] =
    useState<boolean>(false);

  const primaryCard =
    paymentCards.find((card) => card.role === PaymentCardRole.primary) || null;

  const selectedCard =
    paymentCards.find((card) => card.sourceId === currentPaymentSourceId) ||
    primaryCard;

  const handleOpenPaymentMethodModal = () => {
    setIsPaymentMethodModalOpen(true);
  };
  const handleClosePaymentMethodModal = () => {
    setIsPaymentMethodModalOpen(false);
  };

  const updatePaymentMethod = (paymentSourceId: string) => {
    handleUpdatePaymentMethod(paymentSourceId);
    handleClosePaymentMethodModal();
  };

  return (
    <>
      <PaymentAndBillingAddress
        billingAddress={billingAddress}
        card={selectedCard}
        handleEditBillingAddress={handleOpenBillingAddressModal}
        handleEditPayment={handleOpenPaymentMethodModal}
      />
      {paymentCards && (
        <PaymentMethodModal
          paymentCards={paymentCards}
          currentPaymentSourceId={currentPaymentSourceId}
          isModalOpened={isPaymentMethodModalOpen}
          handleCloseModal={handleClosePaymentMethodModal}
          handleUpdatePaymentMethod={updatePaymentMethod}
        />
      )}
    </>
  );
};

export default PaymentAndBillingAddressContainer;
