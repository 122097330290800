import { FC } from 'react';

import ModalDialog from 'components/ModalDialog';
import { IModalDialogProps } from 'components/ModalDialog/types';

import { LearnMoreModalWrapper } from './styled';

const LearnMoreModal: FC<IModalDialogProps> = ({
  width,
  children,
  isModalOpened,
  handleCloseModal,
}) => (
  <ModalDialog
    width={width}
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
  >
    <LearnMoreModalWrapper>{children}</LearnMoreModalWrapper>
  </ModalDialog>
);

export default LearnMoreModal;
