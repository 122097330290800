import { FC } from 'react';

import { MAX_EMAIL_LENGTH } from 'constants/validations';
import { SAVE_AND_SHARE_ACTIONS_BUTTONS_ID } from 'constants/googleAnalytics';
import { DefaultInput } from 'components/Inputs';

import {
  CancelButton,
  ShareActionsWrapper,
  ShareButton,
  SharedSubtitle,
} from '../styled';
import { SHARE_MODAL_SUBTITLE } from '../constants';
import { SHARE_EMAIL_FORM_TEXT } from './constants';
import { EmailInputLabel } from './styled';
import { IShareEmailFormProps, ShareEmailFormField } from './types';

const ShareEmailForm: FC<IShareEmailFormProps> = ({
  formik,
  isSubmitActionLoading,
  handleCloseModal,
}) => {
  const { errors, handleSubmit, getFieldProps } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <SharedSubtitle>{SHARE_MODAL_SUBTITLE}</SharedSubtitle>
      <EmailInputLabel>
        {SHARE_EMAIL_FORM_TEXT.EMAIL_INPUT_LABEL}
      </EmailInputLabel>
      <DefaultInput
        id={ShareEmailFormField.EMAIL}
        labelText={SHARE_EMAIL_FORM_TEXT.EMAIL_LABEL}
        errorMessage={errors.email}
        maxLength={MAX_EMAIL_LENGTH}
        {...getFieldProps(ShareEmailFormField.EMAIL)}
      />
      <ShareActionsWrapper>
        <CancelButton type='ghost' onClick={handleCloseModal}>
          {SHARE_EMAIL_FORM_TEXT.CANCEL_LABEL}
        </CancelButton>
        <ShareButton
          htmlType='submit'
          id={SAVE_AND_SHARE_ACTIONS_BUTTONS_ID}
          loading={isSubmitActionLoading}
          type='primary'
        >
          {SHARE_EMAIL_FORM_TEXT.SHARE_LABEL}
        </ShareButton>
      </ShareActionsWrapper>
    </form>
  );
};

export default ShareEmailForm;
