export const SERIAL_NUMBER_HEADER = {
  serialNumber: 'Serial # ',
  shareButton: 'Share',
  saveButton: 'Save',
  saveShareInfo:
    'DinarVerify gives the customers verifiable ownership records that are easily shareable with 3rd parties and which you can easily save to your devices. You can share your ownership records by Email,  by QR code, by text message and by link. To protect your record with password, please, switch the "Embed Code" toggle on. The password will be shared with a third party by email and by text message if you pick sharing by these options. If you choose sharing by QR code, the password will be embedded and copied with them. If you choose sharing by link you need to copy and send the link first and then copy and send the password. Please note that a new link and a new corresponding password will be generated when the page is reloaded. This password is not saved anywhere. Therefore, you need to generate a new link with a new password and share it one more time in case you lose necessary password. Also you can save your records to your device, Google Drive or DropBox. Please note that the Save and Share feature is an optional feature and is not always enabled. Disabled date is indicated on the lable.',
};

export const SAVE_OPTIONS = {
  saveDevice: 'Save to Device',
  saveGoogle: 'Save to Google Drive',
  saveDropbox: 'Save to DropBox',
};

export const GOOGLE = {
  SCOPES: 'https://www.googleapis.com/auth/drive.file',
  DISCOVERY_DOCS: [
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
  ],
  GAPI_SCRIPT: 'https://apis.google.com/js/api.js',
  GSI_SCRIPT: 'https://accounts.google.com/gsi/client',
  CLIENT: 'client',
};

export const DROPBOX_SCRIPT = {
  SRC: 'https://www.dropbox.com/static/api/2/dropins.js',
  ID: 'dropboxjs',
  TYPE: 'text/javascript',
};

export const UPLOAD_FILE_OPTIONS = {
  URL: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id',
  POST: 'POST',
  BEARER: 'Bearer ',
};

export enum AccessTokenPrompt {
  consent = 'consent',
  empty = '',
}

export const GOOGLE_POPUP_CLOSED_ERROR = 'popup_closed';

export const TWO_REQUESTS_COUNT = 2;
