import { FC } from 'react';

import {
  ArticleText,
  ArticleTitle,
  ArticleWrapper,
  StyledLink,
} from './styled';
import { IArticleCard } from './types';
import { LINK_TEXT } from './constants';

const ArticleCard: FC<IArticleCard> = ({ article }) => {
  const { title, description, link, image } = article;

  return (
    <ArticleWrapper>
      {image}
      <ArticleTitle>{title}</ArticleTitle>
      <ArticleText>{description}</ArticleText>
      <StyledLink to={link}>{LINK_TEXT}</StyledLink>
    </ArticleWrapper>
  );
};

export default ArticleCard;
