import { FC } from 'react';

import LearnMoreModal, {
  LearnMoreModalFooter,
  LearnMoreModalSteps,
} from 'components/LearnMoreModal';
import { IModalDialogProps } from 'components/ModalDialog/types';

import {
  LearnMoreModalTitle,
  LearnMoreModalBoldText,
  LearnMoreModalText,
} from './styled';
import { ENTRY_STEP_MODAL_CONTENT, ENTRY_STEP_MODAL_WIDTH } from './constants';

const EntryStepModal: FC<IModalDialogProps> = ({
  isModalOpened,
  handleCloseModal,
}) => (
  <LearnMoreModal
    width={ENTRY_STEP_MODAL_WIDTH}
    isModalOpened={isModalOpened}
    handleCloseModal={handleCloseModal}
  >
    <>
      <LearnMoreModalTitle>
        {ENTRY_STEP_MODAL_CONTENT.title}
      </LearnMoreModalTitle>
      <LearnMoreModalBoldText>
        {ENTRY_STEP_MODAL_CONTENT.subtitle}
      </LearnMoreModalBoldText>
      <LearnMoreModalText>{ENTRY_STEP_MODAL_CONTENT.text}</LearnMoreModalText>
      <LearnMoreModalBoldText>
        {ENTRY_STEP_MODAL_CONTENT.tip}
      </LearnMoreModalBoldText>
      <LearnMoreModalSteps
        stepsList={ENTRY_STEP_MODAL_CONTENT.steps}
        stepsListTitle={ENTRY_STEP_MODAL_CONTENT.stepsTitle}
        withImages={false}
      />
      <LearnMoreModalFooter />
    </>
  </LearnMoreModal>
);

export default EntryStepModal;
