import { FC } from 'react';

import { getCardLargeIconByBrand, getCardNameByBrand } from 'utils/payment';

import { CARD_NAME_PREFIX, PAYMENT_METHOD_INFO } from './constants';
import {
  RadioButtonWrapper,
  StyledRadioButton,
  PaymentMethodBrandLogo,
  PaymentMethodBrandName,
  PaymentMethodBrandNamePrefix,
  PaymentMethodCardNumbers,
  PaymentMethodInfo,
  PaymentMethodInfoText,
  PaymentMethodInfoIcon,
} from './styled';
import { IPaymentMethodRadioButtonProps } from './types';

const PaymentMethodRadioButton: FC<IPaymentMethodRadioButtonProps> = ({
  card,
}) => {
  const isPrimaryCard = !card.sourceId;

  const cardLargeIcon = getCardLargeIconByBrand(card.brand);
  const cardName = getCardNameByBrand(card.brand);

  return (
    <RadioButtonWrapper isPrimary={isPrimaryCard}>
      <StyledRadioButton value={card.sourceId}>
        <PaymentMethodBrandLogo>{cardLargeIcon}</PaymentMethodBrandLogo>
        <div>
          <PaymentMethodBrandName>
            {isPrimaryCard && (
              <PaymentMethodBrandNamePrefix>
                {CARD_NAME_PREFIX}
              </PaymentMethodBrandNamePrefix>
            )}
            {cardName}
          </PaymentMethodBrandName>
          <PaymentMethodCardNumbers>
            {card.maskedNumber}
          </PaymentMethodCardNumbers>
        </div>
      </StyledRadioButton>
      {isPrimaryCard && (
        <PaymentMethodInfo>
          <PaymentMethodInfoIcon />
          <PaymentMethodInfoText>{PAYMENT_METHOD_INFO}</PaymentMethodInfoText>
        </PaymentMethodInfo>
      )}
    </RadioButtonWrapper>
  );
};

export default PaymentMethodRadioButton;
