import styled from 'styled-components';

import Button from 'components/Button';
import { COLORS } from 'constants/colorPalette';

export const StyledImage = styled('img')({
  display: 'block',
  margin: '60px auto 29px',
  maxWidth: '120px',
  maxHeight: '120px',
});

export const StyledArticleImage = styled('img')({
  display: 'block',
  maxWidth: '239px',
  maxHeight: '126px',
});

export const PageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '80px',
  width: '100%',
});

export const PageTitle = styled('h1')({
  margin: '44px auto 20px',
  fontSize: '32px',
  fontWeight: 600,
  color: COLORS.gray800,
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  lineHeight: '44px',
});

export const PageSubTitle = styled(PageTitle)({
  margin: 0,
  fontSize: '20px',
  letterSpacing: '-2%',
  color: COLORS.gray600,
  lineHeight: '28px',
});

export const ActionButton = styled(Button)({
  margin: '32px auto 105px',
  width: '416px',
});

export const BannerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '592px',
  height: '457px',
  backgroundColor: COLORS.gray50,
  border: `1px solid ${COLORS.gray300}`,
  marginBottom: '60px',
});

export const BannerTitle = styled(PageTitle)({
  fontWeight: 700,
  margin: 0,
});

export const BannerSubtitle = styled('span')({
  margin: '12px 0 29px',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '25.2px',
  letterSpacing: '0.1px',
  color: COLORS.gray600,
  textAlign: 'center',
});

export const BannerDescription = styled('span')({
  marginBottom: '12px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '19.6px',
  textAlign: 'center',
  color: COLORS.gray600,
  letterSpacing: '0.2px',
});

export const ArticlesWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '31px',
  rowGap: '32px',
  width: '980px',
  height: '758px',
});
