import { isEqual, omit } from 'lodash';

import { NULL_TYPE_VALUE } from 'constants/common';
import { IBillingAddressFormValues } from 'components/BillingAddress/types';
import { renderMoneyDollars } from 'utils/renders';

import { AS_HOME_CHECKBOX_FIELD } from './constants';

export const checkIsBillingAddressChanged = (
  customerBillingAddress: IBillingAddressFormValues,
  billingAddressFormValues: IBillingAddressFormValues,
) =>
  !isEqual(
    omit(
      { ...customerBillingAddress, company: NULL_TYPE_VALUE },
      AS_HOME_CHECKBOX_FIELD,
    ),
    omit(billingAddressFormValues, AS_HOME_CHECKBOX_FIELD),
  );

export const getSubmitPaymentButtonText = (price: number): string =>
  `Unlock records - Pay ${renderMoneyDollars(price)} & subscribe`;
