import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const LearnMoreModalStepsWrapper = styled('ul')({
  padding: 0,
  marginBottom: '64px',
  listStyle: 'none',
  counterReset: 'section',
  '& li:not(last-child) ': {
    marginBottom: '54px',
  },
});

export const LearnMoreModalStepsTitle = styled('h4')({
  margin: '40 0 32px',
  fontSize: '24px',
  lineHeight: '34px',
  fontWeight: 600,
  color: COLORS.gray900,
});

export const LearnMoreModalStepWrapper = styled('li')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  paddingLeft: '74px',
  '&:before': {
    position: 'absolute',
    content: 'counter(section, decimal-leading-zero)',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    fontSize: '16px',
    fontWeight: 500,
    color: COLORS.blue500,
    borderRadius: '50%',
    background: COLORS.blue50,
    counterIncrement: 'section',
  },
});

export const LearnMoreModalStepTextWrapper = styled('div')({
  maxWidth: '438px',
  width: '100%',
  marginRight: '32px',
  '@media (max-width: 768px)': {
    marginRight: 0,
  },
});

export const LearnMoreModalStepTitle = styled('h3')({
  margin: 0,
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 800,
  color: COLORS.gray900,
});

export const LearnMoreModalStepSubtitle = styled('h3')({
  margin: 0,
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 500,
  color: COLORS.gray600,
});

export const LearnMoreModalStepImg = styled('div')({
  width: '214px',
  height: '100px',
  backgroundColor: COLORS.gray300,
  marginLeft: 'auto',
  '@media (max-width: 768px)': {
    display: 'none',
  },
});
