import {
  BillingAddressFormField,
  TButtonsText,
} from 'components/BillingAddress/types';

export const PAYMENT_FORM_TEXT = {
  title: 'Pay using your debit / credit card',
  subtitle: 'Visa, Mastercard, Discover or Amex.',
  cardNumberLabel: 'Card number',
  expirationDateLabel: 'Expiration date',
  securityCode: 'Security code',
  checkboxText: 'Billing address same as home address.',
  addressButtonText: 'Show billing address',
  submitTrialButtonText: 'Unlock records - Start my free trial now',
  paymentSecureText: 'Secure Checkout by Chargebee',
};

export const BILLING_ADDRESS_MODAL_TEXT = {
  addBillingAddressTitle: 'Please enter your Billing address',
  asHomeAddressTitle: 'Your Billing address',
  defaultTitleFromModal: undefined,
};

export const BILLING_ADDRESS_FORM_BUTTON_TEXT: Record<
  | 'addBillingAddressFormButtonsText'
  | 'asHomeAddressFormButtonsText'
  | 'defaultFormButtonsText',
  TButtonsText | undefined
> = {
  addBillingAddressFormButtonsText: {
    cancelText: 'Cancel',
    submitText: 'Save',
  },
  asHomeAddressFormButtonsText: {
    cancelText: null,
    submitText: null,
  },
  defaultFormButtonsText: undefined,
};

export const AS_HOME_CHECKBOX_FIELD = BillingAddressFormField.SAME_WITH_PROFILE;
export const COMPANY_FIELD = BillingAddressFormField.COMPANY;
