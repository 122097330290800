import { useMutation, UseMutationResult } from 'react-query';

import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { NOTIFICATIONS_TEXT } from 'api/constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { useUserId } from 'hooks';
import { NotificationType } from 'types/notifications';
import { openNotificationWithIcon } from 'utils/showNotification';
import { transformRoute } from 'utils/routing';

import { IUpdatePaymentCardRolePayload } from './types';

const useUpdatePaymentCardRole = (): UseMutationResult<
  void,
  TAxiosRequestError,
  IUpdatePaymentCardRolePayload
> => {
  const customerId = useUserId();

  const handleUpdatePaymentCardRoleSuccess = () => {
    openNotificationWithIcon(
      NotificationType.success,
      NOTIFICATION_SUCCESS_MESSAGE.updatePayment,
    );

    queryClient.invalidateQueries([
      QUERY_KEYS.CUSTOMER_PAYMENT_AND_BILLING_ADDRESS,
      { customerId },
    ]);
  };

  const handleUpdatePaymentCardRoleError = (error: TAxiosRequestError) => {
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data?.message || NOTIFICATIONS_TEXT.WRONG,
    );
  };

  return useMutation(
    async ({ paymentSourceId, role }) => {
      await apiClient.patch(
        transformRoute(
          ApiRoutes.UPDATE_PAYMENT_CARD,
          customerId,
          paymentSourceId,
        ),
        { role },
      );
    },
    {
      onSuccess: handleUpdatePaymentCardRoleSuccess,
      onError: handleUpdatePaymentCardRoleError,
    },
  );
};

export default useUpdatePaymentCardRole;
