import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useIsFetching } from 'react-query';

import {
  useGetIsDesktopVersion,
  useGetUserFromQueryContext,
  useToggle,
} from 'hooks';
import { useSignOut } from 'api/auth';
import { showConfirmModal } from 'utils/showConfirmModal';
import { EMPTY_OBJECT, NULL_VALUE } from 'constants/common';
import { SIGN_OUT_LS_KEY } from 'constants/localStorage';
import { SearchParams } from 'types/urlSearchParams';
import { QUERY_KEYS } from 'constants/queryKeys';
import { queryClient } from 'api/base';

import AuthorizedLayout from './AuthorizedLayout';
import { SIGN_OUT_CONFIRM_PROPS } from './constants';

const AuthorizedLayoutContainer: FC = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(EMPTY_OBJECT);
  const totalFetchingQueriesCount = useIsFetching({
    predicate: (query) =>
      query.queryKey[NULL_VALUE] !== QUERY_KEYS.NOTIFICATIONS,
  });

  const [cachedFetchingQueriesCount, setCachedFetchingQueriesCount] =
    useState<number>(NULL_VALUE);

  const isSignOutPopConfirmShown = useRef<boolean>(true);

  const { mutate: signOutMutate } = useSignOut();

  const [isMobileMenuOpened, toggleIsMobileMenuOpened] = useToggle(false);
  const [isCollapsed, toggleIsCollapsed] = useToggle(true);

  const userProfile = useGetUserFromQueryContext();

  const isDesktopVersion = useGetIsDesktopVersion();

  const ref = useRef<HTMLDivElement>(null);

  const handleSignOutOpenedTabs = (event: StorageEvent) => {
    if (event.key === SIGN_OUT_LS_KEY) {
      signOutMutate();
    }
  };

  const handleSignOut = () => {
    localStorage.setItem(SIGN_OUT_LS_KEY, Date.now().toString());

    signOutMutate();
  };

  useEffect(() => {
    const emailFromLink = searchParams.get(SearchParams.email) as string;

    if (emailFromLink) {
      if (
        isSignOutPopConfirmShown.current &&
        userProfile.email !== emailFromLink
      ) {
        isSignOutPopConfirmShown.current = false;
        showConfirmModal({
          ...SIGN_OUT_CONFIRM_PROPS,
          onOk: () => handleSignOut(),
        });
      }
      setSearchParams(EMPTY_OBJECT);
    }

    window.addEventListener('storage', handleSignOutOpenedTabs);

    return () => {
      window.removeEventListener('storage', handleSignOutOpenedTabs);
    };
  }, []);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    const handleClearHistoryState = () => {
      window.history.replaceState(EMPTY_OBJECT, document.title);
    };

    window.addEventListener('beforeunload', handleClearHistoryState);

    return () =>
      window.removeEventListener('beforeunload', handleClearHistoryState);
  }, []);

  useEffect(() => {
    setCachedFetchingQueriesCount(totalFetchingQueriesCount);

    if (totalFetchingQueriesCount < cachedFetchingQueriesCount) {
      queryClient.invalidateQueries([QUERY_KEYS.NOTIFICATIONS]);
    }
  }, [totalFetchingQueriesCount, setCachedFetchingQueriesCount]);

  return (
    <AuthorizedLayout
      ref={ref}
      userProfile={userProfile}
      isCollapsed={isCollapsed && isDesktopVersion}
      isMobileMenuOpened={isMobileMenuOpened}
      toggleIsCollapsed={toggleIsCollapsed}
      toggleIsMobileMenuOpened={toggleIsMobileMenuOpened}
    />
  );
};

export default AuthorizedLayoutContainer;
