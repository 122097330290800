import { useQuery, UseQueryResult } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { QUERY_KEYS } from 'constants/queryKeys';
import { TAxiosRequestError } from 'api/types';
import { transformRoute } from 'utils/routing';
import { triggerDownloadFile } from 'utils/files/downloadFile';

import { TDownloadInvoicePlacement } from './types';

const useDownloadPaymentInvoice = (
  invoiceId: string,
  placement: TDownloadInvoicePlacement = 'page',
): UseQueryResult<string, TAxiosRequestError> => {
  const handleSuccess = (url: string) => {
    triggerDownloadFile(url);
  };

  return useQuery(
    [QUERY_KEYS.PAYMENT_INVOICE, { invoiceId, placement }],
    async () => {
      const { data: invoiceURLResponse } = await apiClient.get(
        transformRoute(ApiRoutes.GET_INVOICE_URL, invoiceId),
      );

      return invoiceURLResponse;
    },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
    },
  );
};

export default useDownloadPaymentInvoice;
