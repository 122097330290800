import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetCustomerPaymentAndBillingAddress } from 'api/customers';
import { useCheckSerialNumberForPayment } from 'api/serialNumbers';
import { useGetSubscriptionPlansList } from 'api/subscriptions';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constants/common';
import { APP_ROUTE } from 'constants/appRoutes';
import { useToggle, useUserId } from 'hooks';
import { SearchParams } from 'types/urlSearchParams';
import { ISubscriptionPlan } from 'types/subscriptions';

import SerialNumberVerifiedModal from './SerialNumberVerifiedModal';
import Payment from './Payment';

const PaymentContainer: FC = () => {
  const customerId = useUserId();
  const navigateTo = useNavigate();
  const [searchParams] = useSearchParams(EMPTY_OBJECT);
  const [isVerifiedModalOpened, toggleIsVerifiedModalOpened] = useToggle();

  const [selectedSubscription, setSelectedSubscription] =
    useState<ISubscriptionPlan | null>(null);

  const serialNumberFromUrl = searchParams.get(
    SearchParams.serialNumber,
  ) as string;

  const {
    data: customerPaymentAndBillingAddressResponse,
    isLoading: isCustomerPaymentAndBillingAddressLoading,
  } = useGetCustomerPaymentAndBillingAddress(customerId);

  const {
    data: subscriptionPlans,
    isFetching: isSubscriptionPlansFetching,
    refetch: refetchSubscriptionPlans,
  } = useGetSubscriptionPlansList({ serialNumber: serialNumberFromUrl });

  const {
    data: serialNumberResponse,
    isSuccess: isCheckSerialNumberForPaymentSuccess,
    isError: isCheckSerialNumberForPaymentError,
    isFetching: isCheckSerialNumberForPaymentFetching,
    remove: clearSerialNumberForPaymentData,
  } = useCheckSerialNumberForPayment(serialNumberFromUrl);

  const showContent =
    serialNumberFromUrl && !isCheckSerialNumberForPaymentError;

  useEffect(() => {
    if (!showContent) {
      navigateTo(APP_ROUTE.serialNumbers);
    } else if (isCheckSerialNumberForPaymentSuccess) {
      toggleIsVerifiedModalOpened();
      refetchSubscriptionPlans();
    }
  }, [showContent, isCheckSerialNumberForPaymentSuccess]);

  useEffect(() => {
    return () => clearSerialNumberForPaymentData();
  }, [clearSerialNumberForPaymentData]);

  return (
    <>
      {showContent && (
        <>
          <Payment
            serialNumber={serialNumberResponse || null}
            subscriptionPlans={subscriptionPlans || EMPTY_ARRAY}
            selectedSubscription={selectedSubscription}
            customerPaymentAndBillingAddress={
              customerPaymentAndBillingAddressResponse
            }
            isLoading={
              isCheckSerialNumberForPaymentFetching ||
              isSubscriptionPlansFetching ||
              isCustomerPaymentAndBillingAddressLoading
            }
            handleChangeSubscription={setSelectedSubscription}
          />
          <SerialNumberVerifiedModal
            serialNumber={serialNumberFromUrl}
            isModalOpened={isVerifiedModalOpened}
            handleCloseModal={toggleIsVerifiedModalOpened}
          />
        </>
      )}
    </>
  );
};

export default PaymentContainer;
