import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';

export const LearnMoreModalFooterWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: 70,
  width: '100%',
  padding: '26px 32px 26px 90px',
  margin: '54px auto 40px',
  backgroundColor: COLORS.gray50,
  borderRadius: '8px',
  overflow: 'hidden',
  '&:before, &:after': {
    position: 'absolute',
    content: "''",
    borderRadius: '50%',
  },
  '&:before': {
    bottom: 0,
    left: 0,
    transform: 'translate(-50%, 10%)',
    width: '134px',
    height: '134px',
    backgroundColor: COLORS.gray100,
  },
  '&:after': {
    bottom: 0,
    left: 0,
    transform: 'translate(-65%, 25%)',
    width: '96px',
    height: '96px',
    backgroundColor: COLORS.gray200,
  },
  '@media (max-width: 768px)': {
    paddingLeft: '20px',
    columnGap: 24,
    ':after, :before': {
      display: 'none',
    },
  },
});

export const LearnMoreModalFooterText = styled('h4')({
  fontSize: '24px',
  lineHeight: '34px',
  fontWeight: 600,
  color: COLORS.gray800,
  marginBottom: 0,
});

export const LearnMoreModalFooterButton = styled(Button)({
  width: '170px',
  margin: 0,
});
