import { FC } from 'react';

import {
  MAX_NAME_LENGTH,
  MAX_ADDRESS_LENGTH,
  MAX_COMPANY_NAME_LENGTH,
} from 'constants/validations';
import { DefaultInput, PhoneNumberInput } from 'components/Inputs';

import { BILLING_ADDRESS_FORM_TEXT } from './constants';
import { FieldsWrapper } from './styled';
import { IBillingAddressFieldsProps, BillingAddressFormField } from './types';

const BillingAddressFields: FC<IBillingAddressFieldsProps> = ({
  formik,
  isFormDisabled,
  handleChangePhoneNumberValue,
  handleChangeCountryCodeValue,
  ...props
}) => {
  const { errors, getFieldProps } = formik;

  return (
    <FieldsWrapper {...props}>
      <FieldsWrapper>
        <DefaultInput
          disabled={isFormDisabled}
          maxLength={MAX_NAME_LENGTH}
          labelText={BILLING_ADDRESS_FORM_TEXT.firstNameLabel}
          errorMessage={errors[BillingAddressFormField.FIRST_NAME]}
          {...getFieldProps(BillingAddressFormField.FIRST_NAME)}
        />
        <DefaultInput
          disabled={isFormDisabled}
          maxLength={MAX_NAME_LENGTH}
          labelText={BILLING_ADDRESS_FORM_TEXT.lastNameLabel}
          errorMessage={errors[BillingAddressFormField.LAST_NAME]}
          {...getFieldProps(BillingAddressFormField.LAST_NAME)}
        />
        <DefaultInput
          disabled={isFormDisabled}
          labelText={BILLING_ADDRESS_FORM_TEXT.emailLabel}
          errorMessage={errors[BillingAddressFormField.EMAIL]}
          {...getFieldProps(BillingAddressFormField.EMAIL)}
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <PhoneNumberInput
          phoneNumberInputProps={{
            id: BillingAddressFormField.PHONE_NUMBER,
            disabled: isFormDisabled,
            labelText: BILLING_ADDRESS_FORM_TEXT.phoneNumberLabel,
            errorMessage: errors[BillingAddressFormField.PHONE_NUMBER],
            handleChangePhoneNumberFormik: handleChangePhoneNumberValue,
            ...getFieldProps(BillingAddressFormField.PHONE_NUMBER),
          }}
          countrySelectProps={{
            id: BillingAddressFormField.COUNTRY_CODE,
            disabled: isFormDisabled,
            errorMessage: errors[BillingAddressFormField.COUNTRY_CODE],
            handleChangeCountryCodeFormik: handleChangeCountryCodeValue,
            ...getFieldProps(BillingAddressFormField.COUNTRY_CODE),
          }}
        />
        <DefaultInput
          disabled={isFormDisabled}
          required={false}
          labelText={BILLING_ADDRESS_FORM_TEXT.companyLabel}
          maxLength={MAX_COMPANY_NAME_LENGTH}
          {...getFieldProps(BillingAddressFormField.COMPANY)}
        />
        <DefaultInput
          disabled={isFormDisabled}
          maxLength={MAX_ADDRESS_LENGTH}
          labelText={BILLING_ADDRESS_FORM_TEXT.addressLabel}
          errorMessage={errors[BillingAddressFormField.ADDRESS]}
          {...getFieldProps(BillingAddressFormField.ADDRESS)}
        />
      </FieldsWrapper>
    </FieldsWrapper>
  );
};

export default BillingAddressFields;
