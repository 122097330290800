import { mockImage } from 'assets/images';

import { StyledArticleImage } from './styled';

export const THANK_YOU_PAGE_CONTENT = {
  TITLE: `Congratulations! \n Your Iraqi Dinar has been unlocked!`,
  SUB_TITLE: 'What are you waiting for? See your report right now.',
  BUTTON_TEXT: 'Continue',
  BANNER_TITLE: 'Thank you for your order!',
  BANNER_SUB_TITLE: 'We just sent the following to your email:',
  BANNER_DETAILS: {
    CONFIRMATION: '• Registration confirmation.',
    SUBSCRIPTION: '• Subscription confirmation.',
    BRIEF: '• Brief description of the DinarVerify application.',
  },
  LEARN_MORE_TITLE: 'What do you want to do next with Dinar?',
};

export const BANNER_DETAILS_LIST = [
  THANK_YOU_PAGE_CONTENT.BANNER_DETAILS.CONFIRMATION,
  THANK_YOU_PAGE_CONTENT.BANNER_DETAILS.SUBSCRIPTION,
  THANK_YOU_PAGE_CONTENT.BANNER_DETAILS.BRIEF,
];

export const ARTICLES_MOCK = [
  {
    image: <StyledArticleImage src={mockImage} />,
    title: 'Add Dinarcare protection',
    description:
      'Some text here. Will be provided by the customer later. Some text here. Will be provided by the customer.',
    link: '#',
  },
  {
    image: <StyledArticleImage src={mockImage} />,
    title: 'Add cash out rate protection',
    description:
      'Some text here. Will be provided by the customer later. Some text here. Will be provided by the customer.',
    link: '#',
  },
  {
    image: <StyledArticleImage src={mockImage} />,
    title: 'Add cash out priority protection',
    description:
      'Some text here. Will be provided by the customer later. Some text here. Will be provided by the customer.',
    link: '#',
  },
  {
    image: <StyledArticleImage src={mockImage} />,
    title: 'Buy more dinar',
    description:
      'Some text here. Will be provided by the customer later. Some text here. Will be provided by the customer.',
    link: '#',
  },
  {
    image: <StyledArticleImage src={mockImage} />,
    title: 'Get my dinar verified',
    description:
      'Some text here. Will be provided by the customer later. Some text here. Will be provided by the customer.',
    link: '#',
  },
  {
    image: <StyledArticleImage src={mockImage} />,
    title: 'Buy other verified currencies',
    description:
      'Some text here. Will be provided by the customer later. Some text here. Will be provided by the customer.',
    link: '#',
  },
];

export const INITIAL_THANK_YOU_PAGE_STEP = 1;
export const LEARN_MORE_STEP = 2;
