import { ICurrencyRequest, IRequestDetails } from './request';

export enum CurrencyType {
  DINAR = 'IQD',
  RUPIAH = 'IDR',
  DONG = 'VND',
}

export type TCurrencyType = 'IQD' | 'VND' | 'IDR';

export type TLookupByCurrencyType<T = string> = { [key in TCurrencyType]: T };

export interface ICurrenciesForRequest
  extends Pick<ICurrencyRequest, 'denom' | 'currency'>,
    Partial<Pick<IRequestDetails, 'series' | 'condition'>> {
  banknoteSN: string;
  convertedBanknoteSN?: string;
}

export interface ICurrenciesTable extends ICurrenciesForRequest {
  cbi?: string;
  front?: string;
  back?: string;
  status?: string;
}
