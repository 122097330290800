import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { COLORS } from 'constants/colorPalette';

export const StyledConfirmTitle = styled('h3')({
  margin: 0,
  fontWeight: 800,
  fontSize: '16px',
  letterSpacing: '0.2px',
  color: COLORS.gray900,
  '& .highlighted': {
    color: COLORS.blue500,
  },
});

export const StyledConfirmContent = styled('p')({
  margin: 0,
  fontWeight: 500,
  fontSize: '14px',
  letterSpacing: '0.2px',
  color: COLORS.gray700,
  whiteSpace: 'pre-line',
});

export const ConfirmModalIcon = styled(ExclamationCircleOutlined)({
  '& svg': {
    color: COLORS.primary600,
  },
});
