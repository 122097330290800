import { formatPhoneNumber } from 'components/Inputs/PhoneNumberInput/utils';
import { IBillingAddress } from 'types/customer';
import { trimObjectValues } from 'utils/trimObjectValues';

export const formatBillingAddressForSubmit = (
  payload: IBillingAddress,
): IBillingAddress => {
  const { countryCode, phoneNumber, ...valuesToTrim } = payload;

  const trimmedPayload = trimObjectValues(valuesToTrim);

  return {
    ...trimmedPayload,
    countryCode,
    phoneNumber: formatPhoneNumber({ value: phoneNumber, countryCode }),
  };
};
