import { FC, Suspense } from 'react';

import { RoutesList } from 'components/Routes';
import { Loader } from 'components/Loader';

import { IUnauthorizedLayoutProps } from './types';
import { LayoutWrapper, Main } from './styled';
import Header from './Header';

const UnauthorizedLayout: FC<IUnauthorizedLayoutProps> = ({
  isDesktopVersion,
}) => (
  <LayoutWrapper>
    <Header isDesktopVersion={isDesktopVersion} />
    <Main isDesktopVersion={isDesktopVersion}>
      <Suspense fallback={<Loader />}>
        <RoutesList />
      </Suspense>
    </Main>
  </LayoutWrapper>
);

export default UnauthorizedLayout;
